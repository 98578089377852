import dayjs from 'dayjs'
import queryString from 'query-string'
import React, { Component } from 'react'
import { Mutation, Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import { RELAY_GROUP_QUERY } from '../../constants/queries'
import { LAGVIS_LIST_UPDATE_DATA_MUTATION } from '../../constants/mutations'

import SidebarFilter from '../../components/SidebarFilter'
import Dropdown from '../../components/Dropdown'

import 'dayjs/locale/nb'
import utc from 'dayjs/plugin/utc'
import ResponseMiddleware from '../../Middleware/ResponseMiddleware'
import types from '../../Store'
import LagTimeOption from '../../views/SidebarFilter/LagTimeOption'
dayjs.locale('nb')
dayjs.extend(utc)

class Sidebar extends Component {
  mutationHandler = () => {}

  state = {
    types: [
      {
        label: 'Finaler 100m',
        slug: 'BA_100',
      },
      {
        label: 'Finaler 200m',
        slug: 'BA_200',
      },
      {
        label: 'Finaler Finfelt',
        slug: 'FE_FIN',
      },
      {
        label: 'Finaler Grovfelt',
        slug: 'FE_GROV',
      },
      {
        label: 'Finaler Stang',
        slug: 'FE_ST',
      },
      {
        label: 'Finaler Felt',
        slug: 'FE_FH',
      },
      {
        label: 'Finaler MIL Bane',
        slug: 'BA_MIL',
      },
      {
        label: 'Finaler MIL Felt',
        slug: 'FE_MIL',
      },
      {
        label: 'Lagskyting',
        slug: 'LA',
      },
      {
        label: 'Samlagsskyting ungdom',
        slug: 'SA_UNGDOM',
      },
      {
        label: 'Samlagsskyting senior',
        slug: 'SA_SENIOR',
      },
      {
        label: 'Samlagsskyting veteran',
        slug: 'SA_VETERAN',
      },
    ],
    selectedType: 'BA_100',
  }

  componentDidMount() {
    const { selectedType } = queryString.parse(this.props.location.search)

    if (!selectedType) {
      return
    }

    this.setState({
      selectedType,
    })
  }

  formatCategoriesList = (response) => {
    const categories = {}

    if (!response.length) {
      return []
    }

    response[0].relays.forEach((category) => {
      if (category.relayType !== 'FINALE') {
        return
      }

      const slug = `${this.state.selectedType}-${category.name.split(' ').join('-')}`

      const [date] = category.checkinDateTime.split('T')

      const isCategoryExists = categories.hasOwnProperty(date)

      const itemLabel = `Lag ${category.number} - ${dayjs(category.checkinDateTime).utc().format('HH.mm')} - ${
        category.name
      }`

      const item = {
        slug: itemLabel.split(' ').join('-'),
        label: category.name,
        lag: `Lag ${category.number}`,
        time: dayjs(category.checkinDateTime).utc().format('HH:mm'),
        timestamp: dayjs(category.checkinDateTime).utc().unix(),
        timeString: category.checkinDateTime,
        organizationId: response[0].organizationId,
        number: category.number,
      }

      if (isCategoryExists) {
        categories[date].children.push(item)

        return
      }

      categories[date] = {
        label: dayjs(category.checkinDateTime).utc().format('dddd D.M').toUpperCase(),
        timestamp: dayjs(category.checkinDateTime).utc().unix(),
        slug,
        children: [item],
      }
    })

    return Object.values(categories)
      .map((category) => ({
        ...category,
        children: category.children.sort((a, b) => a.timestamp - b.timestamp),
      }))
      .sort((a, b) => a.timestamp - b.timestamp)
  }

  updateLinkSelect = (category, option, callback) => {
    const activeType = this.state.types.find((type) => type.slug === this.state.selectedType) || {
      label: '',
    }

    if (!option) {
      return
    }

    callback({
      variables: {
        type: activeType.label,
        category: category.label,
        option: option.label,
        timeString: option.timeString,
        number: option.number,
        organizationId: option.organizationId,
      },
    })

    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    this.props.onCloseSidebar()
  }

  onSelectType = (selectedType) => {
    this.setState({
      selectedType,
    })

    const { pathname, search } = this.props.location
    const previousSearch = queryString.parse(search)

    this.props.history.push({
      pathname,
      search: queryString.stringify({
        ...previousSearch,
        selectedType,
      }),
    })
  }

  componentWillUnmount() {
    this.mutationHandler({
      variables: types.lagvisList,
    })
  }

  render() {
    const { types, selectedType } = this.state

    const response = new ResponseMiddleware()
    response.setComponent((data) => (
      <Mutation mutation={LAGVIS_LIST_UPDATE_DATA_MUTATION}>
        {(handler) => {
          this.mutationHandler = handler

          return (
            <SidebarFilter
              onSelectLink={(category, option) => this.updateLinkSelect(category, option, handler)}
              categories={this.formatCategoriesList(data.getRelayGroup)}
              categoryComponent={LagTimeOption}
            />
          )
        }}
      </Mutation>
    ))

    return (
      <>
        <Dropdown options={types} selectedOption={selectedType} onChange={this.onSelectType} />

        <Query query={RELAY_GROUP_QUERY} variables={{ organizationId: selectedType }}>
          {response.execute}
        </Query>
      </>
    )
  }
}

export default withRouter(Sidebar)
