import React from 'react'
import PropTypes from 'prop-types'
import ClassNamesService from '../../../services/ClassNamesService'

const ScoreHead = ({ children, width = null, className = null }) => (
  <div
    className={ClassNamesService.execute(['head-cell head-cell--is-score', className])}
    style={{
      width,
    }}
  >
    {children}
  </div>
)

ScoreHead.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, () => null]),
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
}

export default ScoreHead
