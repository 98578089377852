import dayjs from 'dayjs'
import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import '../styles/ranged-tables.scss'
import messages from '../constants/messages'
import { LAGVIS_LIST_QUERY } from '../constants/queries'

import Sidebar from '../containers/FinalLagvisList/Sidebar'
import Message from '../views/Message'
import ResponseMiddleware from '../Middleware/ResponseMiddleware'
import Heading from '../views/TypeHeadingTime'
import ShootersTable from '../containers/FinalLagvisList/ShootersTable'
import SidebarHOC from '../components/SidebarHOC'

class FinalLagvisList extends Component {
  componentDidMount() {
    this.props.onShowSearch(false)
    this.props.onShowNav(false)
  }

  render() {
    const response = new ResponseMiddleware()
    response.setComponent(data => (
      <div
        className="content-wrapper"
        style={{
          paddingTop: data.lagvisList.category === '' && '30px',
        }}
      >
        {data.lagvisList.category !== '' && (
          <Heading
            className="ranged-field"
            type={`LAG ${data.lagvisList.number}`}
            option={data.lagvisList.option}
            time={dayjs(data.lagvisList.timeString)
              .utc()
              .format('DD.MM.YYYY HH:mm')}
            preTitle="Oppropstid:"
          />
        )}
        {data.lagvisList.category !== '' ? (
          <ShootersTable {...data.lagvisList} />
        ) : (
          <Message text={messages.refineSearch} />
        )}
      </div>
    ))

    return (
      <div className="full-page-wrapper full-page-wrapper--is-fluid full-page-wrapper--is-full-width shooters-table">
        <SidebarHOC>
          <Sidebar />
        </SidebarHOC>

        <Query query={LAGVIS_LIST_QUERY}>{response.execute}</Query>
      </div>
    )
  }
}

export default withRouter(FinalLagvisList)
