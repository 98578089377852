import React, { Component } from 'react'
import RowHOC from '../../containers/SamlagsFylkesvise/RowHOC'
import ClassNamesService from '../../services/ClassNamesService'

import { formatShootsHeadsId, getShotsCode } from '../../services/shooterService'

import Table from '../../views/Table/Table'
import HeadSection from '../../views/Table/HeadSection'
import OpenShooterModal from '../../views/OpenShooterModal'
import Row from '../Table/Row'
import ResizableCell from '../Table/ResizableCell'
import {
  NumberCell,
  ShotsCell,
  TotalStarCell,
  ShooterCell,
  TotalScoreCell,
  AdditionalCell,
  ScoreCell,
} from '../../views/Table/Cells'
import { NumberHead, ScoreHead, ShooterHead, TotalHead, TotalStarHead, AdditionalHead } from '../../views/Table/Heads'
import Button from '../../views/Button'

class DefaultTable extends Component {
  TOTAL_CELL = 'TOTAL_CELL'

  getSerie = series => organizationId => {
    return (
      series.find(serie => serie.organizationId === organizationId) || {
        sum: 0,
        sumInner: 0,
      }
    )
  }

  getHeadKey = head => {
    return `${head.organizationId}-${head.eventName}`
  }

  headsToObject = heads => heads.reduce((acc, item) => ({ ...acc, [item.organizationId]: '' }), {})

  resolveHeads767 = middleHeads => {
    if (!middleHeads.length) {
      return []
    }

    const middleHeadsObject = this.headsToObject(middleHeads)

    return [
      // {
      //   name: 'Hold 1',
      //   organizationId: 'ST_1',
      // },
      // {
      //   name: 'Hold 2',
      //   organizationId: 'ST_2',
      // },
      // {
      //   name: 'Stang',
      //   organizationId: 'ST_1To2',
      // },
    ].filter(head => middleHeadsObject.hasOwnProperty(head.organizationId))
  }

  resolveHeads1199 = middleHeads => {
    if (!middleHeads.length) {
      return []
    }

    const middleHeadsObject = this.headsToObject(middleHeads)

    return [
      // {
      //   name: '30 Skudd',
      //   organizationId: 'FE_1To5',
      // },
      // {
      //   name: 'Minne',
      //   organizationId: 'MI_1',
      // },
    ].filter(head => middleHeadsObject.hasOwnProperty(head.organizationId))
  }

  resolveHeadsMax = middleHeads => {
    if (!middleHeads.length) {
      return []
    }

    const existingHeads = {
      ...this.headsToObject(this.resolveHeads767(middleHeads)),
      ...this.headsToObject(this.resolveHeads1199(middleHeads)),
    }

    return middleHeads.filter(head => !existingHeads.hasOwnProperty(head.organizationId))
  }

  middleHeads = shooter => {
    const formattedHeads = formatShootsHeadsId(shooter)
    const middleHeads = formattedHeads.slice(0, -1)
    const lastElement = formattedHeads.slice(-1)
    const heads767 = this.resolveHeads767(middleHeads)
    const heads1199 = this.resolveHeads1199(middleHeads)
    const headsMax = this.resolveHeadsMax(middleHeads)

    return {
      items: [
        {
          className: 'hidden-up-to-1439',
          heads: headsMax,
        },
        {
          className: 'hidden-up-to-1199',
          heads: heads1199,
        },
        {
          className: 'hidden-up-to-767',
          heads: heads767,
        },
      ],
      secondaryColumns: [
        {
          className: 'hidden-from-1440',
          heads: headsMax,
        },
        {
          className: 'hidden-from-1200',
          heads: heads1199,
        },
        {
          className: 'hidden-from-768',
          heads: heads767,
        },
      ],
      formattedHeads,
      lastHead: lastElement.length ? lastElement[0] : { name: '' },
      middleHeads,
    }
  }

  render() {
    const { data } = this.props

    const shooters = data
    const resolvedMiddleHeads = this.middleHeads(shooters.find(shooter => !!shooter.series.length))
    const lastHead = resolvedMiddleHeads.lastHead

    const lastHeadKey = this.getHeadKey(lastHead)
    const totalSubscribe = this.props.subscribeGenerator(lastHeadKey)
    const totalUnsubscribe = this.props.unSubscribeGenerator(lastHeadKey)
    const totalMaxWidth = this.props.getMaxWidth(lastHeadKey)

    const middleColumnHandlers = {}

    resolvedMiddleHeads.middleHeads.forEach(headName => {
      const key = this.getHeadKey(headName)

      middleColumnHandlers[key] = {
        subscribe: this.props.subscribeGenerator(key),
        unsubscribe: this.props.unSubscribeGenerator(key),
        width: this.props.getMaxWidth(key),
      }
    })

    return (
      <Table>
        <HeadSection className="head-row--up-to-1440">
          <NumberHead>SK.</NumberHead>
          <ShooterHead>Navn og Skytterlag</ShooterHead>

          {resolvedMiddleHeads.items.map(group =>
            group.heads.map((head, idx) => (
              <AdditionalHead key={idx} className={group.className}>
                <ResizableCell {...middleColumnHandlers[this.getHeadKey(head)]}>
                  <ScoreHead>{head.name}</ScoreHead>
                </ResizableCell>
              </AdditionalHead>
            ))
          )}
          <ResizableCell subscribe={totalSubscribe} unsubscribe={totalUnsubscribe} width={totalMaxWidth}>
            <TotalStarHead star="*" score={lastHead.name} />
          </ResizableCell>
        </HeadSection>

        {shooters.map((shooter, key) => {
          const shooterSeries = this.getSerie(shooter.series)
          const lastSerie = shooterSeries(lastHead.organizationId)

          const secondaryColumns = (
            <OpenShooterModal key={shooter.organizationId}>
              {({ onOpen }) => (
                <>
                  {resolvedMiddleHeads.secondaryColumns.map(group =>
                    group.heads.map((headName, idx) => {
                      const cellSerie = shooterSeries(headName.organizationId)

                      return (
                        <div key={idx} className={ClassNamesService.execute(['secondary-line', group.className])}>
                          <TotalHead className="head-cell--is-secondary-head">{headName.name}</TotalHead>
                          <ScoreCell className="cell--is-thin cell--additional-is-shots">
                            {getShotsCode(cellSerie.shots || [])}
                          </ScoreCell>
                          <ResizableCell
                            subscribe={totalSubscribe}
                            unsubscribe={totalUnsubscribe}
                            width={totalMaxWidth}
                          >
                            <TotalScoreCell>{cellSerie.sum}</TotalScoreCell>
                          </ResizableCell>
                        </div>
                      )
                    })
                  )}
                  <Button
                    className="btn--is-secondary-row"
                    onClick={() => onOpen({ shooterId: shooter.organizationId })}
                  >
                    Vis skytterkort
                  </Button>
                </>
              )}
            </OpenShooterModal>
          )

          return (
            <OpenShooterModal key={shooter.organizationId + key}>
              {({ onOpen }) => (
                <RowHOC shooterId={[shooter.eventName, shooter.organizationId].join(':')}>
                  <Row
                    secondaryRows={resolvedMiddleHeads.secondaryColumns.length && secondaryColumns}
                    className="row--up-to-1440"
                    updateKey={data[0].className}
                    onClick={() => onOpen({ shooterId: shooter.organizationId, from: 1439 })}
                  >
                    <NumberCell>{key + 1}</NumberCell>
                    <ShooterCell image="" name={shooter.name} club={shooter.clubName} />

                    {resolvedMiddleHeads.items.map(group =>
                      group.heads.map((headName, idx) => {
                        const cellSerie = shooterSeries(headName.organizationId)
                        const key = this.getHeadKey(headName)

                        return (
                          <AdditionalCell className={group.className} key={idx}>
                            <ResizableCell {...middleColumnHandlers[key]}>
                              <ShotsCell
                                hasBorder={idx !== resolvedMiddleHeads.formattedHeads.length - 1}
                                score={String(cellSerie.sum)}
                                shots={getShotsCode(cellSerie.shots || [])}
                              />
                            </ResizableCell>
                          </AdditionalCell>
                        )
                      })
                    )}

                    <ResizableCell subscribe={totalSubscribe} unsubscribe={totalUnsubscribe} width={totalMaxWidth}>
                      <TotalStarCell score={lastSerie.sum} star={lastSerie.sumInner || 0} />
                    </ResizableCell>
                  </Row>
                </RowHOC>
              )}
            </OpenShooterModal>
          )
        })}
      </Table>
    )
  }
}

export default DefaultTable
