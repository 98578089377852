import React from 'react'
import { withRouter } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import { OPEN_SHOOTER_MODAL_MUTATION } from '../constants/mutations'
import { SHOOTER_PATH } from '../constants/routePaths'
import sniperIcon from '../images/sniper.svg'
import { getNameInitials } from '../services/shooterService'
import { isMobile } from '../services/screenService'

// @TODO: API DOES NOT HAVE AVATARS
const shooterHasAvatar = false

const SearchResultShooter = props => {
  const handleClick = openModalFunc => {
    isMobile()
      ? props.history.push(SHOOTER_PATH + props.organizationId)
      : openModalFunc({ variables: { shooterModalId: props.organizationId } })
  }

  return (
    <Mutation mutation={OPEN_SHOOTER_MODAL_MUTATION}>
      {openModal => (
        <div className="search-result__shooter" onClick={() => handleClick(openModal)}>
          <div className="sr__col-0">
            <div className="sr__col-1">
              <span className="search-result__avatar">
                {shooterHasAvatar ? <img src={sniperIcon} alt="Avatar" /> : getNameInitials(props.name)}
              </span>
              <span>{props.name}</span>
            </div>
            <div className="sr__col-2">{props.clubName ? props.clubName : ''}</div>
          </div>
          <div className="sr__col-3">
            {props.categories && props.categories.length
              ? props.categories.map(cat => (
                  <span className="badge" key={cat}>
                    {cat}
                  </span>
                ))
              : ''}
            {props.defaultClassOrganizationId ? (
              <span className="badge badge__accent" key={props.defaultClassOrganizationId}>
                {props.defaultClassOrganizationId}
              </span>
            ) : null}
            {/* <span className="badge" key={'lol'}>
              {props.defaultClassName}
            </span> */}
          </div>
          <div className="sr__col-4">
            <span className="btn">skytter kort</span>
          </div>
        </div>
      )}
    </Mutation>
  )
}

export default withRouter(SearchResultShooter)
