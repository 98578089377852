import React, { Component } from 'react'
import ClassNamesService from '../../services/ClassNamesService'
import { formatScoreHeads, resolveDelimiter } from '../../services/shooterService'

import Table from '../../views/Table/Table'
import HeadSection from '../../views/Table/HeadSection'
import ShooterMedal from '../../VO/ShooterMedal'
import ResizableCell from '../Table/ResizableCell'
import Row from '../Table/Row'
import { AdditionalCell, NumberCell, ScoreCell, ShooterCell, TotalScoreCell } from '../../views/Table/Cells'
import { NumberHead, ShooterHead, ScoreHead, TotalHead, AdditionalHead } from '../../views/Table/Heads'
import Button from '../../views/Button'
import OpenShooterModal from '../../views/OpenShooterModal'

class Finfelt30Table extends Component {
  TOTAL_CELL = 'TOTAL_CELL'

  getSerie = series => name => {
    return (
      series.find(serie => serie.name === name) || {
        sum: 0,
        sumInner: 0,
      }
    )
  }

  middleHeads = shooter => {
    const formattedHeads = formatScoreHeads(shooter).slice(0, -1)

    return {
      items: [
        {
          className: 'hidden-up-to-1199',
          heads: formattedHeads,
        },
      ],
      secondaryColumns: [
        {
          className: 'hidden-from-1200',
          heads: formattedHeads,
        },
      ],
      formattedHeads,
    }
  }

  render() {
    const { data } = this.props

    const shooters = data.getReport.ranks

    const shooter = shooters.find(shooter => shooter.shooters[0].series.length)
    const resolvedMiddleHeads = this.middleHeads(shooter || shooters[0])

    const middleColumnHandlers = {}

    resolvedMiddleHeads.formattedHeads.forEach(headName => {
      middleColumnHandlers[headName] = {
        subscribe: this.props.subscribeGenerator(headName),
        unsubscribe: this.props.unSubscribeGenerator(headName),
        width: this.props.getMaxWidth(headName),
      }
    })

    const totalSubscribe = this.props.subscribeGenerator(this.TOTAL_CELL)
    const totalUnsubscribe = this.props.unSubscribeGenerator(this.TOTAL_CELL)
    const totalMaxWidth = this.props.getMaxWidth(this.TOTAL_CELL)

    return (
      <Table>
        <HeadSection className="head-row--up-to-1200">
          <NumberHead>PL.</NumberHead>
          <ShooterHead>Navn og Skytterlag</ShooterHead>

          {resolvedMiddleHeads.items.map(group =>
            group.heads.map((head, idx) => (
              <AdditionalHead key={idx} className={group.className}>
                <ResizableCell {...middleColumnHandlers[head]}>
                  <ScoreHead>{head}</ScoreHead>
                </ResizableCell>
              </AdditionalHead>
            ))
          )}

          <ResizableCell subscribe={totalSubscribe} unsubscribe={totalUnsubscribe} width={totalMaxWidth}>
            <TotalHead>30 skudd</TotalHead>
          </ResizableCell>
        </HeadSection>

        {shooters.map((shooter, key) => {
          const shooterSeries = this.getSerie(shooter.shooters[0].series)
          const lastSerie = shooterSeries('30 skudd')

          const secondaryColumns = (
            <OpenShooterModal>
              {({ onOpen }) => (
                <>
                  {resolvedMiddleHeads.secondaryColumns.map(group =>
                    group.heads.map((headName, idx) => {
                      const cellSerie = shooterSeries(headName)

                      return (
                        <div key={idx} className={ClassNamesService.execute(['secondary-line', group.className])}>
                          <TotalHead className="head-cell--is-secondary-head">{headName}</TotalHead>
                          <TotalScoreCell>{resolveDelimiter(cellSerie.sum, cellSerie.sumInner)}</TotalScoreCell>
                        </div>
                      )
                    })
                  )}
                  <Button
                    className="btn--is-secondary-row"
                    onClick={() => onOpen({ shooterId: shooter.shooters[0].organizationId })}
                  >
                    Vis skytterkort
                  </Button>
                </>
              )}
            </OpenShooterModal>
          )

          const shooterImage = ShooterMedal.medalImage(shooter)

          return (
            <OpenShooterModal key={key}>
              {({ onOpen }) => (
                <Row
                  key={key}
                  secondaryRows={secondaryColumns}
                  updateKey={data.getReport.name}
                  className="row--up-to-1200"
                  onClick={() => onOpen({ shooterId: shooter.shooters[0].organizationId, from: 1199 })}
                >
                  <NumberCell>{shooter.rank}</NumberCell>
                  <ShooterCell image={shooterImage} name={shooter.name} club={shooter.shooters[0].clubName} />

                  {resolvedMiddleHeads.items.map(group =>
                    group.heads.map((headName, idx) => {
                      const cellSerie = shooterSeries(headName)

                      return (
                        <AdditionalCell className={group.className} key={idx}>
                          <ResizableCell {...middleColumnHandlers[headName]}>
                            <ScoreCell hasBorder>{resolveDelimiter(cellSerie.sum, cellSerie.sumInner)}</ScoreCell>
                          </ResizableCell>
                        </AdditionalCell>
                      )
                    })
                  )}

                  <ResizableCell subscribe={totalSubscribe} unsubscribe={totalUnsubscribe} width={totalMaxWidth}>
                    <TotalScoreCell>{resolveDelimiter(lastSerie.sum, lastSerie.sumInner)}</TotalScoreCell>
                  </ResizableCell>
                </Row>
              )}
            </OpenShooterModal>
          )
        })}
      </Table>
    )
  }
}

export default Finfelt30Table
