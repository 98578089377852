import PropTypes from 'prop-types'
import AdminMenuChildItemDTO from '../DTO/AdminMenuChildItemDTO'

class AdminMenuItemVO {
  static shape = {
    title: PropTypes.string,
    url: PropTypes.string,
    order: PropTypes.string,
    type: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape(AdminMenuChildItemDTO.shape)),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  static types = {
    internal: 'internal',
    iframe: 'iframe',
    xml: 'xml',
  }

  static editableTypes = {
    [AdminMenuItemVO.types.iframe]: '',
    [AdminMenuItemVO.types.xml]: '',
  }

  static mappedTypes = {
    [AdminMenuItemVO.types.internal]: 'system',
    [AdminMenuItemVO.types.iframe]: 'iframe',
    [AdminMenuItemVO.types.xml]: 'xml',
  }

  static editions = {
    2017: '2017',
    2018: '2018',
    2019: '2019',
  }

  static childrenOfTypes = {
    '/rangert-felt': [
      new AdminMenuChildItemDTO('Stang', AdminMenuItemVO.types.internal, 'Stang'),
      new AdminMenuChildItemDTO('Minne', AdminMenuItemVO.types.internal, 'Minne'),
      new AdminMenuChildItemDTO('Felthurtig', AdminMenuItemVO.types.internal, 'Felthurtig'),
      new AdminMenuChildItemDTO('Felt', AdminMenuItemVO.types.internal, 'Felt'),
      new AdminMenuChildItemDTO('Plass siffer', AdminMenuItemVO.types.internal, 'Plass-siffer'),
    ],
    '/rangert-bane': [
      new AdminMenuChildItemDTO('Bane', AdminMenuItemVO.types.internal, 'Bane'),
      new AdminMenuChildItemDTO('Lagsskytting', AdminMenuItemVO.types.internal, 'Lagsskytting'),
      new AdminMenuChildItemDTO('Samlagsskytting', AdminMenuItemVO.types.internal, 'Samlagsskytting'),
    ],
    '/lagvis-lister-inledende': [
      new AdminMenuChildItemDTO('Lagvis 100m', AdminMenuItemVO.types.internal, 'Lagvis-100m'),
      new AdminMenuChildItemDTO('Lagvis 200m', AdminMenuItemVO.types.internal, 'Lagvis-200m'),
      new AdminMenuChildItemDTO('Lagvis Finfelt', AdminMenuItemVO.types.internal, 'Lagvis-Finfelt'),
      new AdminMenuChildItemDTO('Lagvis Grovfelt', AdminMenuItemVO.types.internal, 'Lagvis-Grovfelt'),
    ],
    '/lagvis-lister-finale': [
      new AdminMenuChildItemDTO('Finaler 100m', AdminMenuItemVO.types.internal, 'Finaler-100m'),
      new AdminMenuChildItemDTO('Finaler 200m', AdminMenuItemVO.types.internal, 'Finaler-200m'),
      new AdminMenuChildItemDTO('Finaler Finfelt', AdminMenuItemVO.types.internal, 'Finaler-Finfelt'),
      new AdminMenuChildItemDTO('Finaler Grovfelt', AdminMenuItemVO.types.internal, 'Finaler-Grovfelt'),
      new AdminMenuChildItemDTO('Finaler Stang', AdminMenuItemVO.types.internal, 'Finaler-Stang'),
      new AdminMenuChildItemDTO('Finaler Felt', AdminMenuItemVO.types.internal, 'Finaler-Felt'),
    ],
  }

  title
  url
  order
  type
  children
  id

  constructor(title, url, order, type, children, id) {
    this.title = title
    this.url = url
    this.order = order
    this.type = type
    this.children = children
    this.id = id

    return this
  }

  /**
   *
   * @param value
   * @return {AdminMenuItemVO}
   */
  setOrder = value => {
    this.order = value

    return this
  }

  /**
   *
   * @param data
   * @return {AdminMenuItemVO}
   */
  fromBackend = data => {
    this.title = data.name
    this.url = data.url
    this.order = data.order
    this.type = AdminMenuItemVO.types[data.type] ? data.type : AdminMenuItemVO.types.internal
    this.children = AdminMenuItemVO.childrenOfTypes[data.url] || []
    this.id = data.id

    return this
  }

  /**
   *
   * @param {AdminMenuItemVO} instance
   * @return {AdminMenuItemVO}
   */
  fromInstance = instance => {
    this.title = instance.title
    this.url = instance.url
    this.order = instance.order
    this.type = instance.type
    this.children = AdminMenuItemVO.childrenOfTypes[instance.url] || []
    this.id = instance.id

    return this
  }
}

export default AdminMenuItemVO
