import React from 'react'

import '../styles/loader.scss'
import ClassNamesService from '../services/ClassNamesService'

const Loader = ({ className }) => {
  return (
    <div className={ClassNamesService.execute(['loader js-loaded', className])}>
      <div className="sk-spinner sk-spinner-pulse" />
    </div>
  )
}

export default Loader
