import PropTypes from 'prop-types'
import LinkDTO from './LinkDTO'

class SidebarMenuItem {
  static shape = {
    link: PropTypes.shape(LinkDTO.shape),
    icon: PropTypes.element,
    iconWidth: PropTypes.number,
    initialPathname: PropTypes.string,
  }
  link
  icon
  iconWidth
  initialPathname

  constructor(link, icon, iconWidth = null, initialPathname = null) {
    this.link = link
    this.icon = icon
    this.iconWidth = iconWidth || 18
    this.initialPathname = initialPathname || 'null'

    return this
  }
}

export default SidebarMenuItem
