import dayjs from 'dayjs'
import React, { Component } from 'react'
import { Mutation, Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import { DATES_AND_CLASSES_COUNTY, DATES_AND_CLASSES_DISTRICT, SHOOTER_PLACES_QUERY } from '../../constants/queries'
import { SAMLAGS_FYLKE_UPDATE_DATA_MUTATION } from '../../constants/mutations'

import SidebarFilter from '../../components/SamlagsFylkevise/SidebarFilter'
import SearchBox from '../../components/SidebarSearchBox'
import DropDownHOC from '../../components/DropDownHOC'

import messages from '../../constants/messages'

import 'dayjs/locale/nb'
import utc from 'dayjs/plugin/utc'
import SelectedDate from '../../entities/SelectedDate'
import ResponseMiddleware from '../../Middleware/ResponseMiddleware'
import Message from '../../views/Message'
dayjs.locale('nb')
dayjs.extend(utc)

class Sidebar extends Component {
  state = {
    selectedType: '',
    label: '',
    fromDate: '',
    toDate: '',
    classesSelected: [],
  }

  updateGraphQL = () => {}

  formatSearchVariants = response => {
    const district = response.searchDistrict.map(region => {
      return {
        id: region.organizationId,
        text: region.name.trim(),
        label: 'samlag',
      }
    })

    const county = response.searchCounty.map(region => {
      return {
        id: region.organizationId,
        text: region.name.trim(),
        label: 'fylke',
      }
    })

    return [...county, ...district]
  }

  handleDateSelect = async ({ slug }, callback) => {
    this.updateGraphQL = callback

    const selectedDate = new SelectedDate()
    selectedDate.setDateAsString(slug)

    await new Promise(resolve =>
      this.setState(
        {
          fromDate: selectedDate.startDate,
          toDate: selectedDate.endDate,
        },
        resolve
      )
    )

    this.updateClientStore(callback)

    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    this.props.onCloseSidebar()
  }

  handleClassesSelect = async (list, callback) => {
    await new Promise(resolve =>
      this.setState(
        {
          classesSelected: list,
        },
        resolve
      )
    )

    this.updateClientStore(callback)
  }

  updateClientStore = (callback, state = this.state) => {
    callback({
      variables: {
        organizationId: state.selectedType,
        fromDate: state.fromDate,
        toDate: state.toDate,
        classesSelected: state.classesSelected,
        label: state.label,
      },
    })
  }

  onSelectType = async selectedType => {
    const newState = {
      selectedType: selectedType.id,
      label: selectedType.label,
    }

    this.updateClientStore(this.updateGraphQL, {
      ...this.state,
      ...newState,
    })

    this.setState(newState)
  }

  render() {
    const { selectedType, label } = this.state

    const searchResponse = new ResponseMiddleware()
    searchResponse.setComponent(data => (
      <DropDownHOC>
        <SearchBox variants={this.formatSearchVariants(data)} onSelect={this.onSelectType} />
      </DropDownHOC>
    ))

    const filterResponse = new ResponseMiddleware()
    filterResponse.setComponent(data => (
      <Mutation mutation={SAMLAGS_FYLKE_UPDATE_DATA_MUTATION}>
        {handler => {
          const key = Object.keys(data)[0]

          const nothingMessage = (
            <Message
              text={messages.nothingFound}
              className="message--has-small-mt"
              style={{
                fontSize: '16px',
              }}
            />
          )

          if (!key) {
            return nothingMessage
          }

          const { dates } = data[key]

          if (!dates.length) {
            return nothingMessage
          }

          return (
            <SidebarFilter
              classList={data[key].classes}
              dates={dates}
              onClassSelect={list => this.handleClassesSelect(list, handler)}
              onDateSelect={date => this.handleDateSelect(date, handler)}
            />
          )
        }}
      </Mutation>
    ))

    return (
      <>
        <Query query={SHOOTER_PLACES_QUERY}>{searchResponse.execute}</Query>

        {label !== '' && (
          <Query
            query={label === 'fylke' ? DATES_AND_CLASSES_COUNTY : DATES_AND_CLASSES_DISTRICT}
            variables={{ organizationId: selectedType }}
          >
            {filterResponse.execute}
          </Query>
        )}
      </>
    )
  }
}

export default withRouter(Sidebar)
