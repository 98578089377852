import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
  HOME_PATH,
  SEARCH_PATH,
  SHOOTER_PATH,
  CLUBTABLE_PATH,
  RANGED_FIELD_PATH,
  RANGED_BANE_PATH,
  START_LISTER_PATH,
  INITIAL_LAGVIS_LIST_PATH,
  FINAL_LAGVIS_LIST_PATH,
  SAMLAGS_FYLKESVISE_LIST_PATH,
  REPORT_PATH,
  IFRAME_PATH,
} from '../constants/routePaths'

import { Query } from 'react-apollo'
import { SHOOTER_MODAL_QUERY } from '../constants/queries'

import { Header } from '../containers'
import FinalLagvisList from './FinalLagvisList'
import FrontPage from './frontPage'
import IFramePage from './IFramePage'
import InitialLagvisList from './InitialLagvisList'
import SamlagsFylkesvise from './SamlagsFylkesvise'
import SearchPage from './searchPage'
import ShooterPage from './shooterPage'
import TopReport from './TopReport'
import ClubTablePage from './clubTablePage'
import RangedField from './RangedField'
import RangedBane from './RangedBane'
import StartList from './StartList'
import FourOhFour from './fourOhFour'

import Modal from '../components/modal'
import { ShooterModal } from '../containers'

class Pages extends Component {
  state = {
    isSearchShown: false,
    isNavShown: false,
  }

  handleShowSearch = (bool) => {
    this.setState({
      isSearchShown: bool,
    })
  }
  handleShowNav = (bool) => {
    this.setState({
      isNavShown: bool,
    })
  }

  render() {
    return (
      <>
        <Switch>
          <Route path={REPORT_PATH} component={TopReport} />
          <Route
            path="/"
            render={() => (
              <div
                style={{
                  paddingTop: '130px',
                }}
              >
                <Header appState={this.state} onShowSearch={this.handleShowSearch} onShowNav={this.handleShowNav} />
                <Switch>
                  <Route
                    path={`${CLUBTABLE_PATH}:id`}
                    render={(props) => (
                      <ClubTablePage {...props} onShowSearch={this.handleShowSearch} onShowNav={this.handleShowNav} />
                    )}
                  />
                  <Route
                    path={`${RANGED_FIELD_PATH}`}
                    render={(props) => (
                      <RangedField {...props} onShowSearch={this.handleShowSearch} onShowNav={this.handleShowNav} />
                    )}
                  />
                  <Route
                    path={`${RANGED_BANE_PATH}`}
                    render={(props) => (
                      <RangedBane {...props} onShowSearch={this.handleShowSearch} onShowNav={this.handleShowNav} />
                    )}
                  />
                  <Route
                    path={`${START_LISTER_PATH}`}
                    render={(props) => (
                      <StartList {...props} onShowSearch={this.handleShowSearch} onShowNav={this.handleShowNav} />
                    )}
                  />
                  <Route
                    path={`${INITIAL_LAGVIS_LIST_PATH}`}
                    render={(props) => (
                      <InitialLagvisList
                        {...props}
                        onShowSearch={this.handleShowSearch}
                        onShowNav={this.handleShowNav}
                      />
                    )}
                  />
                  <Route
                    path={`${FINAL_LAGVIS_LIST_PATH}`}
                    render={(props) => (
                      <FinalLagvisList {...props} onShowSearch={this.handleShowSearch} onShowNav={this.handleShowNav} />
                    )}
                  />
                  <Route
                    path={`${SAMLAGS_FYLKESVISE_LIST_PATH}`}
                    render={(props) => (
                      <SamlagsFylkesvise
                        {...props}
                        onShowSearch={this.handleShowSearch}
                        onShowNav={this.handleShowNav}
                      />
                    )}
                  />
                  <Route
                    path={`${SHOOTER_PATH}:id`}
                    render={(props) => (
                      <ShooterPage {...props} onShowSearch={this.handleShowSearch} onShowNav={this.handleShowNav} />
                    )}
                  />
                  <Route
                    path={IFRAME_PATH}
                    render={(props) => (
                      <IFramePage {...props} onShowSearch={this.handleShowSearch} onShowNav={this.handleShowNav} />
                    )}
                  />
                  <Route
                    path={SEARCH_PATH}
                    render={(props) => (
                      <SearchPage {...props} onShowSearch={this.handleShowSearch} onShowNav={this.handleShowNav} />
                    )}
                  />
                  <Route
                    path={HOME_PATH}
                    exact
                    render={(props) => (
                      <FrontPage {...props} onShowSearch={this.handleShowSearch} onShowNav={this.handleShowNav} />
                    )}
                  />
                  <Route
                    render={(props) => (
                      <FourOhFour {...props} onShowNav={this.handleShowNav} onShowSearch={this.handleShowSearch} />
                    )}
                  />
                </Switch>
              </div>
            )}
          />
        </Switch>

        <Query query={SHOOTER_MODAL_QUERY}>
          {({ data }) =>
            data.isShooterModalOpen ? (
              <Modal>
                <ShooterModal />
              </Modal>
            ) : null
          }
        </Query>
      </>
    )
  }
}

export default Pages
