import React from 'react'
import PropTypes from 'prop-types'
import ClassNamesService from '../../../services/ClassNamesService'

const TotalHead = ({ children, width = null, className = null }) => (
  <div
    className={ClassNamesService.execute(['head-cell head-cell--is-total', className])}
    style={{
      width,
    }}
  >
    {children}
  </div>
)

TotalHead.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, () => null]),
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
}

export default TotalHead
