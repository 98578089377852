import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import Toast from 'react-bootstrap/Toast'
import { connect } from 'react-redux'
import NotificationDTO from '../DTO/NotificationDTO'
import { notificationsEntity } from '../entities/NotificationsEntity'
import WithBootstrap from '../HOC/WithBootstrap'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import ClassNamesService from '../services/ClassNamesService'
import HtmlReactParser from 'html-react-parser'

const mapStateToProps = state => ({
  Notifications: state.Notifications,
})

const mapDispatchToProps = dispatch => ({})

class Notifications extends Component {
  static propTypes = {
    Notifications: PropTypes.object,
  }

  render() {
    return (
      <WithBootstrap isAbsolute>
        <div className="notifications-provider">
          <TransitionGroup>
            {notificationsEntity.notificationsToDisplay().map(item => (
              <CSSTransition
                timeout={{
                  appear: 200,
                  enter: 400,
                  exit: 200,
                }}
                key={item.id}
                classNames="notification"
              >
                <Toast
                  onClose={() => notificationsEntity.removeNotification(item.id)}
                  animation={false}
                  className={ClassNamesService.execute([
                    item.type === NotificationDTO.types.info && 'bg-info',
                    item.type === NotificationDTO.types.error && 'bg-danger',
                    item.type === NotificationDTO.types.success && 'bg-success',
                  ])}
                >
                  <Toast.Header>
                    <strong className="mr-auto">{item.title}</strong>
                  </Toast.Header>
                  <Toast.Body
                    className={ClassNamesService.execute([
                      item.type === NotificationDTO.types.info && 'text-light',
                      item.type === NotificationDTO.types.error && 'text-light',
                      item.type === NotificationDTO.types.success && 'text-light',
                    ])}
                  >
                    {HtmlReactParser(item.content.split('\n').join('<br />'))}
                    <br />
                    {item.actionTitle && item.handler && (
                      <Button onClick={() => item.handler(item)} variant="primary" size="sm" className="mt-2">
                        {item.actionTitle}
                      </Button>
                    )}
                  </Toast.Body>
                </Toast>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
      </WithBootstrap>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)
