import { requestTypes } from '../actions/RequestPending'
import { REQUEST_PENDING_FINISH, REQUEST_PENDING_START } from '../ActionTypes'

const requestsObject = {
  [requestTypes.FAVORITE]: false,
  [requestTypes.LOGIN]: false,
}

/**
 *
 * @param {Object} state
 * @param {Object} action
 * @param {String} action.type
 * @param {*} action.payload
 * @returns {Object}
 */
export default (state = requestsObject, action) => {
  switch (action.type) {
    case REQUEST_PENDING_START:
      if (!state.hasOwnProperty(action.payload.name)) {
        return state
      }

      return {
        ...state,
        [action.payload.name]: true,
      }

    case REQUEST_PENDING_FINISH:
      if (!state.hasOwnProperty(action.payload.name)) {
        return state
      }

      return {
        ...state,
        [action.payload.name]: false,
      }

    default:
      return state
  }
}
