import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ClassNamesService from '../services/ClassNamesService'

class SingleStepRender extends Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.element,
    tableKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    renderedItems: PropTypes.object,
    renderArea: PropTypes.shape({
      from: PropTypes.number,
      to: PropTypes.number,
    }),
    handleRenderItem: PropTypes.func,
  }

  static defaultProps = {
    className: '',
  }

  contentRef = React.createRef()

  get contentHeight() {
    return this.contentRef.current ? this.contentRef.current.offsetHeight : 0
  }

  get offsetTop() {
    return this.contentRef.current ? this.contentRef.current.offsetTop : 0
  }

  get hiddenHeight() {
    const { renderedItems, tableKey } = this.props

    return renderedItems.hasOwnProperty(tableKey) ? renderedItems[tableKey].afterRenderHeight : 0
  }

  isContentRendered = (renderArea = this.props.renderArea) => {
    return this.offsetTop + this.hiddenHeight >= renderArea.from && this.offsetTop <= renderArea.to
  }

  componentDidMount() {
    this.props.handleRenderItem(this.props.tableKey, this.contentHeight)
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    const nextRenderArea = nextProps.renderArea
    const isRenderedCurrently = this.isContentRendered()

    if (this.isContentRendered(nextRenderArea) === isRenderedCurrently) {
      return
    }

    isRenderedCurrently && this.props.handleRenderItem(this.props.tableKey, this.contentHeight)
  }

  render() {
    const { className, children } = this.props
    const shouldContentRender = this.isContentRendered()

    return (
      <div
        className={ClassNamesService.execute([className, 'flex-column'])}
        ref={this.contentRef}
        style={{
          height: !shouldContentRender && `${this.hiddenHeight}px`,
        }}
      >
        {shouldContentRender && children}
      </div>
    )
  }
}

export default SingleStepRender
