import React from 'react'
import PropTypes from 'prop-types'

import ClassNamesService from '../../services/ClassNamesService'

import { ReactComponent as ArrowIcon } from '../../images/arrow-icon.svg'

const CollapsibleCategory = ({ label, slug, children, isOpened, toggleCategory }) => {
  return (
    <ul className={ClassNamesService.execute(['filter__category', isOpened && 'filter__category--is-opened'])}>
      <li
        className={ClassNamesService.execute([
          'filter__category-label',
          isOpened && 'filter__category-label--is-opened',
        ])}
        onClick={() => toggleCategory(slug)}
      >
        <span>{label}</span>

        <ArrowIcon
          className={ClassNamesService.execute([
            'filter__category-arrow',
            isOpened && 'filter__category-arrow--is-opened',
          ])}
        />
      </li>

      {isOpened && children}
    </ul>
  )
}

CollapsibleCategory.propTypes = {
  label: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isOpened: PropTypes.bool,
  toggleCategory: PropTypes.func,
}

CollapsibleCategory.defaultProps = {
  isOpened: false,
  toggleCategory: () => {},
}

export default CollapsibleCategory
