import PropTypes from 'prop-types'
import React from 'react'

import '../styles/type-heading.scss'
import ClassNamesService from '../services/ClassNamesService'

const TypeHeading = ({ type, category, option, className = '' }) => (
  <div className={ClassNamesService.execute(['type-heading', className !== '' && `type-heading--is-${className}`])}>
    <h1 className="type-heading__main-info">
      {type}
      {category !== '' && ` - ${category}`}
    </h1>
    <span className="type-heading__secondary-info">{option}</span>
  </div>
)

TypeHeading.propTypes = {
  type: PropTypes.string.isRequired,
  category: PropTypes.string,
  option: PropTypes.string.isRequired,
  className: PropTypes.string,
}

TypeHeading.defaultProps = {
  className: '',
  category: '',
}

export default TypeHeading
