import { USER_ADD_INFO, USER_LOGOUT } from '../ActionTypes'

/**
 *
 * @param {Object} state
 * @param {Object} action
 * @param {String} action.type
 * @param {*} action.payload
 * @returns {Object}
 */
export default (state = {}, action) => {
  switch (action.type) {
    case USER_ADD_INFO:
      return action.payload

    case USER_LOGOUT:
      return {}

    default:
      return state
  }
}
