export default [
  { label: '2017', to: 'https://2017.lsres.no' },
  { label: '2018', to: 'https://2018.lsres.no' },
  { label: '2019', to: 'https://2019.lsres.no' },
  { label: '2021', to: 'https://2021.lsres.no' },
  { label: '2022', to: 'https://2022.lsres.no' },
  { label: '2023', to: 'https://2023.lsres.no' },
  { label: '2024', to: 'https://2024.lsres.no' },
]

const getYear = () => {
  const subdomain = window.location.host.split('.')[0]
  const localStorageYear = localStorage.getItem('year') || ''
  const conditions = [
    ['2017', '2018', '2019', '2021', '2022', '2023', '2024'].includes(subdomain) && subdomain,
    localStorageYear !== '' && localStorageYear,
    '2024',
  ]

  return conditions.find((condition) => condition)
}

export const availableYears = {
  2017: 2017,
  2018: 2018,
  2019: 2019,
  2021: 2021,
  2022: 2022,
  2023: 2023,
  2024: 2024,
}

export const applicationYear = getYear()

export const lastYear = Object.keys(availableYears).reduce((acc, item) => (item > acc ? item : acc), 0)

export const changeYear = (nextYear, origin = window.location.origin) => {
  const [connection, rest] = origin.split('//')
  const yearInUrl = Object.keys(availableYears).find((year) => rest.startsWith(`${year}`))

  if (!yearInUrl) {
    return [connection, '//', nextYear, '.', rest].join('')
  }

  return [connection, '//', rest.replace(yearInUrl, nextYear)].join('')
}
