import PropTypes from 'prop-types'
import React, { Component } from 'react'

class StepRender extends Component {
  static propTypes = {
    className: PropTypes.string,
    windowsCount: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  }

  static defaultProps = {
    className: '',
    windowsCount: 2,
  }

  renderedItems = {}
  state = {
    renderedItems: {},
  }

  get renderArea() {
    return {
      from: window.pageYOffset - window.innerHeight * this.props.windowsCount,
      to: window.pageYOffset + window.innerHeight * (this.props.windowsCount + 1),
    }
  }

  handleRenderItem = (tableKey, afterRenderHeight) => {
    this.renderedItems[tableKey] = { afterRenderHeight }

    this.setState({
      renderedItems: JSON.parse(JSON.stringify(this.renderedItems)),
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', () => this.forceUpdate())
  }

  render() {
    const { className, children } = this.props

    return (
      <div className={className}>
        {children.map(child =>
          React.cloneElement(child, {
            handleRenderItem: this.handleRenderItem,
            renderArea: this.renderArea,
            renderedItems: this.renderedItems,
          })
        )}
      </div>
    )
  }
}

export default StepRender
