export const setNewLoginToken = (newLoginToken, newLoginYear) => {
  localStorage.setItem('loginToken', newLoginToken)
  // Set expiration for 23 hours
  localStorage.setItem('loginExpireDate', `${Date.now() + 23 * 60 * 60 * 1000}`)
}

export const clearToken = () => {
  localStorage.setItem('loginExpireDate', `0`)
  localStorage.removeItem('dev-auth')
  window.location.reload()
}

export const devModeCredentials = () => {
  return localStorage.getItem('dev-auth')
}

export const isDevModeActive = () => {
  return !!devModeCredentials()
}

export const saveDevCredentials = (username, password) => {
  localStorage.setItem('dev-auth', btoa(`${username}:${password}`))
}

export const exitDevMode = () => {
  const confirm = window.confirm('Do you want to exit dev mode?')

  if (!confirm) {
    return
  }

  localStorage.removeItem('dev-auth')
  clearToken()
}

export default {
  setNewLoginToken,
}
