import ActionDTO from '../../DTO/ActionDTO'
import PageSettingDTO from '../../DTO/Storage/PageSettingDTO'
import httpInstance from '../../services/Http'
import { PAGE_SETTINGS_ADD_ITEM } from '../ActionTypes'

/**
 *
 * @param {PageSettingDTO[]} settingsList
 * @return {{payload: *, type: *}}
 */
export const pageSettingsAddItems = settingsList => {
  const pagesObject = {}

  settingsList.forEach(setting => {
    if (!pagesObject.hasOwnProperty(setting.page)) {
      pagesObject[setting.page] = {}
    }

    if (!pagesObject[setting.page].hasOwnProperty(setting.edition)) {
      pagesObject[setting.page][setting.edition] = {}
    }

    pagesObject[setting.page][setting.edition][setting.key] = setting.value
  })

  return new ActionDTO(PAGE_SETTINGS_ADD_ITEM, pagesObject).valueOf
}

/**
 *
 * @param {PageSettingDTO} _data
 * @return {function(*): Promise<*>}
 * @constructor
 */
export const PageSettingsAddItems = _data => async dispatch => {
  const data = await httpInstance.endpoints.editionTexts.get(_data)

  dispatch(
    pageSettingsAddItems([new PageSettingDTO('fp', data.edition, 'introText', decodeURIComponent(data.description))])
  )
}
