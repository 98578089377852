import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { ADMIN_FP_SETTINGS, ADMIN_LOGIN, ADMIN_MENU, ADMIN_REPORT } from '../../constants/routePaths'
import UserEntity from '../../entities/UserEntity'
import ProtectedRoute from '../ProtectedRoute'
import FPSettings from './FPSettings'
import MenuEdit from './MenuEdit'
import Reports from './Reports'
import AdminLogin from './AdminLogin'

const mapStateToProps = state => ({
  User: state.User,
})

const mapDispatchToProps = () => ({})

class Index extends Component {
  static propTypes = {
    User: PropTypes.object,
  }

  user = new UserEntity()

  render() {
    return (
      <>
        <Switch>
          <Route path={ADMIN_LOGIN} component={AdminLogin} />

          <ProtectedRoute
            component={MenuEdit}
            path={ADMIN_MENU()}
            redirectRoute={ADMIN_LOGIN}
            additionalCondition={this.user.isAdmin}
          />

          <ProtectedRoute
            component={Reports}
            path={ADMIN_REPORT()}
            redirectRoute={ADMIN_LOGIN}
            additionalCondition={this.user.isAdmin}
          />

          <ProtectedRoute
            component={FPSettings}
            path={ADMIN_FP_SETTINGS()}
            redirectRoute={ADMIN_LOGIN}
            additionalCondition={this.user.isAdmin}
          />
        </Switch>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)
