import React, { Component } from 'react'
import ClassNamesService from '../../services/ClassNamesService'

import { formatScoreHeads, resolveBaneColumnName, resolveDelimiter } from '../../services/shooterService'

import Table from '../../views/Table/Table'
import HeadSection from '../../views/Table/HeadSection'
import RowHOC from '../../containers/SamlagsFylkesvise/RowHOC'
import ShooterMedal from '../../VO/ShooterMedal'
import Row from '../Table/Row'
import ResizableCell from '../Table/ResizableCell'
import {
  NumberCell,
  ScoreCell,
  ShooterCell,
  TotalScoreCell,
  TotalStarCellStartList,
  AdditionalCell,
} from '../../views/Table/Cells'
import { ScoreHead, ShooterHead, TotalHead, TotalStarHead, AdditionalHead } from '../../views/Table/Heads'

class DefaultTable extends Component {
  TOTAL_CELL = 'TOTAL_CELL'

  getSerie = (series) => (name) => {
    return (
      series.find((serie) => serie.name === name) || {
        sum: 0,
        sumInner: 0,
      }
    )
  }

  middleHeads = (shooter) => {
    const formattedHeads = formatScoreHeads(shooter)
    const middleHeads = formattedHeads.slice(0, -1)

    return {
      items: middleHeads.length
        ? [
            {
              className: 'hidden-up-to-767',
              heads: middleHeads,
            },
          ]
        : [],
      secondaryColumns: middleHeads.length
        ? [
            {
              className: 'hidden-from-768',
              heads: middleHeads,
            },
          ]
        : [],
      formattedHeads,
      lastHead: formattedHeads.slice(-1)[0],
      middleHeads,
    }
  }

  render() {
    const { data } = this.props

    const shooters = data.getReport.ranks

    const shooter = shooters.find((shooter) => shooter.shooters[0].series.length)
    const resolvedMiddleHeads = this.middleHeads(shooter || shooters[0])
    const lastHead = resolvedMiddleHeads.lastHead

    const resolveHeadName = (headName) => resolveBaneColumnName(headName, shooter)

    const lastHeadKey = resolveHeadName('total')

    const totalSubscribe = this.props.subscribeGenerator(lastHeadKey)
    const totalUnsubscribe = this.props.unSubscribeGenerator(lastHeadKey)
    const totalMaxWidth = this.props.getMaxWidth(lastHeadKey)

    const middleColumnHandlers = {}

    resolvedMiddleHeads.middleHeads.forEach((headName) => {
      const key = resolveHeadName(headName)

      middleColumnHandlers[key] = {
        subscribe: this.props.subscribeGenerator(key),
        unsubscribe: this.props.unSubscribeGenerator(key),
        width: this.props.getMaxWidth(headName),
      }
    })

    return (
      <Table>
        <HeadSection>
          <TotalHead className="head-cell--is-class">Startnr.</TotalHead>
          <ShooterHead>Navn og Skytterlag</ShooterHead>

          {resolvedMiddleHeads.items.map((group) =>
            group.heads.map((head, idx) => (
              <AdditionalHead key={idx} className={group.className}>
                <ResizableCell {...middleColumnHandlers[resolveHeadName(head)]}>
                  <ScoreHead>{head}</ScoreHead>
                </ResizableCell>
              </AdditionalHead>
            ))
          )}
          <ResizableCell subscribe={totalSubscribe} unsubscribe={totalUnsubscribe} width={totalMaxWidth}>
            <TotalStarHead star="Resultat" score={lastHead} />
          </ResizableCell>
        </HeadSection>

        {shooters.map((shooter, key) => {
          const shooterSeries = this.getSerie(shooter.shooters[0].series)
          const lastSerie = shooterSeries(lastHead)

          const secondaryColumns = (
            <>
              {resolvedMiddleHeads.secondaryColumns.map((group) =>
                group.heads.map((headName, idx) => {
                  const cellSerie = shooterSeries(headName)

                  return (
                    <div key={idx} className={ClassNamesService.execute(['secondary-line', group.className])}>
                      <TotalHead className="head-cell--is-secondary-head">{headName}</TotalHead>
                      <TotalScoreCell hasMidPadding>
                        {resolveDelimiter(cellSerie.sum, cellSerie.sumInner)}
                      </TotalScoreCell>
                    </div>
                  )
                })
              )}
            </>
          )

          const shooterImage = ShooterMedal.medalImage(shooter)

          return (
            <RowHOC key={key} shooterId={resolveHeadName(shooter.shooters[0].organizationId)}>
              <Row
                secondaryRows={resolvedMiddleHeads.secondaryColumns.length && secondaryColumns}
                updateKey={data.getReport.name}
              >
                <NumberCell>{shooter.rank}</NumberCell>
                <ShooterCell isStartList image={shooterImage} name={shooter.name} club={shooter.shooters[0].clubName} />

                {resolvedMiddleHeads.items.map((group) =>
                  group.heads.map((headName, idx) => {
                    const cellSerie = shooterSeries(headName)

                    return (
                      <AdditionalCell className={group.className} key={idx}>
                        <ResizableCell {...middleColumnHandlers[resolveHeadName(headName)]}>
                          <ScoreCell hasBorder={idx !== resolvedMiddleHeads.formattedHeads.length - 1}>
                            {resolveDelimiter(cellSerie.sum, cellSerie.sumInner)}
                          </ScoreCell>
                        </ResizableCell>
                      </AdditionalCell>
                    )
                  })
                )}

                <ResizableCell subscribe={totalSubscribe} unsubscribe={totalUnsubscribe} width={totalMaxWidth}>
                  <TotalStarCellStartList score={lastSerie.sum} star={`${shooter.sum}/${shooter.sumInner}`} />
                </ResizableCell>
              </Row>
            </RowHOC>
          )
        })}
      </Table>
    )
  }
}

export default DefaultTable
