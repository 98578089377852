import queryString from 'query-string'
import React, { Component, useEffect } from 'react'
import { Mutation, Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { REPORTS_START_LIST_QUERY } from '../../constants/queries'
import { RANGED_FIELD_UPDATE_DATA_MUTATION } from '../../constants/mutations'

import Dropdown from '../../components/Dropdown'
import ReportsList from '../../entities/ReportsList'
import SubMenuCategory from '../../entities/SubMenuCategory'
import ResponseMiddleware from '../../Middleware/ResponseMiddleware'
import types from '../../Store'

import RadioOption from '../../views/SidebarFilter/RadioOption'

export const ReportsListInstance = new ReportsList()

const SL = new SubMenuCategory('SL', 'NM skogsløp Startlister')

ReportsListInstance.addCategories([SL])

class Sidebar extends Component {
  mutationHandler = () => {}

  state = {
    types: [SL.value],
    selectedType: SL.id,
    selectedLink: '',
  }

  componentDidMount() {
    const { selectedType, selectedLink } = queryString.parse(this.props.location.search)

    if (!selectedType || !selectedLink) {
      return
    }

    this.setState({
      selectedType,
      selectedLink,
    })

    this.updateLinkSelect(selectedLink)
  }

  /**
   *
   * @param {Object[]} response
   * @param {Boolean} response.webEnabled
   * @param {String} response.organizationGroup2Id
   * @param {String} response.organizationId
   * @param {String} response.nameShort
   * @param {null|Number} response.sortOrder
   * @returns {any[]}
   */

  updateLinkSelect = (category, option, callback) => {
    const activeType = this.state.types.find((type) => type.slug === this.state.selectedType) || {
      label: '',
    }

    option &&
      callback({
        variables: {
          type: activeType.slug,
          category: category,
          option: option.nameShort,
          organizationId: category,
        },
      })
    const { pathname, search } = this.props.location
    const previousSearch = queryString.parse(search)

    this.setState({
      selectedLink: category,
    })

    this.props.history.push({
      pathname,
      search: queryString.stringify({
        ...previousSearch,
        selectedLink: category,
        selectedType: activeType.slug,
      }),
    })

    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    this.props.onCloseSidebar()
  }

  onSelectType = (selectedType) => {
    this.setState({
      selectedType,
    })

    const { pathname, search } = this.props.location
    const previousSearch = queryString.parse(search)

    this.props.history.push({
      pathname,
      search: queryString.stringify({
        ...previousSearch,
        selectedType,
      }),
    })
  }

  componentWillUnmount() {
    this.mutationHandler({
      variables: types.rangedField,
    })
  }

  render() {
    const { types, selectedType } = this.state

    const response = new ResponseMiddleware()
    response.setComponent((data) => (
      <Mutation mutation={RANGED_FIELD_UPDATE_DATA_MUTATION}>
        {(handler) => {
          this.mutationHandler = handler

          return (
            <>
              <SidebarFilter
                selectedLink={this.state.selectedLink}
                updateLinkSelect={(slug, item) => this.updateLinkSelect(slug, item, handler)}
                options={data.getReportList}
              />
            </>
          )
        }}
      </Mutation>
    ))

    return (
      <>
        <Dropdown options={types} selectedOption={selectedType} onChange={this.onSelectType} />

        <Query
          query={REPORTS_START_LIST_QUERY}
          variables={{ organizationGroup1Id: selectedType, organizationGroup2Id: 'START_LIST' }}
        >
          {response.execute}
        </Query>
      </>
    )
  }
}

export default withRouter(Sidebar)

const SidebarFilter = ({ options, selectedLink, updateLinkSelect }) => {
  useEffect(() => {
    if (selectedLink) {
      updateLinkSelect(
        selectedLink,
        options.find((item) => item.organizationId === selectedLink)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // check double request

  return (
    <>
      {options.map((item, index) => (
        <RadioOption
          key={index}
          label={item.nameShort}
          slug={item.organizationId}
          isSelected={selectedLink === item.organizationId}
          selectLink={(slug) => updateLinkSelect(slug, item)}
        />
      ))}
    </>
  )
}
