import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'

import '../styles/sidebar-search-box.scss'
import ClassNamesService from '../services/ClassNamesService'
import ResultsList from '../views/ResultsList'

class SidebarSearchBox extends Component {
  state = {
    search: '',
    selectedId: '',
  }

  static defaultProps = {
    label: 'skyttersamlag eller fylke',
    placeholder: 'Søk samlag eller fylke',
    stringKey: 'sidebar-search',
    onSelect: () => {},
    variants: [],
  }

  static propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    stringKey: PropTypes.string,
    onSelect: PropTypes.func,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
        label: PropTypes.string,
      })
    ),
  }

  get filteredVariants() {
    const searchPhrase = this.state.search.toLowerCase()

    return this.props.variants.filter(variant => variant.text.toLowerCase().startsWith(searchPhrase))
  }

  componentDidMount() {
    const search = JSON.parse(JSON.stringify(qs.parse(window.location.search)))

    if (!search.hasOwnProperty(this.props.stringKey)) {
      return
    }

    const selectedVariant = this.props.variants.find(variant => variant.id === search[this.props.stringKey])

    if (!selectedVariant) {
      return
    }

    this.setState({
      search: selectedVariant.text,
      selectedId: selectedVariant.id,
    })

    this.props.onSelect(selectedVariant)
  }

  handleSearch = e => {
    this.setState({
      search: e.target.value,
    })
  }

  elementClickHandler = element => {
    this.setState({
      search: element.text,
      selectedId: element.id,
    })

    this.props.closeWrapper()

    const previousSearch = JSON.parse(JSON.stringify(qs.parse(window.location.search)))

    this.props.history.push({
      search: qs.stringify({
        ...previousSearch,
        [this.props.stringKey]: element.id,
      }),
    })

    this.props.onSelect(element)
  }

  handleVariantHighlight = data => {
    return data.id === this.state.selectedId
  }

  render() {
    const { search } = this.state
    const { label, placeholder, isWrapperOpened, wrapperId } = this.props

    return (
      <div className="sidebar-search">
        <span className="sidebar-search__label">{label}</span>

        <div className="sidebar-search__shadow-wrapper" onClick={this.props.bindOutsideClick} id={wrapperId}>
          <input
            className={ClassNamesService.execute([
              'sidebar-search__input',
              isWrapperOpened && 'sidebar-search__input--is-opened',
            ])}
            value={search}
            onClick={this.props.openWrapper}
            onChange={this.handleSearch}
            placeholder={placeholder}
          />

          <ResultsList
            onSelect={this.elementClickHandler}
            handleVariantHighlight={this.handleVariantHighlight}
            isVisible={isWrapperOpened}
            variantsList={this.filteredVariants}
            className="results-list__wrapper--is-sidebar-search"
          />
        </div>
      </div>
    )
  }
}

export default withRouter(SidebarSearchBox)
