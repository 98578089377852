import React from 'react'
import tryService from '../services/tryService'

import { findResults, findSum, getEventDate, getEventTime } from '../services/shooterService'

import '../styles/shooter-table.scss'

const ShooterResultTable = ({ shooterInfo, shooterInfo: { events, distinctions } }) => {
  let baneResults,
    feltResults,
    minneResults,
    skogslopResults,
    stangResults,
    felthurtigResults,
    banefinaleResults,
    feltfinaleResults

  // distinctions
  const distinctionsResultsGetter = (code, eventType = null) =>
    tryService(() =>
      distinctions
        .filter((distinction) => {
          if (!eventType) {
            return distinction.organizationEventId === code
          }

          return distinction.organizationEventId === code && distinction.organizationEventType === eventType
        })
        .map((result) => result.name)
        .join(', ')
    ) || ''

  // Bane
  baneResults = findResults(events, 'Bane', 'MAIN', 'ORDINARY')
  const bane1 = tryService(() => baneResults.checkinDateTime && getEventDate(baneResults.checkinDateTime))
  const bane2 = tryService(() => baneResults.checkinDateTime && getEventTime(baneResults.checkinDateTime))
  const bane3 = tryService(() => baneResults.relayNumber)
  const bane4 = tryService(() => baneResults.targetNumber)
  const bane5 = findSum(baneResults, '25 skudd')
  const bane6 = distinctionsResultsGetter('BA', 'MAIN')

  // Felt
  feltResults = findResults(events, 'Felt', 'MAIN', 'ORDINARY')
  const felt1 = tryService(() => feltResults.checkinDateTime && getEventDate(feltResults.checkinDateTime))
  const felt2 = tryService(() => feltResults.checkinDateTime && getEventTime(feltResults.checkinDateTime))
  const felt3 = tryService(() => feltResults.relayNumber)
  const felt4 = tryService(() => feltResults.targetNumber)
  const felt5 = findSum(feltResults, '30 skudd')
  const felt6 = distinctionsResultsGetter('FE', 'MAIN')

  // Minne
  minneResults = findResults(events, 'Minne', 'MAIN', 'ORDINARY')
  const minne1 = null
  const minne2 = null
  const minne3 = null
  const minne4 = null
  const minne5 = findSum(minneResults, 'Minne')
  const minne6 = distinctionsResultsGetter('MI')

  // Skogslop
  skogslopResults = findResults(events, 'Skogsløp', 'MAIN', 'ORDINARY')
  const skogslop1 = null
  const skogslop2 = null
  const skogslop3 = null
  const skogslop4 = null
  const skogslop5 = findSum(skogslopResults, 'Innledende')
  const skogslop6 = distinctionsResultsGetter('SL')

  // Stang
  stangResults = findResults(events, 'Stang', 'MAIN', 'ORDINARY')
  const stang1 = tryService(() => stangResults.checkinDateTime && getEventDate(stangResults.checkinDateTime))
  const stang2 = tryService(() => stangResults.checkinDateTime && getEventTime(stangResults.checkinDateTime))
  const stang3 = tryService(() => stangResults.relayNumber)
  const stang4 = tryService(() => stangResults.targetNumber)
  const stang5 = findSum(stangResults, 'Innledende')
  const stang6 = distinctionsResultsGetter('ST')

  // Felthurtig
  felthurtigResults = findResults(events, 'Felthurtig', 'MAIN', 'ORDINARY')
  const felthurtig1 = null
  const felthurtig2 = null
  const felthurtig3 = null
  const felthurtig4 = null
  const felthurtig5 = findSum(felthurtigResults, 'Innledende')
  const felthurtig6 = distinctionsResultsGetter('FH')

  // BaneFinale
  banefinaleResults = findResults(events, 'Bane', 'FINALE', 'ORDINARY')
  const banefinale1 = tryService(
    () => banefinaleResults.checkinDateTime && getEventDate(banefinaleResults.checkinDateTime)
  )
  const banefinale2 = tryService(
    () => banefinaleResults.checkinDateTime && getEventTime(banefinaleResults.checkinDateTime)
  )
  const banefinale3 = null
  const banefinale4 = null
  const banefinale5 = findSum(banefinaleResults, '35 skudd')
  const banefinale6 = distinctionsResultsGetter('BA', 'FINALE')

  // FeltFinale
  feltfinaleResults = findResults(events, 'Felt', 'FINALE', 'ORDINARY')
  const feltfinale1 = tryService(
    () => feltfinaleResults.checkinDateTime && getEventDate(feltfinaleResults.checkinDateTime)
  )
  const feltfinale2 = tryService(
    () => feltfinaleResults.checkinDateTime && getEventTime(feltfinaleResults.checkinDateTime)
  )
  const feltfinale3 = null
  const feltfinale4 = null
  const feltfinale5 = findSum(feltfinaleResults, '42 skudd')
  const feltfinale6 = distinctionsResultsGetter('FE', 'FINALE')

  return events ? (
    <>
      <p className="shooter-table__klasse">{tryService(() => shooterInfo.defaultClassName)}</p>
      <div className="shooter-table">
        <div className="shooter-table__row">
          <div className="shooter-table__cell">øvelse</div>
          <div className="shooter-table__cell">dato</div>
          <div className="shooter-table__cell">opprop</div>
          <div className="shooter-table__cell">lag</div>
          <div className="shooter-table__cell">skive</div>
          <div className="shooter-table__cell">resultat</div>
          <div className="shooter-table__cell">beger</div>
        </div>
        <div className="shooter-table__row">
          <div className="shooter-table__cell">bane</div>
          <div className="shooter-table__cell">{bane1}</div>
          <div className="shooter-table__cell">{bane2}</div>
          <div className="shooter-table__cell">{bane3}</div>
          <div className="shooter-table__cell">{bane4}</div>
          <div className="shooter-table__cell">{bane5}</div>
          <div className="shooter-table__cell">{bane6}</div>
        </div>
        <div className="shooter-table__row">
          <div className="shooter-table__cell">felt</div>
          <div className="shooter-table__cell">{felt1}</div>
          <div className="shooter-table__cell">{felt2}</div>
          <div className="shooter-table__cell">{felt3}</div>
          <div className="shooter-table__cell">{felt4}</div>
          <div className="shooter-table__cell">{felt5}</div>
          <div className="shooter-table__cell">{felt6}</div>
        </div>
        <div className="shooter-table__row">
          <div className="shooter-table__cell">minne</div>
          <div className="shooter-table__cell">{minne1}</div>
          <div className="shooter-table__cell">{minne2}</div>
          <div className="shooter-table__cell">{minne3}</div>
          <div className="shooter-table__cell">{minne4}</div>
          <div className="shooter-table__cell">{minne5}</div>
          <div className="shooter-table__cell">{minne6}</div>
        </div>
        <div className="shooter-table__row">
          <div className="shooter-table__cell">Skogsløp</div>
          <div className="shooter-table__cell">{skogslop1}</div>
          <div className="shooter-table__cell">{skogslop2}</div>
          <div className="shooter-table__cell">{skogslop3}</div>
          <div className="shooter-table__cell">{skogslop4}</div>
          <div className="shooter-table__cell">{skogslop5}</div>
          <div className="shooter-table__cell">{skogslop6}</div>
        </div>
        <div className="shooter-table__row">
          <div className="shooter-table__cell">stang</div>
          <div className="shooter-table__cell">{stang1}</div>
          <div className="shooter-table__cell">{stang2}</div>
          <div className="shooter-table__cell">{stang3}</div>
          <div className="shooter-table__cell">{stang4}</div>
          <div className="shooter-table__cell">{stang5}</div>
          <div className="shooter-table__cell">{stang6}</div>
        </div>
        <div className="shooter-table__row">
          <div className="shooter-table__cell">felthurtig</div>
          <div className="shooter-table__cell">{felthurtig1}</div>
          <div className="shooter-table__cell">{felthurtig2}</div>
          <div className="shooter-table__cell">{felthurtig3}</div>
          <div className="shooter-table__cell">{felthurtig4}</div>
          <div className="shooter-table__cell">{felthurtig5}</div>
          <div className="shooter-table__cell">{felthurtig6}</div>
        </div>
        <div className="shooter-table__row">
          <div className="shooter-table__cell">banefinale</div>
          <div className="shooter-table__cell">{banefinale1}</div>
          <div className="shooter-table__cell">{banefinale2}</div>
          <div className="shooter-table__cell">{banefinale3}</div>
          <div className="shooter-table__cell">{banefinale4}</div>
          <div className="shooter-table__cell">{banefinale5}</div>
          <div className="shooter-table__cell">{banefinale6}</div>
        </div>
        <div className="shooter-table__row">
          <div className="shooter-table__cell">feltfinale</div>
          <div className="shooter-table__cell">{feltfinale1}</div>
          <div className="shooter-table__cell">{feltfinale2}</div>
          <div className="shooter-table__cell">{feltfinale3}</div>
          <div className="shooter-table__cell">{feltfinale4}</div>
          <div className="shooter-table__cell">{feltfinale5}</div>
          <div className="shooter-table__cell">{feltfinale6}</div>
        </div>
      </div>
    </>
  ) : null
}

export default ShooterResultTable
