import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import QueryString from 'query-string'

class IFramePage extends Component {
  static propTypes = {}

  get frameUrl() {
    const search = QueryString.parse(this.props.location.search.slice(1))

    if (!search.url) {
      return ''
    }

    return decodeURIComponent(search.url)
  }

  componentDidMount() {
    this.props.onShowSearch(false)
    this.props.onShowNav(false)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search) {
      this.props.onShowSearch(false)
      this.props.onShowNav(false)
    }
  }

  render() {
    const url = this.frameUrl

    // eslint-disable-next-line jsx-a11y/iframe-has-title
    return <div className="iframe-wrapper">{url ? <iframe src={url} frameBorder="0" /> : null}</div>
  }
}

export default withRouter(IFramePage)
