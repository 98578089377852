class LoadingState {
  /**
   *
   * @private
   */
  _classRef
  /**
   * @type {{}}
   * @private
   */
  _availableKeys
  /**
   * @type {string}
   * @private
   */
  _stateKey

  constructor(_classRef, _availableKeys = null, _stateKey = null) {
    this._classRef = _classRef
    this._availableKeys = _availableKeys === null ? {} : _availableKeys
    this._stateKey = _stateKey === null ? '_loadingRequests' : _stateKey

    const loadingObject = {}
    Object.keys(this._availableKeys).forEach(key => {
      loadingObject[key] = false
    })

    if (!this._classRef.state) {
      this._classRef.state = {}
    }

    this._classRef.state[this._stateKey] = loadingObject
  }

  /**
   *
   * @param {string} key
   * @return {boolean}
   */
  isLoading = key => {
    return this._classRef.state[this._stateKey][key]
  }

  /**
   *
   * @param {string[]} keys
   * @param {boolean} nextKeysState
   * @return {Promise<unknown>}
   * @private
   */
  _changeState = async (keys, nextKeysState) => {
    const updatedKeys = keys.reduce(
      (acc, key) =>
        this._availableKeys.hasOwnProperty(key)
          ? {
              ...acc,
              [key]: nextKeysState,
            }
          : acc,
      {}
    )

    return new Promise(r =>
      this._classRef.setState(
        prevState => ({
          [this._stateKey]: {
            ...prevState[this._stateKey],
            ...updatedKeys,
          },
        }),
        r
      )
    )
  }

  /**
   *
   * @param {string[]} keys
   * @return {Promise<unknown>}
   */
  startLoading = async keys => {
    return this._changeState(keys, true)
  }

  /**
   *
   * @param {string[]} keys
   * @return {Promise<unknown>}
   */
  finishLoading = async keys => {
    return this._changeState(keys, false)
  }
}

export default LoadingState
