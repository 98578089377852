import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { userCabinet } from '../../constants/messages'
import { GET_SHOOTER } from '../../constants/queries'
import { SHOOTER_PATH } from '../../constants/routePaths'
import UserEntity from '../../entities/UserEntity'
import { ReactComponent as DeleteIcon } from '../../images/delete.svg'
import ResponseMiddleware, { LoadingTemplate } from '../../Middleware/ResponseMiddleware'
import { FavoritesRemoveShooter } from '../../redux/actions/Favorites'
import { getNameInitials } from '../../services/shooterService'
import MenuCell from '../admin/views/MenuCell'
import Paper from '../admin/views/Paper'
import SubmitButton from '../admin/views/SubmitButton'
import Layout from './Layout'

const mapStateToProps = state => ({
  User: state.User,
  Favorites: state.Favorites,
})

const mapDispatchToProps = dispatch => ({
  FavoritesRemoveShooter: id => dispatch(FavoritesRemoveShooter(id)),
})

class FavoriteShooters extends Component {
  static propTypes = {
    User: PropTypes.object,
    Favorites: PropTypes.object,

    FavoritesRemoveShooter: PropTypes.func,
  }

  user = new UserEntity()

  shooterNames = {}

  response = new ResponseMiddleware()
    .setLoadingTemplate(() => <LoadingTemplate className="js-loaded--is-small" />)
    .setComponent(data => {
      const shooterInfo = data.getShooter && data.getShooter[0]

      if (!shooterInfo) {
        return <></>
      }

      this.shooterNames[shooterInfo.organizationId] = shooterInfo.name

      return (
        <li className="admin-table__row admin-menu-table__row admin-menu-table__row--is-shooters">
          <MenuCell>
            <span className="search-result__avatar">{getNameInitials(shooterInfo.name)}</span>
          </MenuCell>
          <MenuCell>{shooterInfo.name}</MenuCell>
          <MenuCell>{shooterInfo.clubName}</MenuCell>
          <MenuCell align="end" className="admin-table__row-text--has-badges">
            {shooterInfo.categories.map(category => (
              <span className="badge" key={category}>
                {category}
              </span>
            ))}
          </MenuCell>
          <MenuCell align="end">
            <SubmitButton
              className="admin-button--is-shooter-link"
              handleSubmit={() => {
                this.props.history.push(SHOOTER_PATH + shooterInfo.organizationId)
              }}
            >
              SKYTTER KORT
            </SubmitButton>
          </MenuCell>
          <MenuCell align="center">
            <button className="admin-modal__delete-button" onClick={() => this.deleteItem(shooterInfo.organizationId)}>
              <DeleteIcon className="admin-modal__delete-icon" />
            </button>
          </MenuCell>
        </li>
      )
    })

  get shooterIds() {
    return Object.keys(this.props.Favorites)
  }

  deleteItem = async id => {
    const shooterName = this.shooterNames[id]

    const isConfirm = window.confirm(
      `Do you want to delete ${shooterName ? `"${shooterName}"` : 'shooter'} from favorites?`
    )

    if (!isConfirm) {
      return
    }

    this.props.FavoritesRemoveShooter(id)
  }

  render() {
    return (
      <Layout title={userCabinet.favoriteShooters} menuType={Layout.menuTypes.user}>
        <Paper>
          <ul className="admin-table">
            <li className="admin-table__row admin-table__row--is-heading admin-menu-table__row--is-shooters">
              <MenuCell />
              <MenuCell>Name</MenuCell>
              <MenuCell>Skytterlag</MenuCell>
              <MenuCell align="end">Klasse</MenuCell>
              <MenuCell />
              <MenuCell />
            </li>
            {this.shooterIds.map(id => (
              <Query query={GET_SHOOTER} variables={{ organizationId: id }} key={id}>
                {this.response.execute}
              </Query>
            ))}
          </ul>
        </Paper>
      </Layout>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FavoriteShooters)
)
