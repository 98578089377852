import ActionDTO from '../../DTO/ActionDTO'
import ReportDTO from '../../DTO/ReportDTO'
import httpInstance from '../../services/Http'
import { REPORTS_ADD_ITEM } from '../ActionTypes'

export const reportItemsAddItems = (links, edition) => new ActionDTO(REPORTS_ADD_ITEM, { links, edition }).valueOf

export const ReportItemsAddItems = edition => async dispatch => {
  const data = await httpInstance.endpoints.reports.items(edition)

  const links = data.map(item => new ReportDTO().fromBackend(item))

  dispatch(reportItemsAddItems(links, edition))

  return links
}
