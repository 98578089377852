import gql from 'graphql-tag'

export const OPEN_SHOOTER_MODAL_MUTATION = gql`
  mutation OpenShooterModalMutation($shooterModalId: String!) {
    openShooterModalMutation(shooterModalId: $shooterModalId) @client
  }
`

export const CLOSE_SHOOTER_MODAL_MUTATION = gql`
  mutation {
    closeShooterModalMutation @client
  }
`

export const RANGED_FIELD_UPDATE_DATA_MUTATION = gql`
  mutation RangedFieldUpdateDataMutation(
    $type: String!
    $category: String!
    $option: String!
    $organizationId: String!
  ) {
    rangedFieldUpdateDataMutation(type: $type, category: $category, option: $option, organizationId: $organizationId)
      @client
  }
`

export const LAGVIS_LIST_UPDATE_DATA_MUTATION = gql`
  mutation LagvisListUpdateDataMutation(
    $type: String!
    $category: String!
    $option: String!
    $timeString: String!
    $number: Int!
    $organizationId: String!
  ) {
    lagvisListUpdateDataMutation(
      type: $type
      category: $category
      option: $option
      number: $number
      timeString: $timeString
      organizationId: $organizationId
    ) @client
  }
`

gql`
  type SelectBoxType {
    id: String
    text: String
  }
`

export const SAMLAGS_FYLKE_UPDATE_DATA_MUTATION = gql`
  mutation SamlagsFylkeUpdateDataMutation(
    $organizationId: String
    $fromDate: Date
    $toDate: Date
    $classesSelected: [SelectBoxType]
    $label: String
  ) {
    samlagsFylkeUpdateDataMutation(
      organizationId: $organizationId
      fromDate: $fromDate
      toDate: $toDate
      classesSelected: $classesSelected
      label: $label
    ) @client
  }
`

export const SAMLAGS_FYLKE_UPDATE_SHOOTERS_MUTATION = gql`
  mutation SamlagsFylkeUpdateShootersMutation($id: String, $isOpened: Boolean) {
    samlagsFylkeUpdateShootersMutation(id: $id, isOpened: $isOpened) @client
  }
`
