class SelectedDate {
  _startDate
  _endDate
  _delimiter = '_'

  setDateAsParameters(startDate, endDate) {
    this._startDate = startDate
    this._endDate = endDate
  }

  setDateAsString(dateString) {
    ;[this._startDate, this._endDate] = dateString.split(this._delimiter)
  }

  get startDate() {
    return this._startDate
  }

  get endDate() {
    return this._endDate
  }

  get dateString() {
    return this.startDate + this._delimiter + this.endDate
  }
}

export default SelectedDate
