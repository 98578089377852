import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ResizableTable from '../components/ShootersTable/ResizableTable'

class SamlagsTable extends Component {
  static propTypes = {
    organizationId: PropTypes.string,
    organizationGroup1Id: PropTypes.string,
    data: PropTypes.array,
    componentsTree: PropTypes.object,
  }

  resolveComponent = (organizationGroup1Id, organizationGroup2Id = null) => {
    if (!this.props.componentsTree.hasOwnProperty(organizationGroup1Id)) {
      return this.props.componentsTree.default
    }

    if (!this.props.componentsTree[organizationGroup1Id].hasOwnProperty(organizationGroup2Id)) {
      return this.props.componentsTree[organizationGroup1Id].default
    }

    return this.props.componentsTree[organizationGroup1Id][organizationGroup2Id]
  }

  render() {
    const { organizationId, organizationGroup1Id, data } = this.props

    const Component = this.resolveComponent(organizationGroup1Id)

    return (
      <ResizableTable
        updateParameters={{
          organizationId,
        }}
      >
        <Component data={data} />
      </ResizableTable>
    )
  }
}

export default SamlagsTable
