import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { ReactComponent as MoreDots } from '../images/more-dots.svg'
import { ReactComponent as CrossIcon } from '../images/cross-icon.svg'
import ClassNamesService from '../services/ClassNamesService'
import '../styles/sidebar.scss'

class SidebarHOC extends Component {
  state = {
    isOpened: false,
    canClose: false,
  }

  static propTypes = {
    isOpened: PropTypes.bool,
  }

  static defaultProps = {
    isOpened: true,
  }

  toggleSidebar = async () => {
    await new Promise(resolve =>
      this.setState(
        prevState => ({
          isOpened: !prevState.isOpened,
        }),
        resolve
      )
    )

    if (this.state.isOpened) {
      document.documentElement.style.overflow = 'hidden'

      return
    }

    document.documentElement.style.overflow = null
  }

  async componentDidMount() {
    if (this.props.isOpened) {
      await this.toggleSidebar()
    }

    setTimeout(() => {
      this.setState({
        canClose: true,
      })
    }, 2000)
  }

  closeSidebar = () => {
    if (!this.state.canClose) {
      return
    }

    this.setState({
      isOpened: false,
    })

    document.documentElement.style.overflow = null
  }

  render() {
    const { isOpened } = this.state

    return (
      <>
        {isOpened && <div className="sidebar__under-layer" onClick={this.closeSidebar} />}
        <aside className={ClassNamesService.execute(['sidebar', isOpened && 'sidebar--is-visible'])}>
          {React.cloneElement(this.props.children, {
            onCloseSidebar: this.closeSidebar,
          })}
        </aside>
        <button
          onClick={this.toggleSidebar}
          className={ClassNamesService.execute(['sidebar__button', isOpened && 'sidebar__button--is-opened'])}
        >
          {isOpened ? <CrossIcon /> : <MoreDots />}
        </button>
      </>
    )
  }
}

export default SidebarHOC
