class OutsideClickVO {
  _containerRef
  _onOutsideClick

  constructor(containerRef, onOutsideClick) {
    this._containerRef = containerRef
    this._onOutsideClick = onOutsideClick
  }

  /**
   *
   * @param e
   * @private
   */
  _handleClickOutside = e => {
    if (!this._containerRef.current) {
      return
    }

    if (this._containerRef.current.contains(e.target)) {
      return
    }

    this._onOutsideClick()
    this.removeOutsideHandler()
  }

  removeOutsideHandler = () => {
    document.removeEventListener('click', this._handleClickOutside)
  }

  addOutsideHandler = () => {
    document.addEventListener('click', this._handleClickOutside)
  }
}

export default OutsideClickVO
