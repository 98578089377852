class LinkVO {
  /**
   * @private
   * @type {string}
   */
  _path
  /**
   * @private
   * @type {string}
   */
  _origin

  constructor(path, origin = null) {
    this._path = path
    this._origin = origin || window.location.origin
  }

  get isAbsolute() {
    return ['http', '//'].find(string => this._path.startsWith(string))
  }

  get relative() {
    return this.isAbsolute ? this._path.replace(this._origin, '') : this._path
  }

  get absolute() {
    if (this.isAbsolute) {
      return this._path
    }

    return this._path.startsWith('/') ? `${this._origin}${this._path}` : `${this._origin}/${this._path}`
  }
}

export default LinkVO
