import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Mutation, Query } from 'react-apollo'
import { SAMLAGS_FYLKE_UPDATE_SHOOTERS_MUTATION } from '../../constants/mutations'
import { SAMLAGS_FYLKE_SHOOTERS_QUERY } from '../../constants/queries'
import ResponseMiddleware from '../../Middleware/ResponseMiddleware'

class RowHOC extends Component {
  static propTypes = {
    shooterId: PropTypes.string,
  }

  render() {
    const { shooterId, children } = this.props
    const response = new ResponseMiddleware()
    response.setComponent(({ samlagsFylkeShooters }) => (
      <Mutation mutation={SAMLAGS_FYLKE_UPDATE_SHOOTERS_MUTATION}>
        {handler => {
          const handleToggle = isOpened => {
            handler({
              variables: {
                id: shooterId,
                isOpened,
              },
            })
          }

          const list = JSON.parse(samlagsFylkeShooters.list)
          const isInfoOpened = list.hasOwnProperty(shooterId)

          return (
            <>{React.cloneElement(children, { toggleHandler: handleToggle, isAdditionalInfoOpened: isInfoOpened })}</>
          )
        }}
      </Mutation>
    ))

    return <Query query={SAMLAGS_FYLKE_SHOOTERS_QUERY}>{response.execute}</Query>
  }
}

export default RowHOC
