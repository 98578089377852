import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import messages, { actions, adminEdit, notifications } from '../../constants/messages'
import { validationMessages } from '../../constants/messages'
import { ADMIN_MENU } from '../../constants/routePaths'
import NotificationDTO from '../../DTO/NotificationDTO'
import SelectItemDTO from '../../DTO/SelectItemDTO'
import { notificationsEntity } from '../../entities/NotificationsEntity'
import { menuItemsAddItems, MenuItemsAddItems } from '../../redux/actions/MenuItems'
import httpInstance from '../../services/Http'
import ModalComponent from '../../views/ModalComponent'
import AdminMenuItemVO from '../../VO/AdminMenuItemVO'
import ClassNamesService from '../../services/ClassNamesService'
import Layout from '../containers/Layout'
import Select from './components/Select'
import Input from './views/Input'
import MenuBadge from './views/MenuBadge'
import MenuCell from './views/MenuCell'
import Paper from './views/Paper'
import { ReactComponent as DotsIcon } from '../../images/dots.svg'
import { ReactComponent as ChevronDownIcon } from '../../images/chevron-down.svg'
import { ReactComponent as EditIcon } from '../../images/edit.svg'
import { ReactComponent as DeleteIcon } from '../../images/delete.svg'
import SubmitButton from './views/SubmitButton'
import QueryString from 'query-string'

const mapStateToProps = state => ({
  MenuItems: state.MenuItems,
})

const mapDispatchToProps = dispatch => ({
  MenuItemsAddItems: edition => dispatch(MenuItemsAddItems(edition)),
  menuItemsAddItems: (links, edition) => dispatch(menuItemsAddItems(links, edition)),
})

class MenuEdit extends Component {
  static propTypes = {
    MenuItems: PropTypes.object,
    MenuItemsAddItems: PropTypes.func,
    menuItemsAddItems: PropTypes.func,
  }

  state = {
    isEditing: false,
    openedId: null,
    isModalOpened: false,
    isModalSingleEdit: false,
    isLoading: false,

    order: {},

    menuType: new SelectItemDTO('Velg type'),
    title: '',
    url: '',
    titleErrors: [],
    urlErrors: [],
    modalOrder: null,
    modalId: null,
  }

  get edition() {
    return this.props.match.params.year || AdminMenuItemVO.editions['2019']
  }

  get isSU() {
    const search = QueryString.parse(this.props.location.search.slice(1))

    return +search.su === 1
  }

  get menuTypes() {
    const defaultOptions = {
      [AdminMenuItemVO.types.iframe]: new SelectItemDTO(
        AdminMenuItemVO.mappedTypes[AdminMenuItemVO.types.iframe],
        AdminMenuItemVO.types.iframe
      ),
      [AdminMenuItemVO.types.xml]: new SelectItemDTO(
        AdminMenuItemVO.mappedTypes[AdminMenuItemVO.types.xml],
        AdminMenuItemVO.types.xml
      ),
    }

    if (!this.isSU) {
      return defaultOptions
    }

    defaultOptions[AdminMenuItemVO.types.internal] = new SelectItemDTO(
      AdminMenuItemVO.mappedTypes[AdminMenuItemVO.types.internal],
      AdminMenuItemVO.types.internal
    )

    return defaultOptions
  }

  /**
   *
   * @return {AdminMenuItemVO[]}
   */
  get items() {
    if (!this.props.MenuItems[this.edition]) {
      return []
    }

    if (!this.state.isEditing) {
      return this.props.MenuItems[this.edition].map((item, index) =>
        new AdminMenuItemVO().fromInstance(item).setOrder(index + 1)
      )
    }

    return this.props.MenuItems[this.edition]
      .sort((a, b) => {
        const aOrder = this.state.order[a.id] || a.order
        const bOrder = this.state.order[b.id] || b.order

        if (aOrder < bOrder) {
          return -1
        }

        if (aOrder > bOrder) {
          return 1
        }

        return 0
      })
      .map(item => {
        const order =
          this.state.order[item.id] === 0 || this.state.order[item.id] ? this.state.order[item.id] : item.order

        return new AdminMenuItemVO().fromInstance(item).setOrder(order)
      })
  }

  handleRowOpen = id => {
    if (this.state.isEditing) {
      return
    }

    const nextId = this.state.openedId === id ? null : id

    this.setState({
      openedId: nextId,
    })
  }

  handleModalStateChange = value => {
    this.setState({
      isModalOpened: value,
    })
  }

  handleMenuType = type => {
    const item = this.menuTypes[type] || Object.values(this.menuTypes)[0]

    this.setState({
      menuType: new SelectItemDTO(item.title, item.value),
    })
  }

  handleInputChange = value => {
    this.setState({
      title: value,
    })
  }

  handleContentChange = value => {
    this.setState({
      url: value,
    })
  }

  validateModal = () => {
    this.setState({
      titleErrors: [],
      urlErrors: [],
    })

    const title = !!this.state.title.trim().length
    const url = !!this.state.url.trim().length

    this.setState({
      titleErrors: [!title && validationMessages.titleIsRequired].filter(string => !!string),
      urlErrors: [!url && validationMessages.urlIsInvalid].filter(string => !!string),
    })

    return title && url
  }

  createItem = async () => {
    const isValid = this.validateModal()

    if (!isValid) {
      return
    }

    this.setState({
      isLoading: true,
    })

    const instance = new AdminMenuItemVO(
      this.state.title,
      this.state.url,
      this.items.length + 1,
      this.state.menuType.value
    )

    try {
      await httpInstance.endpoints.menu.create(instance, this.edition)

      notificationsEntity.addNotification(
        new NotificationDTO(
          null,
          messages.success,
          notifications.createdSuccessfully(this.state.title),
          NotificationDTO.types.success,
          NotificationDTO.defaultDelay
        )
      )
    } catch (e) {
      notificationsEntity.addNotification(
        new NotificationDTO(
          null,
          messages.error,
          notifications.somethingWentWrong,
          NotificationDTO.types.error,
          NotificationDTO.defaultDelay
        )
      )
    }

    this.props.MenuItemsAddItems(this.edition)

    this.handleModalStateChange(false)

    this.setState({
      isLoading: false,
      title: '',
      url: '',
      menuType: new SelectItemDTO(
        AdminMenuItemVO.mappedTypes[AdminMenuItemVO.types.iframe],
        AdminMenuItemVO.types.iframe
      ),
    })
  }

  handleEditOpen = id => {
    const item = this.items.find(item => item.id === id)

    if (!item) {
      console.log('Invalid id: ' + id)

      return
    }

    this.setState(
      {
        isModalSingleEdit: true,
        title: item.title,
        url: item.url,
        menuType: new SelectItemDTO(AdminMenuItemVO.mappedTypes[item.type], item.type),
        modalOrder: item.order,
        modalId: id,
      },
      () => {
        this.handleModalStateChange(true)
      }
    )
  }

  handleUpdateClose = () => {
    this.handleModalStateChange(false)

    this.setState({
      titleErrors: [],
      urlErrors: [],
      isModalSingleEdit: false,
      title: '',
      url: '',
      menuType: new SelectItemDTO(
        AdminMenuItemVO.mappedTypes[AdminMenuItemVO.types.iframe],
        AdminMenuItemVO.types.iframe
      ),
      modalOrder: null,
      modalId: null,
    })
  }

  /**
   *
   * @return {Promise<void>}
   */
  updateItem = async () => {
    const isValid = this.validateModal()

    if (!isValid) {
      return
    }

    this.setState({
      isLoading: true,
    })

    try {
      await httpInstance.endpoints.menu.update(
        new AdminMenuItemVO(
          this.state.title,
          this.state.url,
          this.state.modalOrder,
          this.state.menuType.value,
          [],
          this.state.modalId
        ),
        this.edition,
        this.isSU
      )

      notificationsEntity.addNotification(
        new NotificationDTO(
          null,
          messages.success,
          notifications.updatedSuccessfully(this.state.title),
          NotificationDTO.types.success,
          NotificationDTO.defaultDelay
        )
      )
    } catch (e) {
      notificationsEntity.addNotification(
        new NotificationDTO(
          null,
          messages.error,
          notifications.somethingWentWrong,
          NotificationDTO.types.error,
          NotificationDTO.defaultDelay
        )
      )
    }

    this.props.MenuItemsAddItems(this.edition)

    this.handleUpdateClose()

    this.setState({
      isLoading: false,
    })
  }

  deleteItem = async () => {
    const item = this.items.find(item => item.id === this.state.modalId)

    if (!item) {
      console.log('Invalid id: ' + this.state.modalId)

      return
    }

    const isConfirm = window.confirm(`Do you want to delete "${item.title}" item?`)

    if (!isConfirm) {
      return
    }

    try {
      await httpInstance.endpoints.menu.delete(this.state.modalId)

      notificationsEntity.addNotification(
        new NotificationDTO(
          null,
          messages.success,
          notifications.deletedSuccessfully(item.title),
          NotificationDTO.types.success,
          NotificationDTO.defaultDelay
        )
      )
    } catch (e) {
      notificationsEntity.addNotification(
        new NotificationDTO(
          null,
          messages.error,
          notifications.somethingWentWrong,
          NotificationDTO.types.error,
          NotificationDTO.defaultDelay
        )
      )
    }

    this.props.MenuItemsAddItems(this.edition)

    this.handleUpdateClose()
  }

  /**
   *
   * @param {boolean} value
   */
  handleEdit = value => {
    if (this.state.isEditing === value) {
      return
    }

    if (value) {
      this.setState({
        isEditing: value,
      })

      return
    }

    this.setState({
      isEditing: value,
    })
  }

  handleOrderChange = (id, value) => {
    this.setState(prevState => ({
      order: {
        ...prevState.order,
        [id]: value,
      },
    }))
  }

  handleCancelEdit = () => {
    this.setState({
      order: {},
    })

    this.handleEdit(false)
  }

  handleSaveEdit = () => {
    const items = this.items.map((item, index) => {
      return new AdminMenuItemVO().fromInstance(item).setOrder(index + 1)
    })

    this.props.menuItemsAddItems(items, this.edition)

    this.handleEdit(false)

    this.setState({
      order: {},
    })

    Promise.all(items.map(item => httpInstance.endpoints.menu.update(item, this.edition)))
  }

  async componentDidMount() {
    this.props.MenuItemsAddItems(this.edition)

    this.handleMenuType()
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.key !== prevProps.location.key) {
      this.props.MenuItemsAddItems(this.edition)

      this.handleMenuType()
    }
  }

  render() {
    return (
      <Layout
        title={adminEdit.menuEdit}
        menuType={Layout.menuTypes.admin}
        yearChange={ADMIN_MENU}
        activeYear={this.props.match.params.year}
      >
        <Paper>
          <div className="admin-menu-controls">
            <h3 className="admin-menu-controls__title">{adminEdit.mainNavigation}</h3>
            <button className="admin-menu-controls__button" onClick={() => this.handleEdit(true)}>
              <DotsIcon className="admin-menu-controls__button-dot" />
            </button>
          </div>

          <ul className="admin-table">
            <li className="admin-table__row admin-menu-table__row admin-table__row--is-heading">
              <MenuCell>{adminEdit.title}</MenuCell>
              <MenuCell align="end">{adminEdit.order}</MenuCell>
              <MenuCell align="end" hasRightOffset>
                {adminEdit.type}
              </MenuCell>
              <MenuCell />
              <MenuCell />
            </li>
            {this.items.map(item => {
              const isOpened = this.state.openedId === item.id
              const hasChildren = !!item.children.length
              const canBeEdited =
                (AdminMenuItemVO.editableTypes.hasOwnProperty(item.type) || this.isSU) && !this.state.isEditing

              return (
                <React.Fragment key={item.id}>
                  <li
                    className={ClassNamesService.execute([
                      'admin-table__row admin-menu-table__row',
                      hasChildren && 'admin-table__row--can-open',
                    ])}
                    onClick={() => this.handleRowOpen(item.id)}
                  >
                    <MenuCell>{item.title}</MenuCell>
                    <MenuCell align="end">
                      {this.state.isEditing ? (
                        <Input
                          handleChange={value => this.handleOrderChange(item.id, +value)}
                          value={`${item.order}`}
                          className="admin-input--is-order"
                        />
                      ) : (
                        item.order
                      )}
                    </MenuCell>
                    <MenuCell align="end">
                      <MenuBadge type={item.type}>{AdminMenuItemVO.mappedTypes[item.type]}</MenuBadge>
                    </MenuCell>
                    <MenuCell align="center">
                      {canBeEdited && (
                        <button className="admin-table__row-pen-button" onClick={() => this.handleEditOpen(item.id)}>
                          <EditIcon className="admin-table__row-pen" />
                        </button>
                      )}
                    </MenuCell>
                    <MenuCell align="center">
                      {hasChildren && (
                        <ChevronDownIcon
                          className={ClassNamesService.execute([
                            'admin-table__row-arrow',
                            isOpened && 'admin-table__row-arrow--is-active',
                          ])}
                        />
                      )}
                    </MenuCell>
                  </li>

                  {isOpened && (
                    <ul className="admin-table admin-table--is-inner">
                      {item.children.map((child, index) => {
                        return (
                          <li
                            className="admin-table__row admin-table__row--is-inner admin-menu-table__row admin-menu-table__row--is-inner"
                            key={child.id}
                          >
                            <MenuCell isInnerTitle>{child.title}</MenuCell>
                            <MenuCell isInner align="end">{`${item.order}.${index + 1}`}</MenuCell>
                            <MenuCell align="end">
                              <MenuBadge type={child.type}>{AdminMenuItemVO.mappedTypes[child.type]}</MenuBadge>
                            </MenuCell>
                            <MenuCell />
                          </li>
                        )
                      })}
                      {false && (
                        <li className="admin-table__row admin-table__row--is-button">
                          <button className="admin-table__button">Add sub menu</button>
                        </li>
                      )}
                    </ul>
                  )}
                </React.Fragment>
              )
            })}

            {this.state.isEditing && (
              <li className="admin-table__row admin-table__row--is-button admin-table__row--is-edit-buttons">
                <SubmitButton className="admin-button--is-text" handleSubmit={() => this.handleCancelEdit()}>
                  {actions.cancel}
                </SubmitButton>
                <SubmitButton handleSubmit={() => this.handleSaveEdit()}>{actions.save}</SubmitButton>
              </li>
            )}

            {!this.state.isEditing && (
              <li className="admin-table__row admin-table__row--is-button admin-table__row--is-button-outer">
                <SubmitButton handleSubmit={() => this.handleModalStateChange(true)}>
                  {actions.addMenuItem}
                </SubmitButton>
              </li>
            )}
          </ul>
        </Paper>

        <ModalComponent
          handleClose={this.handleUpdateClose}
          isOpened={this.state.isModalOpened}
          className="modal-component--is-menu-edit"
          title={this.state.isModalSingleEdit ? 'Rediger menyelement' : 'Legg til menyelement'}
        >
          <>
            <Input
              heading="Menytittel"
              placeholder="Inngangsmenytittel"
              handleChange={this.handleInputChange}
              value={this.state.title}
              errors={this.state.titleErrors}
            />
            <Select
              items={Object.values(this.menuTypes)}
              selectedItem={this.state.menuType}
              heading="Type"
              handleChange={this.handleMenuType}
              hasFirstSelected
            />
            <Input
              heading="Url"
              placeholder="Input Url"
              handleChange={this.handleContentChange}
              value={this.state.url}
              errors={this.state.urlErrors}
            />
            <div className="admin-modal__row">
              {this.state.isModalSingleEdit && (
                <button className="admin-modal__delete-button" onClick={this.deleteItem}>
                  <DeleteIcon className="admin-modal__delete-icon" />
                </button>
              )}
              <SubmitButton
                className="admin-button--is-modal-submit"
                handleSubmit={this.state.isModalSingleEdit ? this.updateItem : this.createItem}
                isLoading={this.state.isLoading}
              >
                {actions.save}
              </SubmitButton>
            </div>
          </>
        </ModalComponent>
      </Layout>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MenuEdit)
)
