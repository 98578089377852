import ActionDTO from '../../DTO/ActionDTO'
import { REQUEST_PENDING_FINISH, REQUEST_PENDING_START } from '../ActionTypes'

export const requestTypes = {
  FAVORITE: 'FAVORITE',
  LOGIN: 'LOGIN',
}

export const requestPendingStart = name => new ActionDTO(REQUEST_PENDING_START, { name }).valueOf
export const requestPendingFinish = name => new ActionDTO(REQUEST_PENDING_FINISH, { name }).valueOf
