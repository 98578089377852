import PropTypes from 'prop-types'
import React, { Component } from 'react'

import TableComponent from '../SamlagsTable'
import DefaultTable from '../../components/SamlagsFylkevise/DefaultTable'
import Lagvis100Table from '../../components/InitialLagvisList/Lagvis100Table'
import Lagvis200Table from '../../components/InitialLagvisList/Lagvis200Table'

class ShootersTable extends Component {
  static propTypes = {
    organizationId: PropTypes.string,
    type: PropTypes.string,
    data: PropTypes.array,
  }

  componentsTree = {
    default: DefaultTable,
    'Lagvis 100m': {
      default: Lagvis100Table,
    },
    'Lagvis 200m': {
      default: Lagvis200Table,
    },
  }

  render() {
    const { organizationId, type, data } = this.props

    return (
      <TableComponent
        organizationId={organizationId}
        organizationGroup1Id={type}
        data={data}
        componentsTree={this.componentsTree}
      />
    )
  }
}

export default ShootersTable
