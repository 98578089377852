import React from 'react'
import PropTypes from 'prop-types'
import ClassNamesService from '../../../services/ClassNamesService'

const ScoreCell = ({ children, hasBorder = false, width = null, className = null }) => (
  <div
    className={ClassNamesService.execute(['cell cell--is-score', className, hasBorder && 'cell--has-right-border'])}
    style={{
      width,
    }}
  >
    <span className="score-block">{children}</span>
  </div>
)

ScoreCell.propTypes = {
  hasBorder: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, () => null]),
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
}

export default ScoreCell
