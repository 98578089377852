import PropTypes from 'prop-types'
import React from 'react'

import messages from '../constants/messages'
import '../styles/results-list.scss'
import ClassNamesService from '../services/ClassNamesService'

const ResultsList = ({
  isVisible,
  variantsList,
  onSelect = () => {},
  handleVariantHighlight = () => false,
  className = null,
}) =>
  isVisible && (
    <div className={ClassNamesService.execute(['results-list__wrapper', className])}>
      <ul className="results-list">
        {variantsList.length ? (
          variantsList.map(variant => (
            <li
              className={ClassNamesService.execute([
                'results-list__variant',
                handleVariantHighlight(variant) && 'results-list__variant--is-selected',
              ])}
              key={variant.id}
              onClick={() => onSelect(variant)}
            >
              {variant.text} {variant.label && <span className="results-list__label">{variant.label}</span>}
            </li>
          ))
        ) : (
          <li className="results-list__variant">{messages.nothingFound}</li>
        )}
      </ul>
    </div>
  )

ResultsList.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
  variantsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      label: PropTypes.oneOfType([PropTypes.string, () => null]),
    })
  ).isRequired,
  onSelect: PropTypes.func,
  handleVariantHighlight: PropTypes.func,
}

export default ResultsList
