class FavoriteVO {
  instance

  constructor(instance) {
    this.instance = instance
  }

  /**
   *
   * @param shooter_id
   * @return {*}
   */
  create = shooter_id => {
    return this.instance.post('/me/favourites', {
      shooter_id,
    })
  }

  list = async () => {
    const { data } = await this.instance.get('/me/favourites')

    const shooters = {}

    data.forEach(shooter => (shooters[shooter.shooter_id] = shooter.shooter_id))

    return shooters
  }

  delete = shooter_id => {
    return this.instance.delete('/me/favourites', {
      data: {
        shooter_id,
      },
    })
  }
}

export default FavoriteVO
