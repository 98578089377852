import gql from 'graphql-tag'

export const SEARCH_SHOOTER = gql`
  query SearchShooter($name: String!) {
    searchShooter(name: $name, limit: 100) {
      organizationId
      name
      clubOrganizationId
      clubName
      districtOrganizationId
      districtName
      countyOrganizationId
      countyName
      categories
      defaultClassName
      defaultClassOrganizationId
    }
  }
`

export const GET_SHOOTER = gql`
  query GetShooter($organizationId: String!) {
    getShooter(organizationId: $organizationId) {
      name
      organizationId
      clubName
      districtName
      categories
      clubOrganizationId
      defaultClassName
      defaultClassOrganizationId
      events(eventSubType: ORDINARY) {
        name
        organizationId
        className
        eventType
        eventSubType
        checkinDateTime
        relayNumber
        targetNumber
        svgScoringCard
        series {
          name
          organizationId
          seriesType
          sum
          sumInner
          visualId
          shots {
            valueInt
            valueDec
            inner
            subTargetNo
            visualId
            x
            y
          }
        }
      }
      distinctions {
        name
        organizationId
        organizationEventId
        organizationEventType
        type
        subType
      }
    }
  }
`

export const SHOOTER_MODAL_QUERY = gql`
  query {
    isShooterModalOpen @client
    shooterModalId @client
  }
`

export const RANGED_FIELD_QUERY = gql`
  query {
    rangedField @client {
      type
      category
      option
      organizationId
    }
  }
`

export const CLUB_SHOOTER_EVENTS_QUERY = gql`
  query GetShooterByClub($clubOrganizationId: String!) {
    getShooterByClub(
      clubOrganizationId: $clubOrganizationId
      includeShootersWithoutResults: true
      limit: 100
      fromDate: "2017-01-01"
      toDate: "2030-12-31"
    ) {
      name
      clubName
      categories
      countyName
      defaultClassName
      defaultClassOrganizationId
      categories
      events {
        name
        eventType
        eventSubType
        className
        checkinDateTime
        shootingDateTime
        resultDateTime
        relayNumber
        targetNumber
        series {
          name
          seriesType
          organizationId
          sum
          sumInner
          visualId
          shots {
            valueInt
            valueDec
          }
        }
      }
    }
  }
`

export const REPORTS_START_LIST_QUERY = gql`
  query GetReportList($organizationGroup1Id: String!, $organizationGroup2Id: String!) {
    getReportList(organizationGroup1Id: $organizationGroup1Id, organizationGroup2Id: $organizationGroup2Id) {
      name
      nameShort
      organizationId
      organizationGroup1Id
      organizationGroup2Id
    }
  }
`

export const REPORTS_LIST_QUERY = gql`
  query GetReportList($organizationGroup1Id: String!) {
    getReportList(organizationGroup1Id: $organizationGroup1Id) {
      name
      nameShort
      webEnabled
      sortOrder
      organizationId
      organizationGroup1Id
      organizationGroup2Id
      multipleShooters
    }
  }
`

export const SINGLE_REPORT_QUERY = gql`
  query GetReport($organizationId: String!, $limit: Int!, $offset: Int!) {
    getReport(organizationId: $organizationId) {
      name
      nameShort
      organizationId
      organizationGroup1Id
      organizationGroup2Id
      multipleShooters
      ranks(limit: $limit, offset: $offset) {
        rank
        name
        note
        name
        sum
        sumInner
        shooters {
          name
          rank
          organizationId
          clubName
          className
          classOrganizationId
          categories
          sum
          sumInner
          clubOrganizationId
          series {
            name
            organizationId
            seriesType
            sum
            sumInner
            visualId
          }
          distinctions {
            name
            type
            subType
          }
        }
      }
    }
  }
`

export const RELAY_GROUP_QUERY = gql`
  query GetRelayGroup($organizationId: String!) {
    getRelayGroup(organizationId: $organizationId) {
      name
      organizationId
      relays {
        name
        checkinDateTime
        relayType
        number
      }
    }
  }
`

export const LAGVIS_LIST_QUERY = gql`
  query {
    lagvisList @client {
      type
      category
      option
      timeString
      number
      organizationId
    }
  }
`

export const SINGLE_RELAY_QUERY = gql`
  query GetRelay($relayGroupOrganizationId: String!, $number: Int!) {
    getRelay(relayGroupOrganizationId: $relayGroupOrganizationId, number: $number) {
      number
      relayType
      name
      checkinDateTime
      shootingDateTime
      targets {
        number
        rank
        shooterName
        clubName
        className
        classOrganizationId
        categories
        shooterOrganizationId
        series {
          name
          organizationId
          seriesType
          sum
          sumInner
          shots {
            valueInt
            inner
          }
        }
      }
    }
  }
`

export const SINGLE_ACTIVE_RELAY_QUERY = gql`
  query GetActiveRelay($relayGroupOrganizationId: String!) {
    getActiveRelay(relayGroupOrganizationId: $relayGroupOrganizationId) {
      number
      relayType
      name
      checkinDateTime
      shootingDateTime
      targets {
        number
        rank
        shooterName
        clubName
        className
        classOrganizationId
        categories
        shooterOrganizationId
        series {
          name
          organizationId
          seriesType
          sum
          sumInner
          shots {
            valueInt
            inner
          }
        }
      }
    }
  }
`

export const SHOOTER_PLACES_QUERY = gql`
  query searchDistrict {
    searchDistrict(name: "") {
      name
      organizationId
    }
    searchCounty(name: "") {
      name
      organizationId
    }
  }
`

export const DATES_AND_CLASSES_COUNTY = gql`
  query GetTotalByCounty($organizationId: String!) {
    getTotalByCounty(countyOrganizationId: $organizationId) {
      classes {
        name
        organizationId
      }
      dates {
        date
      }
    }
  }
`

export const DATES_AND_CLASSES_DISTRICT = gql`
  query GetTotalByDistrict($organizationId: String!) {
    getTotalByDistrict(districtOrganizationId: $organizationId) {
      classes {
        name
        organizationId
      }
      dates {
        date
      }
    }
  }
`

export const SAMLAGS_FYLKE_QUERY = gql`
  query {
    samlagsFylke @client {
      organizationId
      fromDate
      toDate
      classesSelected {
        id
        text
      }
      label
    }
  }
`

export const SAMLAGS_FYLKE_SHOOTERS_QUERY = gql`
  query {
    samlagsFylkeShooters @client {
      list
    }
  }
`

export const SHOOTERS_BY_COUNTY_QUERY = gql`
  query GetShooterByCounty($organizationId: String!, $fromDate: Date!, $toDate: Date!) {
    getShooterByCounty(
      countyOrganizationId: $organizationId
      fromDate: $fromDate
      toDate: $toDate
      limit: 100
      offset: 0
    ) {
      name
      clubName
      countyName
      countyOrganizationId
      defaultClassOrganizationId
      categories
      districtName
      defaultClassName
      organizationId
      events {
        name
        eventType
        classOrganizationId
        className
        resultDateTime
        series {
          name
          organizationId
          seriesType
          sum
          sumInner
        }
      }
    }
  }
`

export const SHOOTERS_BY_DISTRICT_QUERY = gql`
  query GetShooterByDistrict($organizationId: String!, $fromDate: Date!, $toDate: Date!) {
    getShooterByDistrict(
      districtOrganizationId: $organizationId
      fromDate: $fromDate
      toDate: $toDate
      limit: 100
      offset: 0
    ) {
      name
      clubName
      countyName
      countyOrganizationId
      defaultClassOrganizationId
      categories
      districtName
      defaultClassName
      organizationId
      events {
        name
        eventType
        classOrganizationId
        className
        resultDateTime
        series {
          name
          organizationId
          seriesType
          sum
          sumInner
        }
      }
    }
  }
`
