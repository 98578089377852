import PropTypes from 'prop-types'
import React from 'react'
import AdminMenuItemVO from '../../../VO/AdminMenuItemVO'
import ClassNamesService from '../../../services/ClassNamesService'

const MenuBadge = ({ className, type, children }) => (
  <div
    className={ClassNamesService.execute([
      'admin-table__row-badge',
      type === AdminMenuItemVO.types.iframe && 'admin-table__row-badge--is-blue',
      type === AdminMenuItemVO.types.xml && 'admin-table__row-badge--is-green',
    ])}
  >
    {children}
  </div>
)

MenuBadge.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([AdminMenuItemVO.types.internal, AdminMenuItemVO.types.iframe, AdminMenuItemVO.types.xml]),
}

MenuBadge.defaultProps = {
  className: '',
  type: AdminMenuItemVO.types.internal,
}

export default MenuBadge
