import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import '../styles/ranged-tables.scss'
import messages from '../constants/messages'
import { RANGED_FIELD_QUERY } from '../constants/queries'

import Sidebar from '../containers/StartList/Sidebar'
import ResponseMiddleware from '../Middleware/ResponseMiddleware'
import Heading from '../views/TypeHeading'
import ShootersTable from '../containers/StartList/ShootersTable'
import SidebarHOC from '../components/SidebarHOC'
import Message from '../views/Message'

class RangedBane extends Component {
  componentDidMount() {
    this.props.onShowSearch(false)
    this.props.onShowNav(false)
  }

  render() {
    const response = new ResponseMiddleware()
    response.setComponent((data) => (
      <div
        className="content-wrapper"
        style={{
          paddingTop: data.rangedField.category === '' && '30px',
        }}
      >
        {data.rangedField.category !== '' && (
          <Heading className="ranged-field" {...data.rangedField} type="" category="" />
        )}
        {data.rangedField.category !== '' ? (
          <ShootersTable {...data.rangedField} />
        ) : (
          <Message text={messages.refineSearch} />
        )}
      </div>
    ))

    return (
      <div className="full-page-wrapper full-page-wrapper--is-fluid full-page-wrapper--is-full-width shooters-table">
        <SidebarHOC>
          <Sidebar />
        </SidebarHOC>

        <Query query={RANGED_FIELD_QUERY}>{response.execute}</Query>
      </div>
    )
  }
}

export default withRouter(RangedBane)
