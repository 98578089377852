import React, { Component } from 'react'
import propTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { SEARCH_PATH } from '../constants/routePaths'
import { latinExtdRegEx } from '../constants/regex'
import { ReactComponent as SearchGlass } from '../images/magnifying-glass.svg'
import { ReactComponent as CrossIcon } from '../images/cross-icon.svg'

import '../styles/search-box.scss'
import { CSSTransition } from 'react-transition-group'

class HeaderSearch extends Component {
  state = {
    searchString: queryString.parse(this.props.location.search)['s'] || '',
  }

  componentDidMount() {
    this.setState({
      searchString: queryString.parse(this.props.location.search)['s'] || '',
    })
  }

  handleChange = e => {
    e.preventDefault()
    const string = e.target.value

    const regexedString = string.match(latinExtdRegEx)
    const trimmedString = regexedString && regexedString[0] ? regexedString[0].replace(/( )+/g, ' ') : ''
    this.setState({
      searchString: trimmedString || '',
    })
  }

  handleSubmit = e => {
    const { searchString } = this.state
    const { history } = this.props
    const locationSearch = queryString.parse(this.props.location.search) || ''

    e.preventDefault()

    if (!searchString || searchString.length < 2) {
      console.log('Search is too short')
      return 'Search is too short'
    }

    if (locationSearch.s !== searchString) {
      history.push({ pathname: SEARCH_PATH, search: `?s=${searchString}` })
    } else {
      return false
    }
  }

  render() {
    // console.log(this.props);
    const { searchString } = this.state
    const { onShowSearch, appState } = this.props
    const locationSearch = queryString.parse(this.props.location.search) || ''
    return (
      <>
        <button
          className="header-btn header-btn__search"
          onClick={() => this.setState(onShowSearch(!appState.isSearchShown))}
        >
          <span>Søk</span>
          {appState.isSearchShown ? (
            <CrossIcon className="animFadeIn" style={{ fill: '#BFC5D2' }} />
          ) : (
            <SearchGlass className="animFadeIn" />
          )}
        </button>

        <CSSTransition
          in={appState.isSearchShown}
          timeout={500}
          classNames="site-search"
          unmountOnExit
          onEnter={() => onShowSearch(true)}
          onExited={() => onShowSearch(false)}
        >
          <div className="site-search">
            <div className="search-box">
              <div className="search-box__left">
                <h4 className="search-box__heading">Hva ser du etter?</h4>
                <p className="search-box__subheading">Søk på navn eller skytterlag</p>
              </div>

              <div className="search-box__center wrapper">
                <form onSubmit={this.handleSubmit} className="search-box__form" role="search">
                  <input
                    type="text"
                    aria-label="search text"
                    className="input search-box__input"
                    placeholder={locationSearch['s'] || 'Søk'}
                    name="s"
                    value={searchString}
                    onChange={this.handleChange}
                    autoFocus
                  />
                  <div className="search-box__focus-bg" />
                  <button className="btn search-box__button" type="submit">
                    Søk
                  </button>
                </form>
              </div>
            </div>
          </div>
        </CSSTransition>
      </>
    )
  }
}

export default withRouter(HeaderSearch)

HeaderSearch.propTypes = {
  history: propTypes.any,
  location: propTypes.any,
  match: propTypes.any,
  onShowSearch: propTypes.func.isRequired,
  appState: propTypes.shape({
    isSearchShown: propTypes.bool,
  }),
}

HeaderSearch.defaultProps = {
  onShowSearch: () => alert('HeaderSearch clicked'),
  appState: {
    isSearchShown: false,
  },
}
