import tryService from './tryService'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const getNameInitials = fullName => {
  const splitted = fullName.trim().split(' ')
  const initials = splitted[0][0] + splitted[splitted.length - 1][0]
  return initials
}

export const findResults = (events, name, eventType, eventSubType) => {
  const result = events.find(
    event => event.name === name && event.eventType === eventType && event.eventSubType === eventSubType
  )
  return result
}

export const findSum = (results, serieName) => {
  const sum = tryService(() => results.series.find(serie => serie.name === serieName).sum)
  return sum
}

export const getEventDate = eventCheckTime =>
  dayjs(eventCheckTime)
    .utc()
    .format('DD.MM')
export const getEventTime = eventCheckTime =>
  dayjs(eventCheckTime)
    .utc()
    .format('HH:mm')

export const formatScoreHeads = shooter => shooter.shooters[0].series.map(item => item.name)

export const formatShootsHeads = shooter => shooter.series.map(item => item.name)

export const formatShootsHeadsId = shooter =>
  shooter.series.map(item => ({
    name: item.name,
    organizationId: item.organizationId,
    eventName: shooter.hasOwnProperty('eventName') ? shooter.eventName.replace(/:/g, '_') : '',
  }))

export const resolveDelimiter = (valueTop, valueBottom) =>
  [valueTop, valueBottom].filter(value => value !== '' && value).join('/')

export const getShotsCode = shots => shots.reduce((acc, shot) => acc + shot.valueInt, '')

/**
 *
 * @typedef {object} baneSeries
 * @property {string} organizationId
 *
 */

/** @typedef {object} baneShooters
 * @property {string} clubName
 * @property {baneSeries[]} series
 *
 */

/** @param {string} headName
 * @param {object} shooter
 * @param {baneShooters[]} shooter.shooters
 */
export const resolveBaneColumnName = (headName, shooter) => {
  return (
    shooter.shooters[0].clubName.trim() +
    '_' +
    shooter.shooters[0].series[0].organizationId +
    '_' +
    headName
  ).replace(/[ /()]/gi, '-')
}

export default {
  getNameInitials,
  findResults,
  findSum,
  getEventDate,
  getEventTime,
  formatScoreHeads,
  resolveDelimiter,
}
