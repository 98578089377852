import PropTypes from 'prop-types'
import React, { Component } from 'react'

import TableComponent from '../StartListTable'
import DefaultTable from '../../components/StartList/DefaultTable'

class ShootersTable extends Component {
  static propTypes = {
    organizationId: PropTypes.string,
  }

  componentsTree = {
    default: DefaultTable,
  }

  render() {
    const { organizationId } = this.props

    return <TableComponent organizationId={organizationId} componentsTree={this.componentsTree} />
  }
}

export default ShootersTable
