import tryService from './tryService'
import { findResults } from './shooterService'
import {
  KLASS,
  NAME,
  BANE_LAG,
  BANE_SK,
  BANE_DATE,
  BANE_TIME,
  BANE_A,
  BANE_K,
  BANE_L,
  BANE_N,
  BANE_MB,
  BANE_MF,
  BANE_FT,
  FELT_LAG,
  FELT_SK,
  FELT_DATE,
  FELT_TIME,
} from '../constants/shootersTable'
import { getEventDate, getEventTime } from '../services/shooterService'

const getKlass = shooter => shooter.defaultClassOrganizationId
const getName = shooter => shooter.name
const getBaneLag = shooter => tryService(() => findResults(shooter.events, 'Bane', 'MAIN', 'ORDINARY').relayNumber)
const getBaneSk = shooter => tryService(() => findResults(shooter.events, 'Bane', 'MAIN', 'ORDINARY').targetNumber)
const getBaneDate = (shooter, handler = Date.parse) =>
  tryService(() => {
    const baneDateTimestamp = findResults(shooter.events, 'Bane', 'MAIN', 'ORDINARY').checkinDateTime
    const [date] = baneDateTimestamp.split('T')

    return date ? handler(`${date}T00:00:00.000Z`) : null
  })
const getBaneTime = (shooter, handler = Date.parse) =>
  tryService(() => {
    const baneDateTimestamp = findResults(shooter.events, 'Bane', 'MAIN', 'ORDINARY').checkinDateTime
    const [, time] = baneDateTimestamp.split('T')

    return time ? handler(`2019-07-01T${time}`) : null
  })
const getBaneA = shooter => (shooter.categories.includes('U') ? 'U' : '')
const getBaneK = shooter => (shooter.categories.includes('K') ? 'K' : '')
const getBaneL = shooter => (shooter.categories.includes('L') ? 'L' : '')
const getBaneN = shooter => (shooter.categories.includes('N') ? 'N' : '')
const getBaneMB = shooter => (shooter.categories.includes('MB') ? 'MB' : '')
const getBaneMF = shooter => (shooter.categories.includes('MF') ? 'MF' : '')
const getBaneFT = shooter => (shooter.categories.includes('FT') ? 'FT' : '')
const getFeltLag = shooter => tryService(() => findResults(shooter.events, 'Felt', 'MAIN', 'ORDINARY').relayNumber)
const getFeltSk = shooter => tryService(() => findResults(shooter.events, 'Felt', 'MAIN', 'ORDINARY').targetNumber)
const getFeltDate = (shooter, handler = Date.parse) =>
  tryService(() => {
    const feltDateTimestamp = findResults(shooter.events, 'Felt', 'MAIN', 'ORDINARY').checkinDateTime
    const [date] = feltDateTimestamp.split('T')

    return date ? handler(`${date}T00:00:00.000Z`) : null
  })
const getFeltTime = (shooter, handler = Date.parse) =>
  tryService(() => {
    const feltDateTimestamp = findResults(shooter.events, 'Felt', 'MAIN', 'ORDINARY').checkinDateTime
    const [, time] = feltDateTimestamp.split('T')

    return time ? handler(`2019-07-01T${time}`) : null
  })

const sortAsc = (shooters, getShooterValue) =>
  [...shooters].sort((a, b) => (getShooterValue(a) < getShooterValue(b) ? -1 : 1))
const sortDesc = (shooters, getShooterValue) =>
  [...shooters].sort((a, b) => (getShooterValue(a) > getShooterValue(b) ? -1 : 1))

const handleCase = (shooters, order, getProp) => {
  if (order === 'desc') return sortDesc(shooters, getProp)
  return sortAsc(shooters, getProp)
}

export default (shooters, { sort_by, order }) => {
  //   const newShooters = [...shooters];
  switch (sort_by) {
    case KLASS:
      return handleCase(shooters, order, getKlass)

    case NAME:
      return handleCase(shooters, order, getName)

    case BANE_LAG:
      return handleCase(shooters, order, getBaneLag)

    case BANE_SK:
      return handleCase(shooters, order, getBaneSk)

    case BANE_DATE:
      return handleCase(shooters, order, getBaneDate)

    case BANE_TIME:
      return handleCase(shooters, order, getBaneTime)

    case BANE_A:
      return handleCase(shooters, order, getBaneA)

    case BANE_K:
      return handleCase(shooters, order, getBaneK)

    case BANE_L:
      return handleCase(shooters, order, getBaneL)

    case BANE_N:
      return handleCase(shooters, order, getBaneN)

    case BANE_MB:
      return handleCase(shooters, order, getBaneMB)

    case BANE_MF:
      return handleCase(shooters, order, getBaneMF)

    case BANE_FT:
      return handleCase(shooters, order, getBaneFT)

    case FELT_LAG:
      return handleCase(shooters, order, getFeltLag)

    case FELT_SK:
      return handleCase(shooters, order, getFeltSk)

    case FELT_DATE:
      return handleCase(shooters, order, getFeltDate)

    case FELT_TIME:
      return handleCase(shooters, order, getFeltTime)

    default:
      return shooters
  }
}

export const getMethods = [
  getKlass,
  getName,
  getBaneLag,
  getBaneSk,
  shooter => getBaneDate(shooter, getEventDate),
  shooter => getBaneTime(shooter, getEventTime),
  getBaneA,
  getBaneK,
  getBaneL,
  getBaneN,
  getBaneMB,
  getBaneMF,
  getBaneFT,
  getFeltLag,
  getFeltSk,
  shooter => getFeltDate(shooter, getEventDate),
  shooter => getFeltTime(shooter, getEventTime),
]
