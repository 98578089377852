import PropTypes from 'prop-types'
import React from 'react'

import '../styles/type-heading.scss'
import ClassNamesService from '../services/ClassNamesService'

const TypeHeadingTime = ({ type, category, option, time, preTitle, className = '' }) => (
  <div
    className={ClassNamesService.execute([
      'type-heading type-heading--is-time',
      className !== '' && `type-heading--is-${className}`,
    ])}
  >
    <div className="type-heading__wrapper">
      <h1 className="type-heading__main-info">
        {type}
        {category !== '' && ` - ${category}`}
      </h1>
      <span className="type-heading__secondary-info">{option}</span>
    </div>
    <div className="type-heading__wrapper type-heading__wrapper--is-right">
      <h4 className="type-heading__pre-title">{preTitle}</h4>
      <span className="type-heading__time">{time}</span>
    </div>
  </div>
)

TypeHeadingTime.propTypes = {
  type: PropTypes.string.isRequired,
  category: PropTypes.string,
  option: PropTypes.string.isRequired,
  className: PropTypes.string,
  preTitle: PropTypes.string,
  time: PropTypes.string,
}

TypeHeadingTime.defaultProps = {
  className: '',
  category: '',
  preTitle: '',
  time: '',
}

export default TypeHeadingTime
