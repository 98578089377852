import PropTypes from 'prop-types'
import React from 'react'
import WithBootstrap from '../../../HOC/WithBootstrap'
import ClassNamesService from '../../../services/ClassNamesService'

const SubmitButton = ({ children, isDisabled, className, handleSubmit, isLoading }) => {
  const notAvailable = isDisabled || isLoading

  return (
    <button
      className={ClassNamesService.execute(['admin-button', notAvailable && 'admin-button--is-disabled', className])}
      onClick={notAvailable ? () => {} : handleSubmit}
    >
      {isLoading ? (
        <WithBootstrap isSmall>
          <div className="spinner-grow text-light spinner-grow-sm" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </WithBootstrap>
      ) : (
        children
      )}
    </button>
  )
}

SubmitButton.propTypes = {
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
}

SubmitButton.defaultProps = {
  isDisabled: false,
  className: '',
  isLoading: false,
}

export default SubmitButton
