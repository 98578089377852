import {
  FINAL_LAGVIS_LIST_PATH,
  INITIAL_LAGVIS_LIST_PATH,
  RANGED_BANE_PATH,
  RANGED_FIELD_PATH,
  SAMLAGS_FYLKESVISE_LIST_PATH,
} from './routePaths'

export default [
  { label: 'Forside', to: '/' },
  { label: 'Søk', to: '/search' },
  { label: 'Rangert Felt', to: RANGED_FIELD_PATH },
  { label: 'Rangert Bane', to: RANGED_BANE_PATH },
  { label: 'Lagvis lister inledende', to: INITIAL_LAGVIS_LIST_PATH },
  { label: 'Oppropslister finale', to: FINAL_LAGVIS_LIST_PATH },
  {
    label: 'Samlags og fylkesvise resultater',
    to: SAMLAGS_FYLKESVISE_LIST_PATH,
  },
  // { label: 'Vetarans', to: '/veterans' },
  // { label: 'Livevisning', to: '/live' },
  // { label: 'Samlag / lagskyting', to: '/samlag' }
]

const baseURL = window.location.hostname === 'localhost' ? 'https://lsres-stage.innocode.digital' : ''

export const ktsLinksInitial = shooterId => [
  {
    label: 'Bane',
    to: [`${baseURL}/shooter/${shooterId}/graphics/BA/IN`],
  },
  {
    label: 'Felt',
    to: [`${baseURL}/shooter/${shooterId}/graphics/FE/IN`],
  },
  {
    label: 'Minne',
    to: [`${baseURL}/shooter/${shooterId}/graphics/MI/IN`],
  },
  {
    label: 'Stang',
    to: [`${baseURL}/shooter/${shooterId}/graphics/ST/IN`],
  },
  {
    label: 'Felthurtig',
    to: [`${baseURL}/shooter/${shooterId}/graphics/FH/IN`],
  },
]
export const ktsLinksFinale = shooterId => [
  {
    label: 'Bane',
    to: [`${baseURL}/shooter/${shooterId}/graphics/BA/FI`],
  },
  {
    label: 'Felt',
    to: [`${baseURL}/shooter/${shooterId}/graphics/FE/FI`],
  },
  {
    label: 'Stang',
    to: [`${baseURL}/shooter/${shooterId}/graphics/ST/FI`],
  },
  {
    label: 'Felthurtig',
    to: [`${baseURL}/shooter/${shooterId}/graphics/FH/FI`],
  },
]
export const ktsLinksTeam = shooterId => [
  {
    label: 'Samlagsskyting',
    to: [`${baseURL}/shooter/${shooterId}/graphics/SM/IN`],
  },
  {
    label: 'Lagskyting',
    to: [`${baseURL}/shooter/${shooterId}/graphics/LA/IN`],
  },
]
