import AdminMenuItemVO from '../AdminMenuItemVO'

class MenuVO {
  instance

  constructor(instance) {
    this.instance = instance
  }

  /**
   *
   * @param edition
   * @param type
   * @return {Promise<*>}
   */
  items = async (edition, type) => {
    const query = {
      edition,
      type: type || null,
    }

    const response = await this.instance.get('/menu/items', {
      params: query,
    })

    return response.data
  }

  /**
   *
   * @param {AdminMenuItemVO} data
   * @param edition
   * @return {Promise<void>}
   */
  create = async (data, edition) => {
    const response = await this.instance.post('/menu/items', {
      name: data.title,
      url: data.url,
      edition: edition,
      order: data.order || 0,
      type: data.type,
    })

    return response.data
  }

  /**
   *
   * @param {AdminMenuItemVO} data
   * @param edition
   * @param asSU
   * @return {Promise<*>}
   */
  update = async (data, edition, asSU = false) => {
    const query =
      data.type === AdminMenuItemVO.types.internal && !asSU
        ? {
            edition: edition,
            order: data.order || 0,
          }
        : {
            name: data.title,
            url: data.url,
            edition: edition,
            order: data.order || 0,
            type: data.type,
          }

    const response = await this.instance.put(`/menu/items/${data.id}`, query)

    return response.data
  }

  /**
   *
   * @param id
   * @return {Promise<*>}
   */
  delete = async id => {
    const response = await this.instance.delete(`/menu/items/${id}`)

    return response.data
  }
}

export default MenuVO
