import React, { Component } from 'react'

import ResizableCellProp from './ResizableCellProp'
import ResizableCellWidth from './ResizableCellWidth'

class ResizableCell extends Component {
  render() {
    return (
      <>
        {window.areCustomPropertiesSupported ? (
          <ResizableCellProp {...this.props} />
        ) : (
          <ResizableCellWidth {...this.props} />
        )}
      </>
    )
  }
}

export default ResizableCell
