class UserDTO {
  constructor(id, name, email, emailVerifiedAt, role) {
    this.id = id
    this.name = name
    this.email = email
    this.emailVerifiedAt = emailVerifiedAt
    this.role = role

    return this
  }
}

export default UserDTO
