import PropTypes from 'prop-types'
import React, { Component } from 'react'

import TableComponent from '../LagvisListTable'
import DefaultTable from '../../components/FinalLagvisList/DefaultTable'
import Finaler200Table from '../../components/FinalLagvisList/Finaler200Table'
import FinalerGrovfeltTable from '../../components/FinalLagvisList/FinalerGrovfeltTable'

class ShootersTable extends Component {
  static propTypes = {
    organizationId: PropTypes.string,
  }

  componentsTree = {
    default: DefaultTable,
    'Finaler 200m': {
      default: Finaler200Table,
    },
    'Finaler Grovfelt': {
      default: FinalerGrovfeltTable,
    },
  }

  render() {
    const { organizationId, number, type } = this.props

    return (
      <TableComponent
        organizationId={organizationId}
        organizationGroup1Id={type}
        number={number}
        componentsTree={this.componentsTree}
      />
    )
  }
}

export default ShootersTable
