import React from 'react'
import PropTypes from 'prop-types'

import '../styles/char-block.scss'

const CharBlock = ({ children, className = '' }) => <span className={`char-block ${className}`}>{children}</span>

CharBlock.propTypes = {
  className: PropTypes.string,
}

export default CharBlock
