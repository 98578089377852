import React from 'react'
import ReactModal from 'react-modal'
import ModalClose from './modalClose'
import Loader from './loader'

import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import ApolloClient from 'apollo-client'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import '../styles/shooting-card.scss'

const ShootingCardModal = ({ showModal, handleCloseModal, shootingInfo }) => {
  const httpLink = createHttpLink({
    uri: 'https://api.kongsbergtargets.com/target-scoring-generator/graphql',
  })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${localStorage.getItem('loginToken')}`,
      },
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  const eventYear = shootingInfo?.event ? new Date(shootingInfo.event.checkinDateTime).getFullYear() : null

  const series = shootingInfo?.event?.series

  const availableSeries = series?.filter((item) => !!item.shots) || []

  const isBane = shootingInfo?.event.name === 'Bane'
  const isFelt = shootingInfo?.event.name === 'Felt'
  const isMinne = shootingInfo?.event.name === 'Minne'
  const isStang = shootingInfo?.event.name === 'Stang'
  const isFelthurtig = shootingInfo?.event.name === 'Felthurtig'
  const isSamlagsskyting = shootingInfo?.event.name === 'Samlagsskyting'
  const isLagskyting = shootingInfo?.event.name === 'Lagskyting'

  const felthurtigSeries = []

  if (isFelthurtig) {
    const felthurtigSeriesName = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']

    availableSeries.forEach((item) => {
      const newSeries = {}
      item.shots.forEach((shot, index) => {
        if (!newSeries[shot.subTargetNo]) {
          newSeries[shot.subTargetNo] = {
            name: `Felthurtig Hold ${felthurtigSeriesName[shot.subTargetNo - 1]}`,
            shots: [shot],
            visualId: shot.visualId,
          }
        } else {
          newSeries[shot.subTargetNo].shots.push(shot)
        }
      })

      felthurtigSeries.push(...Object.values(newSeries))
    })
  }

  return (
    <>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={{
          overlay: {
            position: 'fixed',
            zIndex: 40,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
          },
          content: {
            position: 'absolute',
            top: '40px',
            left: '50%',
            right: 'auto',
            transform: 'translateX(-50%)',
            bottom: '40px',
            minWidth: '290px',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '40px 30px 20px 30px',
          },
        }}
      >
        <ModalClose onClick={handleCloseModal} />
        <div className={`shooting-card ${!isBane ? 'wide' : ''}`}>
          <table className="header_table">
            <tbody>
              {isBane || isSamlagsskyting || isLagskyting ? (
                <tr>
                  <td rowSpan={3}>
                    Lag <p>{shootingInfo?.event?.relayNumber}</p>
                  </td>
                  <td rowSpan={3}>
                    Skive <p>{shootingInfo?.event?.targetNumber}</p>
                  </td>
                  <td>{eventYear ? `LS${eventYear}` : ''}</td>
                  <td></td>
                </tr>
              ) : null}
              <tr>
                {!isBane && !isSamlagsskyting && !isLagskyting ? (
                  <td rowSpan={3}>{eventYear ? <p>LS{eventYear}</p> : ''}</td>
                ) : null}
                <td>{shootingInfo?.shooter?.name}</td>
                <td>{shootingInfo?.event?.className}</td>
              </tr>
              <tr>
                <td>{shootingInfo?.shooter?.clubName}</td>
                <td>{shootingInfo?.shooter?.organizationId}</td>
              </tr>
            </tbody>
          </table>
          {isBane ? <BaneSeries availableSeries={availableSeries} client={client} series={series} /> : null}
          {isFelt ? <FeltSeries availableSeries={availableSeries} client={client} series={series} /> : null}
          {isMinne ? <MinneSeries availableSeries={availableSeries} client={client} series={series} /> : null}
          {isStang ? <StangSeries availableSeries={availableSeries} client={client} series={series} /> : null}
          {isFelthurtig ? (
            <FelthurtigSeries availableSeries={felthurtigSeries} client={client} series={series} />
          ) : null}
          {isSamlagsskyting ? (
            <SamlagsskytingSeries availableSeries={availableSeries} client={client} series={series} />
          ) : null}
          {isLagskyting ? <LagskytingSeries availableSeries={availableSeries} client={client} series={series} /> : null}
        </div>
      </ReactModal>
    </>
  )
}

export default ShootingCardModal

const BaneSeries = ({ availableSeries, client, series }) => {
  const countTotalsBane = (length) => {
    const allSeries = series.filter((item) => !!item.shots).slice(0, length)

    return allSeries.reduce((acc, current) => {
      return acc + Number(current.sum)
    }, 0)
  }

  const innertiere = series?.length ? series[series.length - 1]?.sumInner : null

  return (
    <div>
      {availableSeries &&
        availableSeries.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item.shots.length ? (
                <div key={index} className="bane-serie">
                  <Query
                    client={client}
                    query={gql`
                        query {
                          getSvgLS(
                            targetSeries: { visualId: ${item.visualId}, shots: [ ${item?.shots?.map(
                      (shot) => `{
                              x: ${shot.x},
                              y: ${shot.y},
                            }`
                    )} ]
                            }
                          ) {
                            svg
                            svgMetaData {
                              name
                              visualNo
                              height
                              minZoom
                              maxZoom
                            }
                          }
                        }
                      `}
                  >
                    {({ data, loading }) => {
                      if (loading) {
                        return (
                          <div className="bane-serie_image_loading">
                            <Loader />
                          </div>
                        )
                      }
                      const image = data?.getSvgLS?.svg

                      return (
                        <div className="bane-serie_image">
                          {image && <img src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`} alt="svg " />}
                        </div>
                      )
                    }}
                  </Query>
                  <div className="bane-serie_details">
                    <p className="serie-title">{item.name}</p>

                    <div style={{ columns: Math.ceil(item.shots?.length / 5) }}>
                      {item.shots.map((shot, index) => {
                        return (
                          <div className="bane-shot" key={index}>
                            <span>{index + 1}: </span>
                            <span>
                              {shot.valueInt}.{shot.valueDec || 0}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                    <div className="serie-sum">
                      <span>SUM: {item?.sum}</span>
                      <span>TOT: {countTotalsBane(index + 1)}</span>
                      {index === availableSeries.length - 1 && innertiere && (
                        <div>{<span>INNERTIERE: {innertiere}</span>}</div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          )
        })}
    </div>
  )
}

const FeltSeries = ({ availableSeries, client, series }) => {
  const countTotalsFelt = (length) => {
    const allSeries = series.filter((item) => !!item.shots).slice(0, length)

    const total = allSeries.reduce((acc, current) => {
      return acc + Number(current.sum.split('/')[0])
    }, 0)
    const actual = allSeries.reduce((acc, current) => {
      return acc + Number(current.sumInner)
    }, 0)

    return `${total}/${actual}`
  }
  return (
    <div className="felt-series-wrap">
      {availableSeries &&
        availableSeries.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item.shots.length ? (
                <div className="felt-serie">
                  <p className="felt-serie_title">Felt {item.name}</p>
                  <div className="felt-serie_wrap">
                    <Query
                      client={client}
                      query={gql`
                        query {
                          getSvgLS(
                            targetSeries: { visualId: ${item.visualId}, shots: [ ${item.shots.map(
                        (shot) => `{
                              x: ${shot.x},
                              y: ${shot.y},
                            }`
                      )} ]
                            }
                          ) {
                            svg
                            svgMetaData {
                              name
                              visualNo
                              height
                              minZoom
                              maxZoom
                            }
                          }
                        }
                      `}
                    >
                      {({ data, loading }) => {
                        if (loading) {
                          return (
                            <div className="felt-serie_image_loading">
                              <Loader />
                            </div>
                          )
                        }
                        const image = data?.getSvgLS?.svg
                        const imageDescription = data?.getSvgLS?.svgMetaData.name

                        return (
                          <div className="felt-serie_image">
                            {image && <img src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`} alt="svg " />}
                            <div className="image-description">
                              <p>{imageDescription}</p>
                            </div>
                          </div>
                        )
                      }}
                    </Query>
                    <div className="felt-serie_details">
                      <div style={{ columns: Math.ceil(item.shots?.length / 6) }}>
                        {item.shots.map((shot, index) => {
                          return (
                            <div className="felt-shot" key={index}>
                              <span>{index + 1}: </span>
                              <span>{shot.valueInt}</span>
                            </div>
                          )
                        })}
                      </div>
                      <div className="serie-sum">
                        <p>SUM: {item?.sum}</p>
                        <p>TOTAL: {countTotalsFelt(index + 1)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          )
        })}
    </div>
  )
}

const MinneSeries = ({ availableSeries, client, series }) => {
  const countTotalsMinne = (length) => {
    const allSeries = series.filter((item) => !!item.shots).slice(0, length)

    return allSeries.reduce((acc, current) => {
      return acc + Number(current.sum)
    }, 0)
  }

  return (
    <div>
      {availableSeries &&
        availableSeries.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item.shots.length ? (
                <div key={index} className="unified-serie">
                  <p className="unified-serie_title">{item.name} Hold 1</p>
                  <div className="unified-serie_wrap">
                    <Query
                      client={client}
                      query={gql`
                        query {
                          getSvgLS(
                            targetSeries: { visualId: ${item.visualId}, shots: [ ${item.shots.map(
                        (shot) => `{
                              x: ${shot.x},
                              y: ${shot.y},
                            }`
                      )} ]
                            }
                          ) {
                            svg
                            svgMetaData {
                              name
                              visualNo
                              height
                              minZoom
                              maxZoom
                            }
                          }
                        }
                      `}
                    >
                      {({ data, loading }) => {
                        if (loading) {
                          return (
                            <div className="unified-serie_image_loading">
                              <Loader />
                            </div>
                          )
                        }
                        const image = data?.getSvgLS?.svg
                        const imageDescription = data?.getSvgLS?.svgMetaData.name

                        return (
                          <div className="unified-serie_image">
                            {image && <img src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`} alt="svg " />}
                            <div className="image-description">
                              <p>{imageDescription}</p>
                            </div>
                          </div>
                        )
                      }}
                    </Query>
                    <div className="unified-serie_details">
                      <div>
                        {item.shots.map((shot, index) => {
                          return (
                            <div className="unified-shot" key={index}>
                              <span>{index + 1}: </span>
                              <span>{shot.valueInt}</span>
                            </div>
                          )
                        })}
                      </div>
                      <div className="serie-sum">
                        <p>SUM: {item?.sum}</p>
                        <p>TOTAL: {countTotalsMinne(index + 1)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          )
        })}
    </div>
  )
}

const StangSeries = ({ availableSeries, client, series }) => {
  const countTotalsStang = (length) => {
    const allSeries = series.filter((item) => !!item.shots).slice(0, length)

    return allSeries.reduce((acc, current) => {
      return acc + Number(current.sum)
    }, 0)
  }
  return (
    <div>
      {availableSeries &&
        availableSeries.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item.shots.length ? (
                <div key={index} className="unified-serie">
                  <p className="unified-serie_title">Stang {item.name}</p>
                  <div className="unified-serie_wrap">
                    <Query
                      client={client}
                      query={gql`
                        query {
                          getSvgLS(
                            targetSeries: { visualId: ${item.visualId}, shots: [ ${item.shots.map(
                        (shot) => `{
                              x: ${shot.x},
                              y: ${shot.y},
                            }`
                      )} ]
                            }
                          ) {
                            svg
                            svgMetaData {
                              name
                              visualNo
                              height
                              minZoom
                              maxZoom
                            }
                          }
                        }
                      `}
                    >
                      {({ data, loading }) => {
                        if (loading) {
                          return (
                            <div className="unified-serie_image_loading">
                              <Loader />
                            </div>
                          )
                        }
                        const image = data?.getSvgLS?.svg
                        const imageDescription = data?.getSvgLS?.svgMetaData.name

                        return (
                          <div className="unified-serie_image">
                            {image && <img src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`} alt="svg " />}
                            <div className="image-description">
                              <p>{imageDescription}</p>
                            </div>
                          </div>
                        )
                      }}
                    </Query>
                    <div className="unified-serie_details">
                      <div style={{ columns: Math.ceil(item.shots?.length / 6) }}>
                        {item.shots.map((shot, index) => {
                          return (
                            <div className="unified-shot" key={index}>
                              <span>{index + 1}: </span>
                              <span>{shot.valueInt}</span>
                            </div>
                          )
                        })}
                      </div>
                      <div className="serie-sum">
                        <p>SUM: {item?.sum}</p>
                        <p>TOTAL: {countTotalsStang(index + 1)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          )
        })}
    </div>
  )
}

const FelthurtigSeries = ({ availableSeries, client, series }) => {
  const sum = series[series.length - 1]?.sum
  return (
    <div>
      {availableSeries &&
        availableSeries.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item.shots.length ? (
                <div key={index} className="unified-serie">
                  <p className="unified-serie_title">{item.name}</p>
                  <div className="unified-serie_wrap">
                    <Query
                      client={client}
                      query={gql`
                        query {
                          getSvgLS(
                            targetSeries: { visualId: ${item.visualId}, shots: [ ${item.shots.map(
                        (shot) => `{
                              x: ${shot.x},
                              y: ${shot.y},
                            }`
                      )} ]
                            }
                          ) {
                            svg
                            svgMetaData {
                              name
                              visualNo
                              height
                              minZoom
                              maxZoom
                            }
                          }
                        }
                      `}
                    >
                      {({ data, loading }) => {
                        if (loading) {
                          return (
                            <div className="unified-serie_image_loading">
                              <Loader />
                            </div>
                          )
                        }
                        const image = data?.getSvgLS?.svg
                        const imageDescription = data?.getSvgLS?.svgMetaData.name

                        return (
                          <div className="unified-serie_image">
                            {image && <img src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`} alt="svg " />}
                            <div className="image-description">
                              <p>{imageDescription}</p>
                            </div>
                          </div>
                        )
                      }}
                    </Query>
                    <div className="unified-serie_details">
                      <div>
                        {item.shots.map((shot, index) => {
                          return (
                            <div className="unified-shot" key={index}>
                              <span>{index + 1}: </span>
                              <span>{shot.valueInt}</span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  {index === availableSeries.length - 1 && (
                    <div className="serie-sum-total">{<p>TOTALTID: {sum}</p>}</div>
                  )}
                </div>
              ) : null}
            </React.Fragment>
          )
        })}
    </div>
  )
}

const SamlagsskytingSeries = ({ availableSeries, client, series }) => {
  return (
    <div>
      {availableSeries &&
        availableSeries.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item.shots.length ? (
                <div key={index} className="bane-serie">
                  <Query
                    client={client}
                    query={gql`
                        query {
                          getSvgLS(
                            targetSeries: { visualId: ${item.visualId}, shots: [ ${item?.shots?.map(
                      (shot) => `{
                              x: ${shot.x},
                              y: ${shot.y},
                            }`
                    )} ]
                            }
                          ) {
                            svg
                            svgMetaData {
                              name
                              visualNo
                              height
                              minZoom
                              maxZoom
                            }
                          }
                        }
                      `}
                  >
                    {({ data, loading }) => {
                      if (loading) {
                        return (
                          <div className="bane-serie_image_loading">
                            <Loader />
                          </div>
                        )
                      }
                      const image = data?.getSvgLS?.svg

                      return (
                        <div className="bane-serie_image">
                          {image && <img src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`} alt="svg " />}
                        </div>
                      )
                    }}
                  </Query>
                  <div className="bane-serie_details">
                    <p className="serie-title">
                      {item.name}{' '}
                      <span className="serie-sum">
                        <span>INN: {item.sum}</span>
                      </span>
                    </p>

                    <div style={{ columns: Math.ceil(item.shots?.length / 5) }}>
                      {item.shots.map((shot, index) => {
                        return (
                          <div className="bane-shot" key={index}>
                            <span>{index + 1}: </span>
                            <span>
                              {shot.valueInt}.{shot.valueDec || 0}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                    <div className="serie-sum">
                      <span>SUM: {item?.sumInner}</span>
                      <span>TOT: {Number(item?.sum) + Number(item?.sumInner)}</span>
                    </div>
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          )
        })}
    </div>
  )
}

const LagskytingSeries = ({ availableSeries, client, series }) => {
  return (
    <div>
      {availableSeries &&
        availableSeries.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item.shots.length ? (
                <div key={index} className="bane-serie">
                  <Query
                    client={client}
                    query={gql`
                        query {
                          getSvgLS(
                            targetSeries: { visualId: ${item.visualId}, shots: [ ${item?.shots?.map(
                      (shot) => `{
                              x: ${shot.x},
                              y: ${shot.y},
                            }`
                    )} ]
                            }
                          ) {
                            svg
                            svgMetaData {
                              name
                              visualNo
                              height
                              minZoom
                              maxZoom
                            }
                          }
                        }
                      `}
                  >
                    {({ data, loading }) => {
                      if (loading) {
                        return (
                          <div className="bane-serie_image_loading">
                            <Loader />
                          </div>
                        )
                      }
                      const image = data?.getSvgLS?.svg

                      return (
                        <div className="bane-serie_image">
                          {image && <img src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`} alt="svg " />}
                        </div>
                      )
                    }}
                  </Query>
                  <div className="bane-serie_details">
                    <p className="serie-title">
                      {item.name}{' '}
                      <span className="serie-sum">
                        <span>INN: {item.sum}</span>
                      </span>
                    </p>

                    <div style={{ columns: Math.ceil(item.shots?.length / 5) }}>
                      {item.shots.map((shot, index) => {
                        return (
                          <div className="bane-shot" key={index}>
                            <span>{index + 1}: </span>
                            <span>
                              {shot.valueInt}.{shot.valueDec || 0}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                    <div className="serie-sum">
                      <span>SUM: {item?.sumInner}</span>
                      <span>TOT: {Number(item?.sum) + Number(item?.sumInner)}</span>
                    </div>
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          )
        })}
    </div>
  )
}
