import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import './styles/index.scss'
import axios from 'axios'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
// import * as serviceWorker from './serviceWorker';

// import gql from 'graphql-tag';
import ApolloClient from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { ApolloProvider } from 'react-apollo'
import store from './redux/store'
import Store from './Store'
import { resolvers } from './resolvers'

import { applicationYear } from './constants/allYears'
import { apiUrl } from './constants/apiVariables'
import {
  clearToken,
  devModeCredentials,
  isDevModeActive,
  saveDevCredentials,
  setNewLoginToken,
} from './services/loginService'

let storageLoginToken = localStorage.getItem('loginToken')
let storageLoginExpireDate = localStorage.getItem('loginExpireDate')

export const devModeToken = async withLS => {
  const lsCredentials = () => {
    return devModeCredentials()
  }
  const promptCredentials = () => {
    const username = window.prompt('Enter username')
    const password = window.prompt('Enter password')

    saveDevCredentials(username, password)

    return btoa(`${username}:${password}`)
  }

  const credentials = withLS ? lsCredentials() : promptCredentials()

  try {
    const urlInstance = new URL(localStorage.getItem('url_base') || window.location.origin)
    urlInstance.pathname = '/dev/login'

    const { data } = await axios.post(
      urlInstance.href,
      {},
      {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    )

    return data.data.login
  } catch (e) {
    alert(e)
    clearToken()

    return null
  }
}

const regularModeToken = async _ => {
  const urlInstance = new URL(localStorage.getItem('url_base') || window.location.origin)
  urlInstance.pathname = '/login'

  try {
    const { data } = await axios.post(urlInstance.href)

    return data.data.login
  } catch (e) {
    clearToken()

    return null
  }
}

export const renderAppInApolloClient = token => {
  const httpLink = createHttpLink({
    uri: apiUrl(),
  })

  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    resolvers,
    cache: new InMemoryCache(),
  })

  client.cache.writeData({
    data: Store,
  })

  const ApolloApp = App => (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <App />
      </Provider>
    </ApolloProvider>
  )

  if (document.getElementById('root')) {
    ReactDOM.render(ApolloApp(App), document.getElementById('root'))
  }
}
;(async () => {
  if (storageLoginToken && storageLoginExpireDate > Date.now()) {
    renderAppInApolloClient(storageLoginToken)

    return
  }

  const newLoginYear = applicationYear
  const method = isDevModeActive() ? devModeToken : regularModeToken

  const newLoginToken = await method(true)

  if (!newLoginToken || newLoginToken === 'null') {
    return
  }

  setNewLoginToken(newLoginToken, newLoginYear)
  renderAppInApolloClient(newLoginToken)
})()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
