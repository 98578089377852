class SelectedLink {
  _category
  _link

  setLinkAsParameters(category, link) {
    this._category = category
    this._link = link
  }

  setLinkAsString(linkString) {
    if (!linkString) {
      return
    }

    ;[this._category, this._link] = linkString.split(':')
  }

  get category() {
    return this._category
  }

  get link() {
    return this._link
  }

  get linkString() {
    return `${this.category}:${this.link}`
  }
}

export default SelectedLink
