import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import dayjs from 'dayjs'

import { FULL_DAYS } from '../../constants/Dates'
import messages from '../../constants/messages'
import SelectedDate from '../../entities/SelectedDate'

import SelectedLink from '../../entities/SelectedLink'
import Category from '../../views/SidebarFilter/Category'

import CollapsibleCategory from '../../views/SidebarFilter/CollapsibleCategory'
import DropDownHOC from '../DropDownHOC'
import SelectBox from '../SidebarSelectBox'

const stringKeys = {
  selectedDate: 'selectedDate',
}

class SidebarFilter extends Component {
  static defaultProps = {
    classList: [],
    dates: [],
    onDateSelect: () => {},
    onClassSelect: () => {},
  }

  static propTypes = {
    classList: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        organizationId: PropTypes.string,
      })
    ),
    dates: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
      })
    ),
    onDateSelect: PropTypes.func,
    onClassSelect: PropTypes.func,
  }

  categories = {
    CLASS: 'CLASS',
    DATE: 'DATE',
  }

  state = {
    openedCategories: [this.categories.DATE, this.categories.CLASS],
    datesList: [],
    [stringKeys.selectedDate]: '',
  }

  get resolvedClassList() {
    return this.props.classList.map(className => ({
      id: className.organizationId,
      text: className.name,
    }))
  }

  get _datesList() {
    return this.props.dates.map(({ date }) => dayjs(date).unix()).sort()
  }

  get resolvedDates() {
    const datesFormat = 'YYYY-MM-DD'
    const datesList = this._datesList
    const allDatesDate = new SelectedDate()
    allDatesDate.setDateAsParameters(
      dayjs.unix(datesList[0]).format(datesFormat),
      dayjs.unix(datesList.slice(-1)[0]).format(datesFormat)
    )

    const allDates = {
      label: messages.allDates,
      slug: allDatesDate.dateString,
    }

    return [
      allDates,
      ...datesList.map(unix => {
        const date = dayjs.unix(unix)
        const slugFormatted = date.format(datesFormat)
        const selectedDateInstance = new SelectedDate()
        selectedDateInstance.setDateAsParameters(slugFormatted, slugFormatted)

        return {
          label: `${FULL_DAYS[date.get('day')]} ${date.format('DD.MM')}`,
          slug: selectedDateInstance.dateString,
        }
      }),
    ]
  }

  componentDidMount() {
    const search = { ...queryString.parse(this.props.location.search) }

    if (!search.hasOwnProperty(stringKeys.selectedDate)) {
      return
    }

    this.setState({
      [stringKeys.selectedDate]: search[stringKeys.selectedDate],
    })

    const linkEntity = new SelectedLink()
    linkEntity.setLinkAsString(search[stringKeys.selectedDate])

    this.props.onDateSelect({ slug: linkEntity.link })
  }

  selectDate = (categoryKey, link) => {
    const linkEntity = new SelectedLink()
    linkEntity.setLinkAsParameters(categoryKey, link)

    this.setState({
      [stringKeys.selectedDate]: linkEntity.linkString,
    })

    const satisfiedDate = this.resolvedDates.find(date => date.slug === link)

    if (!satisfiedDate) {
      return
    }

    this.props.onDateSelect(satisfiedDate)

    const { pathname, search } = this.props.location
    const previousSearch = queryString.parse(search)

    this.props.history.push({
      pathname,
      search: queryString.stringify({
        ...previousSearch,
        [stringKeys.selectedDate]: linkEntity.linkString,
      }),
    })
  }

  toggleCategory = category => {
    if (this.state.openedCategories.includes(category)) {
      this.setState({
        openedCategories: this.state.openedCategories.filter(categoryName => categoryName !== category),
      })

      return
    }

    this.setState({
      openedCategories: [...this.state.openedCategories, category],
    })
  }

  resolveIsCategoryOpened = category => {
    return this.state.openedCategories.includes(category)
  }

  render() {
    return (
      <>
        <Category
          label="Dato"
          selectedLink={this.state[stringKeys.selectedDate]}
          slug={this.categories.DATE}
          selectLink={this.selectDate}
          isOpened={this.resolveIsCategoryOpened(this.categories.DATE)}
          toggleCategory={this.toggleCategory}
          children={this.resolvedDates}
          className="filter__item--is-capitalized"
        />
        <CollapsibleCategory
          label="Klasse"
          slug={this.categories.CLASS}
          isOpened={this.resolveIsCategoryOpened(this.categories.CLASS)}
          toggleCategory={this.toggleCategory}
        >
          <DropDownHOC>
            <SelectBox variants={this.resolvedClassList} onSelect={this.props.onClassSelect} />
          </DropDownHOC>
        </CollapsibleCategory>
      </>
    )
  }
}

export default withRouter(SidebarFilter)
