import React from 'react'
import Img from 'react-image'

import sniperIcon from '../images/sniper.svg'
import { SHOOTER_PATH } from '../constants/routePaths'
import ImageService from '../services/ImageService'

const ShooterImg = ({ shooterId }) => {
  return <Img src={[ImageService.execute(`${SHOOTER_PATH}${shooterId}/avatar`), `${sniperIcon}`]} alt="Shooter image" />
}

export default ShooterImg
