import PropTypes from 'prop-types'
import React from 'react'
import ClassNamesService from '../../../services/ClassNamesService'

const ShooterHead = ({ children, className = null }) => (
  <div className={ClassNamesService.execute(['head-cell head-cell--is-shooter', className])}>{children}</div>
)

ShooterHead.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
}

export default ShooterHead
