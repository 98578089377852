import PropTypes from 'prop-types'
import React from 'react'
import ClassNamesService from '../../../services/ClassNamesService'

const Paper = ({ className, children }) => (
  <div className={ClassNamesService.execute(['layout__paper', className])}>{children}</div>
)

Paper.propTypes = {
  className: PropTypes.string,
}

Paper.defaultProps = {
  className: '',
}

export default Paper
