class SubMenuCategory {
  /**
   * @private
   */
  _name
  /**
   *
   * @type {null}
   * @private
   */
  _displayName = null
  _children = {}

  constructor(name, displayName = null) {
    this._name = name
    this._displayName = displayName
  }

  get id() {
    return this._name
  }

  get name() {
    return this._displayName
  }

  get children() {
    return this._children
  }

  get value() {
    return {
      label: this.name,
      slug: this.id,
    }
  }

  /**
   *
   * @param {SubMenuChild} category
   */
  addCategory = category => {
    if (this._children.hasOwnProperty(category.id)) {
      return
    }

    this._children[category.id] = category
    // this._children[category.id] = category.value
  }

  /**
   *
   * @param {SubMenuChild[]} categories
   */
  addCategories = categories => {
    categories.forEach(this.addCategory)
  }
}

export default SubMenuCategory
