import { combineReducers } from 'redux'
import User from './User'
import Favorites from './Favorites'
import RequestPending from './RequestPending'
import MenuItems from './MenuItems'
import Reports from './Reports'
import Notifications from './Notifications'
import PageSettings from './PageSettings'

export default combineReducers({
  User,
  Favorites,
  RequestPending,
  MenuItems,
  Reports,
  Notifications,
  PageSettings,
})
