class PageSettingDTO {
  page
  key
  value
  edition

  constructor(page, edition, key, value) {
    this.page = page
    this.edition = edition
    this.key = key
    this.value = value
  }

  /**
   *
   * @param {{}} store
   * @return {undefined|*}
   */
  getValue = store => {
    if (!store.hasOwnProperty(this.page) || !store[this.page].hasOwnProperty(this.edition)) {
      return undefined
    }

    return store[this.page][this.edition][this.key]
  }
}

export default PageSettingDTO
