import '../styles/frontpage.scss'
import PropTypes from 'prop-types'

import HtmlReactParser from 'html-react-parser'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { applicationYear } from '../constants/allYears'
import PageSettingDTO from '../DTO/Storage/PageSettingDTO'
import { PageSettingsAddItems } from '../redux/actions/PageSettings'
// import DFSLogo from '../images/dfs_logo.png';

const mapStateToProps = state => ({
  PageSettings: state.PageSettings,
})

const mapDispatchToProps = dispatch => ({
  PageSettingsAddItems: data => dispatch(PageSettingsAddItems(data)),
})

class FrontPage extends Component {
  static propTypes = {
    PageSettingsAddItems: PropTypes.func,
    PageSettings: PropTypes.object,
  }

  get text() {
    const defaultText = ``
    const storeText = new PageSettingDTO('fp', applicationYear, 'introText')

    return (storeText.getValue(this.props.PageSettings) || defaultText).replaceAll('\n', '<br />')
  }

  async componentDidMount() {
    this.props.onShowSearch(true)
    this.props.onShowNav(false)

    try {
      await this.props.PageSettingsAddItems(new PageSettingDTO('fp', applicationYear))
      this.forceUpdate()
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    return (
      <div className="animFadeIn">
        <div className="wrapper front-page">
          <h1>
            Velkommen
            <br />
            <br />
          </h1>
          <div style={{ fontSize: '22px' }}>{HtmlReactParser(this.text)}</div>
          {/* <img src={DFSLogo} alt="DFS Logo" /> */}
        </div>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FrontPage)
