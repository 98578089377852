import React from 'react'
import { withRouter } from 'react-router-dom'
import { Query, Mutation } from 'react-apollo'
import { SHOOTER_MODAL_QUERY, GET_SHOOTER } from '../constants/queries'
import { CLOSE_SHOOTER_MODAL_MUTATION } from '../constants/mutations'

import { ContentShooterInfo, ModalClose } from '../components'
import ResponseMiddleware from '../Middleware/ResponseMiddleware'

const ShooterModal = props => {
  return (
    <Query query={SHOOTER_MODAL_QUERY}>
      {({ data: { isShooterModalOpen, shooterModalId } }) =>
        isShooterModalOpen && (
          <Mutation mutation={CLOSE_SHOOTER_MODAL_MUTATION}>
            {closeShooterModal => {
              const response = new ResponseMiddleware()
              response.setComponent(data => {
                const shooterInfo = data.getShooter[0]

                return (
                  !!shooterInfo && (
                    <div
                      className="modal modal--is-shooter animFadeIn"
                      aria-modal="true"
                      onClick={e => e.stopPropagation()}
                    >
                      <ModalClose onClick={closeShooterModal} />
                      <ContentShooterInfo
                        shooterInfo={shooterInfo}
                        history={props.history}
                        closeShooterModal={closeShooterModal}
                      />
                    </div>
                  )
                )
              })

              return (
                <div onClick={closeShooterModal} className="modal-wrapper animFadeIn">
                  <Query query={GET_SHOOTER} variables={{ organizationId: shooterModalId }} fetchPolicy="no-cache">
                    {response.execute}
                  </Query>
                </div>
              )
            }}
          </Mutation>
        )
      }
    </Query>
  )
}

export default withRouter(ShooterModal)
