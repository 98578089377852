class EditionsTextsVO {
  instance

  constructor(instance) {
    this.instance = instance
  }

  /**
   *
   * @param {PageSettingDTO} data
   * @return {Promise<void>}
   */
  get = async data => {
    const response = await this.instance.get(`/editions/${data.edition}`)

    return response.data
  }

  /**
   *
   * @param edition
   * @return {Promise<*>}
   */
  items = async edition => {
    const query = {
      edition,
    }

    const response = await this.instance.get('/editions', {
      params: query,
    })

    return response.data
  }

  /**
   *
   * @param {PageSettingDTO} data
   * @return {Promise<void>}
   */
  create = async data => {
    const response = await this.instance.post('/editions', {
      description: encodeURIComponent(data.value),
      edition: data.edition,
    })

    return response.data
  }

  /**
   *
   * @param {PageSettingDTO} data
   * @return {Promise<*>}
   */
  update = async data => {
    const query = {
      description: encodeURIComponent(data.value),
    }

    const response = await this.instance.put(`/editions/${data.edition}`, query)

    return response.data
  }

  /**
   *
   * @param id
   * @return {Promise<*>}
   */
  delete = async id => {
    const response = await this.instance.delete(`/editions/${id}`)

    return response.data
  }
}

export default EditionsTextsVO
