import React from 'react'
import PropTypes from 'prop-types'

import ClassNamesService from '../../../services/ClassNamesService'
import CharBlock from '../../CharBlock'

const LettersCellGenerator = ({
  initialLetters = [],
  activeLetters = [],
  hasBorder = false,
  className = null,
  filterLetters = false,
}) => {
  const lettersHash = {}

  initialLetters.forEach(initialLetter => {
    lettersHash[initialLetter] = {
      letter: initialLetter,
      isVisible: false,
    }
  })

  activeLetters.forEach(activeLetter => {
    if (!lettersHash.hasOwnProperty(activeLetter)) {
      return
    }

    lettersHash[activeLetter] = {
      ...lettersHash[activeLetter],
      isVisible: true,
    }
  })

  const resultingLetters = filterLetters
    ? Object.values(lettersHash).filter(({ isVisible }) => isVisible)
    : Object.values(lettersHash)

  return (
    <div
      className={ClassNamesService.execute(['cell cell--is-letters', className, hasBorder && 'cell--has-right-border'])}
    >
      {resultingLetters.map(({ letter, isVisible }, idx) => (
        <CharBlock key={idx} className={ClassNamesService.execute([isVisible && 'char-block--is-visible'])}>
          {letter}
        </CharBlock>
      ))}
    </div>
  )
}

LettersCellGenerator.propTypes = {
  hasBorder: PropTypes.bool,
  initialLetters: PropTypes.arrayOf(PropTypes.string),
  activeLetters: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
}

export default LettersCellGenerator
