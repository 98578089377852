class ReportsList {
  /**
   *
   * @type {{}}
   * @private
   */
  _codes = {}
  /**
   *
   * @type {{}}
   * @private
   */
  _categories = {}

  get codes() {
    return this._codes
  }

  get categories() {
    return Object.values(this._categories)
  }

  /**
   *
   * @param {SubMenuCategory[]} categories
   */
  addCategories = categories => {
    categories.forEach(category => {
      this._codes[category.id] = category.children

      this._categories[category.id] = category
    })
  }

  /**
   *
   * @param {String} selectedType
   * @param {String} code
   * @returns {*}
   */
  _resolveSubMenuName = (selectedType, code) => {
    if (!this._codes.hasOwnProperty(selectedType)) {
      return selectedType
    }

    return this._codes[selectedType].hasOwnProperty(code) ? this._codes[selectedType][code].value : code
  }

  /**
   *
   * @param {String} selectedType
   * @param {String} code
   * @returns {*}
   */
  _resolveOpenedByDefault = (selectedType, code) => {
    if (!this._codes.hasOwnProperty(selectedType)) {
      return selectedType
    }

    return this._codes[selectedType].hasOwnProperty(code) ? this._codes[selectedType][code].isOpenedByDefault : false
  }

  /**
   *
   * @param {Object[]} category
   * @param {null|Number} category.sortOrder
   * @private
   */
  _reorderCategory = category => {
    const sortedObject = {}
    const generalItems = []

    category.forEach(child => {
      if (child.sortOrder) {
        sortedObject[child.sortOrder] = child

        return
      }

      generalItems.push(child)
    })

    return [...Object.values(sortedObject), ...generalItems]
  }

  /**
   *
   * @param {Object[]} response
   * @param {String} selectedType
   * @param {Boolean} response.webEnabled
   * @param {String} response.organizationGroup2Id
   * @param {String} response.organizationId
   * @param {String} response.nameShort
   * @param {null|Number} response.sortOrder
   * @returns {any[]}
   */
  formatCategoriesList = (response, selectedType) => {
    const categories = {}

    response.forEach(category => {
      if (!category.webEnabled) {
        return
      }

      const slug = `${selectedType}-${category.organizationGroup2Id}`

      const isCategoryExists = categories.hasOwnProperty(slug)
      const item = {
        slug: category.organizationId,
        label: category.nameShort,
        sortOrder: category.sortOrder,
        organizationId: category.organizationId,
      }

      if (isCategoryExists) {
        categories[slug].children.push(item)

        return
      }

      categories[slug] = {
        label: this._resolveSubMenuName(selectedType, category.organizationGroup2Id),
        isOpenedByDefault: this._resolveOpenedByDefault(selectedType, category.organizationGroup2Id),
        slug,
        children: [item],
      }
    })

    return Object.values(categories).map(category => ({
      ...category,
      children: this._reorderCategory(category.children),
    }))
  }
}

export default ReportsList
