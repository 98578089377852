import React from 'react'
import PropTypes from 'prop-types'
import ClassNamesService from '../../../services/ClassNamesService'

import TotalHead from './TotalHead'
import StarHead from './StarHead'

const TotalStarHead = ({ score, star, width = null, className = null }) => (
  <div
    className={ClassNamesService.execute(['head-cell head-cell--is-total-star', className])}
    style={{
      width,
    }}
  >
    <TotalHead>{score}</TotalHead>
    <StarHead>{star}</StarHead>
  </div>
)

TotalStarHead.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, () => null]),
  score: PropTypes.string,
  star: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
}

export default TotalStarHead
