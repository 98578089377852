import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { changeYear } from '../../constants/allYears'
import messages, { actions, adminEdit, notifications, validationMessages } from '../../constants/messages'
import { REPORTS_LIST_QUERY } from '../../constants/queries'
import { ADMIN_REPORT } from '../../constants/routePaths'
import NotificationDTO from '../../DTO/NotificationDTO'
import ReportDTO from '../../DTO/ReportDTO'
import SelectItemDTO from '../../DTO/SelectItemDTO'
import { notificationsEntity } from '../../entities/NotificationsEntity'
import { ReactComponent as DeleteIcon } from '../../images/delete.svg'
import { ReactComponent as EditIcon } from '../../images/edit.svg'
import ResponseMiddleware from '../../Middleware/ResponseMiddleware'
import { reportItemsAddItems, ReportItemsAddItems } from '../../redux/actions/Reports'
import httpInstance from '../../services/Http'
import AdminMenuItemVO from '../../VO/AdminMenuItemVO'
import AdminReportItemVO from '../../VO/AdminReportItemVO'
import Layout from '../containers/Layout'
import ReportsModal from './components/ReportsModal'
import MenuCell from './views/MenuCell'
import Paper from './views/Paper'
import SubmitButton from './views/SubmitButton'

const copy = require('clipboard-copy')

const mapStateToProps = (state) => ({
  Reports: state.Reports,
})

const mapDispatchToProps = (dispatch) => ({
  ReportItemsAddItems: (edition) => dispatch(ReportItemsAddItems(edition)),
  reportItemsAddItems: (links, edition) => dispatch(reportItemsAddItems(links, edition)),
})

class Reports extends Component {
  static propTypes = {
    Reports: PropTypes.object,
    ReportItemsAddItems: PropTypes.func,
    reportItemsAddItems: PropTypes.func,
  }

  instance = new AdminReportItemVO()
  ad = AdminReportItemVO

  selectCategory = new SelectItemDTO('Velg kategori', '')
  selectType = new SelectItemDTO('Velg type', '')
  selectLimit = new SelectItemDTO('Velg grense', '')
  selectGroup = new SelectItemDTO('Velg gruppe', '.')
  selectClassType = new SelectItemDTO('Velg klasse', '')
  selectedView = new SelectItemDTO('Velg visning', '')

  initialState = {
    isOpenedModal: false,
    editId: null,
    isLoading: false,

    category: new SelectItemDTO('', ''),
    type: new SelectItemDTO('', ''),
    limit: new SelectItemDTO('10', '10'),
    group: new SelectItemDTO('', '.'),
    classType: new SelectItemDTO('', ''),
    view: new SelectItemDTO('Standard', 'default'),

    categoryVariants: [this.selectCategory, ...this.instance.categoryValues],
    typeVariants: [this.selectType],
    limitVariants: [...this.instance.limitValues],
    viewVariants: [...this.instance.viewValues],
    groupVariants: [this.selectGroup],
    classTypeVariants: [this.selectClassType],

    categoryErrors: [],
    typeErrors: [],
    groupErrors: [],
    classTypeErrors: [],
  }

  state = this.initialState

  get edition() {
    return this.props.match.params.year || AdminMenuItemVO.editions['2019']
  }

  get links() {
    return this.props.Reports[this.edition] || []
  }

  handleModalStateChange = (value) => {
    const nextState = {
      isOpenedModal: value,
      editId: null,
    }

    if (!value) {
      nextState.category = this.initialState.category
      nextState.type = this.initialState.type
      nextState.limit = this.initialState.limit
      nextState.view = this.initialState.view
      nextState.group = this.initialState.group
      nextState.classType = this.initialState.classType
      nextState.cycleThreeItems = this.initialState.cycleThreeItems

      nextState.categoryVariants = this.initialState.categoryVariants
      nextState.typeVariants = this.initialState.typeVariants
      nextState.limitVariants = this.initialState.limitVariants
      nextState.viewVariants = this.initialState.viewVariants
      nextState.groupVariants = this.initialState.groupVariants
      nextState.classTypeVariants = this.initialState.classTypeVariants
    }

    this.setState(nextState)
  }

  handleCategoryChange = (value) => {
    const items = this.instance.handleCategoryChange(value)

    this.setState({
      category: new SelectItemDTO(null, value),
      type: this.initialState.type,
      group: this.initialState.group,
      classType: this.initialState.classType,

      typeVariants: [this.selectType, ...(items || [])],
      groupVariants: this.initialState.groupVariants,
      classTypeVariants: this.initialState.classTypeVariants,
    })
  }

  handleTypeChange = (value) => {
    const items = this.instance.handleTypeChange(value)

    this.setState({
      type: new SelectItemDTO(null, value),
      group: this.initialState.group,
      classType: this.initialState.classType,

      groupVariants: [this.selectGroup, ...(items || [])],
      classTypeVariants: this.initialState.classTypeVariants,
    })
  }

  handleLimitChange = (value) => {
    this.instance.handleLimitChange(value)

    this.setState({
      limit: new SelectItemDTO(null, value),
    })
  }

  handleViewChange = (value) => {
    this.instance.handleViewChange(value)

    this.setState({
      view: new SelectItemDTO(null, value),
    })
  }

  handleGroupChange = (value) => {
    const items = this.instance.handleGroupChange(value)

    this.setState({
      group: new SelectItemDTO(null, value),
      classType: this.initialState.classType,

      classTypeVariants: [this.selectClassType, ...(items || [])],
    })
  }

  handleClassTypeChange = (value) => {
    this.instance.handleClassTypeChange(value)

    this.setState({
      classType: new SelectItemDTO(null, value),
    })
  }

  validateInputs = () => {
    const nextState = {
      categoryErrors: [],
      typeErrors: [],
      groupErrors: [],
      classTypeErrors: [],
    }

    const isValid = [
      new SelectItemDTO('category', this.state.category.value),
      new SelectItemDTO('type', this.state.type.value),
      new SelectItemDTO(
        'group',
        this.state.type.value === 'PS' && this.state.group.value !== this.initialState.group.value
          ? '..'
          : this.state.group.value
      ),
      new SelectItemDTO('classType', this.state.classType.value),
    ].reduce((acc, item) => {
      if (!item.value || item.value === '.') {
        nextState[`${item.title}Errors`] = [validationMessages.fieldIsRequired]
      }

      return !acc ? acc : !!item.value && item.value !== '.'
    }, true)

    this.setState(nextState)

    return isValid
  }

  handleSave = async () => {
    if (!this.validateInputs()) {
      return
    }

    this.setState({
      isLoading: true,
    })

    const categoryRecord = this.state.categoryVariants.find((category) => category.value === this.state.category.value)
    const typeRecord = this.state.typeVariants.find((type) => type.value === this.state.type.value)
    const limitRecord = this.state.limitVariants.find((limit) => limit.value === this.state.limit.value)
    const viewRecord = this.state.viewVariants.find((view) => view.value === this.state.view.value)
    const groupRecord =
      this.state.type.value === 'PS' && this.state.group.value !== this.initialState.group.value
        ? new SelectItemDTO('PS', '')
        : this.state.groupVariants.find((group) => group.value === this.state.group.value)
    const classTypeRecord = this.state.classTypeVariants.find(
      (classType) => classType.value === this.state.classType.value
    )

    const name = [categoryRecord, typeRecord, limitRecord, groupRecord, classTypeRecord, viewRecord]
      .map((item) => item.title)
      .join('; ')

    try {
      await httpInstance.endpoints.reports.create(
        new ReportDTO(null, name, changeYear(this.edition) + this.instance.urlQuery),
        this.edition
      )

      notificationsEntity.addNotification(
        new NotificationDTO(
          null,
          messages.success,
          notifications.createdSuccessfully(),
          NotificationDTO.types.success,
          NotificationDTO.defaultDelay
        )
      )

      this.props.ReportItemsAddItems(this.edition)
      this.handleModalStateChange(false)
    } catch (e) {
      notificationsEntity.addNotification(
        new NotificationDTO(
          null,
          messages.error,
          notifications.somethingWentWrong,
          NotificationDTO.types.error,
          NotificationDTO.defaultDelay
        )
      )
    }

    this.setState({
      isLoading: false,
    })
  }

  handleEditFinish = async () => {
    if (!this.validateInputs()) {
      return
    }

    this.setState({
      isLoading: true,
    })

    const categoryRecord = this.state.categoryVariants.find((category) => category.value === this.state.category.value)
    const typeRecord = this.state.typeVariants.find((type) => type.value === this.state.type.value)
    const limitRecord = this.state.limitVariants.find((limit) => limit.value === this.state.limit.value)
    const viewRecord = this.state.viewVariants.find((view) => view.value === this.state.view.value)

    const groupRecord =
      this.state.type.value === 'PS' && this.state.group.value !== this.initialState.group.value
        ? new SelectItemDTO('PS', '')
        : this.state.groupVariants.find((group) => group.value === this.state.group.value)
    const classTypeRecord = this.state.classTypeVariants.find(
      (classType) => classType.value === this.state.classType.value
    )

    const name = [categoryRecord, typeRecord, limitRecord, groupRecord, classTypeRecord, viewRecord]
      .map((item) => item.title)
      .join('; ')

    try {
      await httpInstance.endpoints.reports.update(
        new ReportDTO(this.state.editId, name, changeYear(this.edition) + this.instance.urlQuery),
        this.edition
      )

      notificationsEntity.addNotification(
        new NotificationDTO(
          null,
          messages.success,
          notifications.updatedSuccessfully(),
          NotificationDTO.types.success,
          NotificationDTO.defaultDelay
        )
      )

      this.props.ReportItemsAddItems(this.edition)
      this.handleModalStateChange(false)
    } catch (e) {
      notificationsEntity.addNotification(
        new NotificationDTO(
          null,
          messages.error,
          notifications.somethingWentWrong,
          NotificationDTO.types.error,
          NotificationDTO.defaultDelay
        )
      )
    }

    this.setState({
      isLoading: false,
    })
  }

  handleEdit = (id) => {
    const record = this.props.Reports[this.edition].find((item) => item.id === id)

    if (!record) {
      return
    }

    this.instance.decodeQuery(record.url)

    const typeVariants = this.instance.mapTypeVariants(this.instance.category)
    const groupVariants = this.instance.mapGroupVariants(this.instance.type)
    const classTypeVariants = this.instance.groupFilters[this.instance.group]

    this.setState({
      isOpenedModal: true,
      editId: id,

      category: new SelectItemDTO(null, this.instance.category),
      type: new SelectItemDTO(null, this.instance.type),
      limit: new SelectItemDTO(null, this.instance.limit),
      view: new SelectItemDTO(null, this.instance.view),
      group: new SelectItemDTO(null, this.instance.group),
      classType: new SelectItemDTO(null, this.instance.classType),

      typeVariants: [this.selectType, ...typeVariants],
      groupVariants: [this.selectGroup, ...groupVariants],
      classTypeVariants: [this.selectClassType, ...classTypeVariants],
    })
  }

  handleDelete = async (id) => {
    const item = this.props.Reports[this.edition].find((item) => item.id === id)

    if (!item) {
      console.log('Invalid id: ' + id)

      return
    }

    const isConfirm = window.confirm(`Do you want to delete "${item.name}" report?`)

    if (!isConfirm) {
      return
    }

    try {
      await httpInstance.endpoints.reports.delete(id)

      notificationsEntity.addNotification(
        new NotificationDTO(
          null,
          messages.success,
          notifications.deletedSuccessfully(item.name),
          NotificationDTO.types.success,
          NotificationDTO.defaultDelay
        )
      )

      this.props.ReportItemsAddItems(this.edition)
    } catch (e) {
      notificationsEntity.addNotification(
        new NotificationDTO(
          null,
          messages.error,
          notifications.somethingWentWrong,
          NotificationDTO.types.error,
          NotificationDTO.defaultDelay
        )
      )
    }
  }

  /**
   *
   * @param {ReportDTO} item
   */
  handleCopyLink = (item) => {
    copy(item.url)

    notificationsEntity.addNotification(
      new NotificationDTO(
        null,
        messages.success,
        notifications.linkCopied(item.name),
        NotificationDTO.types.success,
        NotificationDTO.defaultDelay
      )
    )
  }

  componentDidMount() {
    this.props.ReportItemsAddItems(this.edition)
  }

  render() {
    const response = new ResponseMiddleware()
    response.setLoadingTemplate(() => <></>)
    response.setComponent((data) => {
      this.instance.handleGroupFilters(data.getReportList)

      return <></>
    })

    return (
      <Layout
        title="Rapporter - Top 10"
        menuType={Layout.menuTypes.admin}
        yearChange={ADMIN_REPORT}
        activeYear={this.props.match.params.year}
      >
        <Paper>
          <ul className="admin-table">
            <li className="admin-table__row admin-reports-table__row admin-table__row--is-heading">
              <MenuCell>{adminEdit.title}</MenuCell>
              <MenuCell>{adminEdit.link}</MenuCell>
              <MenuCell />
              <MenuCell />
            </li>
            {this.links.map((item) => (
              <li className="admin-table__row admin-reports-table__row" key={item.id}>
                <MenuCell>{item.name}</MenuCell>
                <MenuCell>
                  <button className="admin-table__link-button" onClick={() => this.handleCopyLink(item)}>
                    {actions.copyLink}
                  </button>
                </MenuCell>
                <MenuCell align="center">
                  <button className="admin-table__row-pen-button" onClick={() => this.handleEdit(item.id)}>
                    <EditIcon className="admin-table__row-pen" />
                  </button>
                </MenuCell>
                <MenuCell align="center">
                  <button className="admin-modal__delete-button" onClick={() => this.handleDelete(item.id)}>
                    <DeleteIcon className="admin-modal__delete-icon" />
                  </button>
                </MenuCell>
              </li>
            ))}
            <li className="admin-table__row admin-table__row--is-button admin-table__row--is-button-outer">
              <SubmitButton handleSubmit={() => this.handleModalStateChange(true)}>
                {actions.generateReport}
              </SubmitButton>
            </li>
          </ul>
          <ReportsModal
            isLoading={this.state.isLoading}
            isOpened={this.state.isOpenedModal}
            handleClose={() => this.handleModalStateChange(false)}
            isEdit={!!this.state.editId}
            category={this.state.category}
            type={this.state.type}
            limit={this.state.limit}
            view={this.state.view}
            group={this.state.group}
            classType={this.state.classType}
            categoryVariants={this.state.categoryVariants}
            typeVariants={this.state.typeVariants}
            limitVariants={this.state.limitVariants}
            viewVariants={this.state.viewVariants}
            groupVariants={this.state.groupVariants}
            classTypeVariants={this.state.classTypeVariants}
            categoryErrors={this.state.categoryErrors}
            typeErrors={this.state.typeErrors}
            groupErrors={this.state.groupErrors}
            classTypeErrors={this.state.classTypeErrors}
            handleCategoryChange={this.handleCategoryChange}
            handleTypeChange={this.handleTypeChange}
            handleLimitChange={this.handleLimitChange}
            handleViewChange={this.handleViewChange}
            handleGroupChange={this.handleGroupChange}
            handleClassTypeChange={this.handleClassTypeChange}
            handleSave={this.state.editId ? this.handleEditFinish : this.handleSave}
          />

          <Query query={REPORTS_LIST_QUERY} variables={{ organizationGroup1Id: this.state.type.value }}>
            {response.execute}
          </Query>
        </Paper>
      </Layout>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Reports))
