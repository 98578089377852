import React, { Component } from 'react'
import ClassNamesService from '../../services/ClassNamesService'

import { formatShootsHeads, getShotsCode } from '../../services/shooterService'

import Table from '../../views/Table/Table'
import HeadSection from '../../views/Table/HeadSection'
import Row from '../Table/Row'
import ResizableCell from '../Table/ResizableCell'
import {
  NumberCell,
  ShotsCell,
  TotalStarCell,
  ShooterCell,
  TotalScoreCell,
  AdditionalCell,
  ScoreCell,
} from '../../views/Table/Cells'
import { NumberHead, ScoreHead, ShooterHead, TotalHead, TotalStarHead, AdditionalHead } from '../../views/Table/Heads'
import Button from '../../views/Button'
import FavoritesButton from '../../containers/FavoritesButton'
import OpenShooterModal from '../../views/OpenShooterModal'

class Lagvis100Table extends Component {
  TOTAL_CELL = 'TOTAL_CELL'

  getSerie = (series) => (name) => {
    return (
      series.find((serie) => serie.name === name) || {
        sum: 0,
        sumInner: 0,
      }
    )
  }

  middleHeads = (shooter) => {
    const formattedHeads = formatShootsHeads(shooter)
    const middleHeads = formattedHeads.slice(0, -1)

    const headsOn768 = ['15 skudd', '10 skudd']

    return {
      items: [
        {
          className: 'hidden-up-to-1199',
          heads: middleHeads.filter((columnName) => !headsOn768.includes(columnName)),
        },
        {
          className: 'hidden-up-to-767',
          heads: headsOn768,
        },
      ],
      secondaryColumns: [
        {
          className: 'hidden-from-1200',
          heads: middleHeads.filter((columnName) => !headsOn768.includes(columnName)),
        },
        {
          className: 'hidden-from-768',
          heads: headsOn768,
        },
      ],
      formattedHeads,
      lastHead: formattedHeads.slice(-1)[0],
      middleHeads,
    }
  }

  render() {
    const { data, activeRelay } = this.props

    const shooters = activeRelay ? data.getActiveRelay.targets : data.getRelay[0].targets
    const shooter = shooters.find((shooter) => shooter.series.length)
    const resolvedMiddleHeads = this.middleHeads(shooter || shooters[0])
    const lastHead = resolvedMiddleHeads.lastHead

    const totalSubscribe = this.props.subscribeGenerator(this.TOTAL_CELL)
    const totalUnsubscribe = this.props.unSubscribeGenerator(this.TOTAL_CELL)
    const totalMaxWidth = this.props.getMaxWidth(this.TOTAL_CELL)

    const middleColumnHandlers = {}

    resolvedMiddleHeads.middleHeads.forEach((headName) => {
      middleColumnHandlers[headName] = {
        subscribe: this.props.subscribeGenerator(headName),
        unsubscribe: this.props.unSubscribeGenerator(headName),
        width: this.props.getMaxWidth(headName),
      }
    })

    return (
      <Table>
        <HeadSection className="head-row--up-to-1200">
          <NumberHead>{activeRelay ? 'PL.' : 'SK.'}</NumberHead>
          {activeRelay ? <NumberHead>Skive</NumberHead> : null}
          <ShooterHead>Navn og Skytterlag</ShooterHead>

          {resolvedMiddleHeads.items.map((group) =>
            group.heads.map((head, idx) => (
              <AdditionalHead key={idx} className={group.className}>
                <ResizableCell {...middleColumnHandlers[head]}>
                  <ScoreHead>{head}</ScoreHead>
                </ResizableCell>
              </AdditionalHead>
            ))
          )}
          <ResizableCell subscribe={totalSubscribe} unsubscribe={totalUnsubscribe} width={totalMaxWidth}>
            <TotalStarHead star="*" score={lastHead} />
          </ResizableCell>
        </HeadSection>

        {shooters.map((shooter, key) => {
          const shooterSeries = this.getSerie(shooter.series)
          const lastSerie = shooterSeries(lastHead)

          const secondaryColumns = (
            <OpenShooterModal>
              {({ onOpen }) => (
                <>
                  {resolvedMiddleHeads.secondaryColumns.map((group) =>
                    group.heads.map((headName, idx) => {
                      const cellSerie = shooterSeries(headName)

                      return (
                        <div key={idx} className={ClassNamesService.execute(['secondary-line', group.className])}>
                          <TotalHead className="head-cell--is-secondary-head">{headName}</TotalHead>
                          <ScoreCell className="cell--is-thin cell--additional-is-shots">
                            {getShotsCode(cellSerie.shots || [])}
                          </ScoreCell>
                          <ResizableCell
                            subscribe={totalSubscribe}
                            unsubscribe={totalUnsubscribe}
                            width={totalMaxWidth}
                          >
                            <TotalScoreCell>{cellSerie.sum}</TotalScoreCell>
                          </ResizableCell>
                        </div>
                      )
                    })
                  )}
                  <Button
                    className="btn--is-secondary-row"
                    onClick={() => onOpen({ shooterId: shooter.shooterOrganizationId })}
                  >
                    Vis skytterkort
                  </Button>
                  <FavoritesButton shooterId={shooter.shooterOrganizationId} />
                </>
              )}
            </OpenShooterModal>
          )

          return (
            <OpenShooterModal key={key}>
              {({ onOpen }) => (
                <Row
                  secondaryRows={resolvedMiddleHeads.secondaryColumns.length && secondaryColumns}
                  className="row--up-to-1200"
                  updateKey={activeRelay ? data.getActiveRelay.name : data.getRelay.name}
                  onClick={() => onOpen({ shooterId: shooter.shooterOrganizationId, from: 1199 })}
                >
                  <NumberCell>{key + 1}</NumberCell>
                  {activeRelay ? <NumberCell>{shooter.number}</NumberCell> : null}

                  <ShooterCell image="" name={shooter.shooterName} club={shooter.clubName} />

                  {resolvedMiddleHeads.items.map((group) =>
                    group.heads.map((headName, idx) => {
                      const cellSerie = shooterSeries(headName)

                      return (
                        <AdditionalCell className={group.className} key={idx}>
                          <ResizableCell {...middleColumnHandlers[headName]}>
                            <ShotsCell
                              hasBorder={idx !== resolvedMiddleHeads.formattedHeads.length - 1}
                              score={String(cellSerie.sum)}
                              shots={getShotsCode(cellSerie.shots || [])}
                            />
                          </ResizableCell>
                        </AdditionalCell>
                      )
                    })
                  )}

                  <ResizableCell subscribe={totalSubscribe} unsubscribe={totalUnsubscribe} width={totalMaxWidth}>
                    <TotalStarCell score={lastSerie.sum} star={lastSerie.sumInner} />
                  </ResizableCell>
                </Row>
              )}
            </OpenShooterModal>
          )
        })}
      </Table>
    )
  }
}

export default Lagvis100Table
