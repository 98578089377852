import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Login from '../components/Login'
import { userCabinet } from '../constants/messages'
import { USER_CABINET_FORGOT_PASSWORD, USER_CABINET_INFO, USER_CABINET_SIGN_UP } from '../constants/routePaths'
import UserEntity from '../entities/UserEntity'
import Http from '../services/Http'

const mapStateToProps = state => ({
  User: state.User,
})

class UserLogin extends Component {
  static propTypes = {
    User: PropTypes.object,
  }

  user = new UserEntity()

  render() {
    return (
      <>
        <Login
          successRoute={USER_CABINET_INFO}
          loginOptions={[
            {
              title: userCabinet.logInShooterId,
              endpoint: Http.endpoints.user.DFSLogin,
            },
            {
              title: userCabinet.logInEmail,
              endpoint: Http.endpoints.user.login,
            },
          ]}
          forgotPasswordRoute={USER_CABINET_FORGOT_PASSWORD}
          signUpRoute={USER_CABINET_SIGN_UP}
          additionalCondition={this.user.isVerified}
        />
      </>
    )
  }
}

export default connect(mapStateToProps)(UserLogin)
