import PropTypes from 'prop-types'
import React from 'react'
import ClassNamesService from '../../../services/ClassNamesService'

const StarHead = ({ children, className = null }) => (
  <div className={ClassNamesService.execute(['head-cell head-cell--is-star', className])}>{children}</div>
)

StarHead.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
}

export default StarHead
