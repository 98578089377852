import { Component } from 'react'
import { createPortal } from 'react-dom'

import '../styles/modal.scss'

const modalRoot = document.getElementById('modal-root')

class Modal extends Component {
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount() {
    document.body.classList.add('overflowHidden')
    modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    document.body.classList.remove('overflowHidden')
    modalRoot.removeChild(this.el)
  }

  render() {
    return createPortal(this.props.children, this.el)
  }
}

export default Modal
