import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import debounce from 'lodash/debounce'
import { SINGLE_REPORT_QUERY } from '../constants/queries'
import ResponseMiddleware from '../Middleware/ResponseMiddleware'
import ClassNamesService from '../services/ClassNamesService'
import AdminReportItemVO from '../VO/AdminReportItemVO'
import DFSLogo from '../images/CA28BF57.png'
import LSLogo from '../images/ls_steinkjer_logo.png'

class TopReport extends Component {
  static propTypes = {}

  reportInstance = new AdminReportItemVO()
  tableRef = React.createRef()
  refetchQuery = null
  unsubscribeResize = () => {}
  timeoutTime = 60 * 1000

  state = {
    cycleArrayIndex: 0,
    version: 0,
    limit: 10,
    loadedData: {},
  }

  constructor(props) {
    super(props)

    this.reportInstance.decodeQuery(window.location.href)
  }

  resolveResultsTitle = (data) => {
    let name = null

    try {
      // const reportCategory = AdminReportItemVO.instances[this.reportInstance.category].categories.find(
      //   (category) => category.id === this.reportInstance.type
      // )
      const shots =
        AdminReportItemVO.instances[this.reportInstance.category].codes[this.reportInstance.type][
          this.reportInstance.group
        ]

      name = [
        // AdminReportItemVO.categories[this.reportInstance.category],
        // reportCategory.name,
        // this.reportInstance.limit,
        shots.value,
        data.nameShort,
      ].join(', ')
    } catch (e) {
      console.log(e)
    }

    return name ? (
      <li className="row">
        <div className="row__primary">
          <div className="cell cell--is-shooter heading">
            <img className="cell__shooter-logo" src={DFSLogo} alt="DFS logo" />
            <span className="cell__shooter-name">{name}</span>
            <img className="cell__shooter-logo" src={LSLogo} alt="LS2024 Steinkjer logo" />
          </div>
        </div>
      </li>
    ) : (
      <></>
    )
  }

  resolveScale = () => {
    const dict = {
      //2.6 at 1200px screen and 2.3 at 900px screen
      [AdminReportItemVO.limits['10']]: () => window.innerHeight * 0.001 + 1.4,
      //2 at 1200px screen and 1.8 at 900px screen
      [AdminReportItemVO.limits['16']]: () => window.innerHeight * 0.0007 + 1.2,
      [AdminReportItemVO.limits['15']]: () => window.innerHeight * 0.0007 + 1.2,
    }

    return dict.hasOwnProperty(this.state.limit) ? dict[this.state.limit]() : dict[AdminReportItemVO.limits['10']]()
  }

  retriveLimit = () => {
    this.setState({
      limit: this.reportInstance.limit,
    })
  }

  requestNewItems = async () => {
    await new Promise((resolve) =>
      this.setState(
        {
          version: this.state.version + 1,
        },
        resolve
      )
    )

    await new Promise((resolve) =>
      setTimeout(() => {
        resolve()
      }, this.timeoutTime)
    )

    await this.requestNewItems()
  }

  subscribeToResize = () => {
    const handler = debounce(() => {
      this.tableRef.current.style.setProperty('--scale', this.resolveScale())
    }, 80)

    window.addEventListener('resize', handler)
    handler()

    return () => {
      window.removeEventListener('resize', handler)
    }
  }

  setLoadedData = (data) => {
    this.setState({
      loadedData: data,
    })
  }

  chunkArray(array, chunkSize) {
    const result = []
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize))
    }
    return result
  }

  setCycleArrayIndex = (index) => {
    this.cycleArrayIndex = index
  }

  displayComponent = (data) => {
    if (!data.getReport) {
      return <></>
    }

    const isCycleThreeItems = this.reportInstance.view === 'cycle-three-items'

    const chunkedArray = this.chunkArray(data.getReport.ranks, 3)

    return isCycleThreeItems ? (
      <>
        {this.resolveResultsTitle(data.getReport)}
        {chunkedArray.length &&
          chunkedArray[this.state.cycleArrayIndex % chunkedArray.length].map((rank) => {
            const key = [data.getReport.organizationGroup2Id, rank.shooters[0].organizationId].join('_')

            return (
              <li className="row" key={key}>
                <div className="row__primary">
                  <div className="cell cell--is-number">
                    <span className="cell__text">{rank.rank}</span>
                  </div>
                  <div className="shooter_img">
                    <img
                      src={`https://mittdfs.no/api/shooterData/ActorImage?actorId=${rank.shooters[0].organizationId}`}
                      alt=""
                    />
                  </div>
                  <div className="cell cell--is-shooter">
                    <div className="cell__shooter-texts">
                      <span className="cell__shooter-name">{rank.name}</span>
                      <span className="cell__club-name">{rank.shooters[0].clubName}</span>
                    </div>
                  </div>
                  <div className="cell cell--is-total-star">
                    <div className="cell cell--is-total">
                      <span className="score-block score-block--is-total">{rank.sum}</span>
                    </div>
                    <div className="cell cell--is-star">
                      <span className="star-block">{rank.sumInner}</span>
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
      </>
    ) : (
      <>
        {this.resolveResultsTitle(data.getReport)}
        {data.getReport.ranks.map((rank) => {
          const key = [data.getReport.organizationGroup2Id, rank.shooters[0].organizationId].join('_')

          return (
            <li className="row" key={key}>
              <div className="row__primary">
                <div className="cell cell--is-number">
                  <span className="cell__text">{rank.rank}</span>
                </div>
                <div className="cell cell--is-shooter">
                  <div className="cell__shooter-texts">
                    <span className="cell__shooter-name">{rank.name}</span>
                    <span className="cell__club-name">{rank.shooters[0].clubName}</span>
                  </div>
                </div>
                <div className="cell cell--is-total-star">
                  <div className="cell cell--is-total">
                    <span className="score-block score-block--is-total">{rank.sum}</span>
                  </div>
                  <div className="cell cell--is-star">
                    <span className="star-block">{rank.sumInner}</span>
                  </div>
                </div>
              </div>
            </li>
          )
        })}
      </>
    )
  }

  componentDidMount() {
    this.unsubscribeResize = this.subscribeToResize()
    this.retriveLimit()

    this.interval = setInterval(() => {
      this.setState((prevState) => ({
        cycleArrayIndex: prevState.cycleArrayIndex + 1,
      }))
    }, 10000)

    setTimeout(() => {
      this.requestNewItems()
    }, this.timeoutTime)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    this.unsubscribeResize()
  }

  render() {
    const response = new ResponseMiddleware()
    response.setErrorTemplate(() => <></>)
    response.setComponent(this.displayComponent)
    response.setLoadingTemplate((refetch) => {
      if (!this.refetchQuery) {
        this.refetchQuery = refetch
      }

      return this.displayComponent(this.state.loadedData)
    })

    return (
      <div className="top-report">
        <ul
          className={ClassNamesService.execute([
            'detailed-table',
            +this.state.limit === 16 && this.reportInstance.view !== 'cycle-three-items' && 'detailed-table--is-16',
            +this.state.limit === 15 && this.reportInstance.view !== 'cycle-three-items' && 'detailed-table--is-16',
            this.reportInstance.view === 'cycle-three-items' && 'cycle_three_items',
          ])}
          ref={this.tableRef}
        >
          <Query
            query={SINGLE_REPORT_QUERY}
            variables={{
              organizationId: this.reportInstance.classType,
              limit: this.state.limit,
              offset: 0,
              version: this.state.version,
            }}
            pollInterval={this.reportInstance.view !== 'cycle-three-items' && 30000}
            fetchPolicy="network-only"
            notifyOnNetworkStatusChange
            onCompleted={this.setLoadedData}
          >
            {response.execute}
          </Query>
        </ul>
      </div>
    )
  }
}

export default withRouter(TopReport)
