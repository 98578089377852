export const KLASS = 'Klass'
export const NAME = 'Name'
export const BANE_LAG = 'baneLag'
export const BANE_SK = 'baneSk'
export const BANE_DATE = 'baneDate'
export const BANE_TIME = 'baneTime'
export const BANE_A = 'baneA'
export const BANE_K = 'baneK'
export const BANE_L = 'baneL'
export const BANE_N = 'baneN'
export const BANE_MB = 'baneMB'
export const BANE_MF = 'baneMF'
export const BANE_FT = 'baneFT'
export const FELT_LAG = 'feltLag'
export const FELT_SK = 'feltSk'
export const FELT_DATE = 'feltDate'
export const FELT_TIME = 'feltTime'
