import IsAbsoluteUrl from 'is-absolute-url'

class ImageService {
  static execute(url) {
    const baseDomain = localStorage.getItem('imageDomain') || ''

    return IsAbsoluteUrl(url) ? url : `${baseDomain}${url}`
  }
}

export default ImageService
