class SubMenuChild {
  _key
  _name
  _openedByDefault

  constructor(key, name, openedByDefault = false) {
    this._key = key
    this._name = name
    this._openedByDefault = openedByDefault
  }

  get naming() {
    return { [this._key]: this._name }
  }

  get id() {
    return this._key
  }

  get value() {
    return this._name
  }

  get isOpenedByDefault() {
    return this._openedByDefault
  }
}

export default SubMenuChild
