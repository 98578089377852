import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, NavLink, withRouter } from 'react-router-dom'
import { availableYears, changeYear, lastYear } from '../../constants/allYears'
import { actions, adminEdit, userCabinet } from '../../constants/messages'
import {
  ADMIN_FP_SETTINGS,
  ADMIN_MENU,
  ADMIN_REPORT,
  HOME_PATH,
  USER_CABINET_INFO,
  USER_CABINET_SHOOTERS,
} from '../../constants/routePaths'
import LinkDTO from '../../DTO/LinkDTO'
import SidebarMenuItem from '../../DTO/SidebarMenuItem'
import UserEntity from '../../entities/UserEntity'
import { ReactComponent as DFSLogo } from '../../images/dfs_logo.svg'
import { ReactComponent as MenuIcon } from '../../images/menu.svg'
import { ReactComponent as ReportIcon } from '../../images/report.svg'
import { ReactComponent as ChevronRightIcon } from '../../images/chevron-right.svg'
import { ReactComponent as ChevronDownIcon } from '../../images/chevron-down.svg'
import { ReactComponent as FavoriteIcon } from '../../images/favorite.svg'
import { ReactComponent as GearIcon } from '../../images/gear.svg'
import { ReactComponent as HeaderMenuIcon } from '../../images/header-menu.svg'
import { ReactComponent as CrossIcon } from '../../images/cross-icon.svg'
import v1 from 'uuid/v1'
import ClassNamesService from '../../services/ClassNamesService'
import OutsideClickVO from '../../VO/OutsideClickVO'

const mapStateToProps = state => ({
  User: state.User,
})

class Layout extends Component {
  static menuTypes = {
    admin: 'admin',
    user: 'user',
  }

  static propTypes = {
    User: PropTypes.object,

    title: PropTypes.string.isRequired,
    activeYear: PropTypes.oneOf(Object.keys(availableYears)),
    menuType: PropTypes.oneOf(Object.keys(Layout.menuTypes)),
    yearChange: PropTypes.func,
  }

  static defaultProps = {
    menuType: Layout.menuTypes.user,
    activeYear: `${availableYears[lastYear]}`,
    yearChange: ADMIN_MENU,
  }

  state = {
    isDropdownVisible: false,
    isMenuOpened: false,
  }

  menuDict = {
    [Layout.menuTypes.admin]: [
      new SidebarMenuItem(
        new LinkDTO(adminEdit.menuEdit, ADMIN_MENU(availableYears[lastYear]), v1()),
        MenuIcon,
        18,
        ADMIN_MENU('')
      ),
      new SidebarMenuItem(
        new LinkDTO(adminEdit.reportsGenerator, ADMIN_REPORT(availableYears[lastYear]), v1()),
        ReportIcon,
        18,
        ADMIN_REPORT('')
      ),
      new SidebarMenuItem(
        new LinkDTO(adminEdit.fpSetting, ADMIN_FP_SETTINGS(availableYears[lastYear]), v1()),
        ReportIcon,
        18,
        ADMIN_FP_SETTINGS('')
      ),
    ],
    [Layout.menuTypes.user]: [
      new SidebarMenuItem(new LinkDTO(userCabinet.favoriteShooters, USER_CABINET_SHOOTERS, v1()), FavoriteIcon, 16),
      new SidebarMenuItem(new LinkDTO(userCabinet.profileSettings, USER_CABINET_INFO, v1()), GearIcon, 14),
    ],
  }

  linkEvents = {}

  user = new UserEntity()
  dropdownRef = React.createRef()
  sidebarRef = React.createRef()
  outsideClick = new OutsideClickVO(this.dropdownRef, () => this.changeDropdownState(false))
  outsideSidebarClick = new OutsideClickVO(this.sidebarRef, () => this.changeMenuState(false))

  /**
   *
   * @return {Array|SidebarMenuItem[]}
   */
  get menu() {
    return this.menuDict[this.props.menuType]
  }

  /**
   *
   * @return {LinkDTO[]}
   */
  get yearsSwitch() {
    return Object.keys(availableYears)
      .reverse()
      .map(year => new LinkDTO(year, this.props.yearChange(year), year))
  }

  /**
   *
   * @return {string}
   */
  get name() {
    return this.user.isAuthenticated ? this.user.state.name : ''
  }

  get isMobile() {
    return window.innerWidth < 1024
  }

  resolveActiveState = (match, location, initialPathname) => {
    if (!!match) {
      return true
    }

    return location.pathname.startsWith(initialPathname)
  }

  changeDropdownState = value => {
    if (value) {
      this.outsideClick.addOutsideHandler()
    }

    this.setState({
      isDropdownVisible: value,
    })
  }

  changeMenuState = (value = false) => {
    if (value) {
      this.outsideSidebarClick.addOutsideHandler()
    }

    return new Promise(resolve =>
      this.setState(
        {
          isMenuOpened: value,
        },
        resolve
      )
    )
  }

  handleLinkClick = async e => {
    if (!this.isMobile) {
      return
    }

    e.persist()

    if (!this.linkEvents.hasOwnProperty(e.target.href)) {
      e.preventDefault()

      this.linkEvents[e.target.href] = ''

      this.changeMenuState(false)

      setTimeout(() => {
        if (e.target.nodeName === 'a') {
          e.target.click()

          return
        }

        const parent = e.target.closest('a')

        if (!parent) {
          console.log('no parent')

          return
        }

        parent.click()
      }, 300)
    }
  }

  handleLogout = async () => {
    await this.user.logout()

    this.props.history.push(HOME_PATH)
  }

  render() {
    return (
      <div className="layout">
        <aside
          className={ClassNamesService.execute([
            'layout__sidebar',
            this.state.isMenuOpened && 'layout__sidebar--is-opened',
          ])}
          ref={this.sidebarRef}
        >
          <button className="layout__sidebar-button" onClick={() => this.changeMenuState(!this.state.isMenuOpened)}>
            {this.state.isMenuOpened ? <CrossIcon /> : <HeaderMenuIcon />}
          </button>

          <div className="layout__logo-wrapper">
            <Link to={HOME_PATH} className="layout__logo-link">
              <DFSLogo className="layout__logo" />
            </Link>
          </div>

          <ul className="layout__sidebar-links">
            {this.menu.map(item => {
              const Icon = item.icon

              return (
                <li className="layout__sidebar-link-wrapper" key={item.link.id}>
                  <NavLink
                    to={item.link.url}
                    className="layout__sidebar-link-item"
                    activeClassName="layout__sidebar-link-item--is-active"
                    isActive={(match, location) => this.resolveActiveState(match, location, item.initialPathname)}
                    onClick={this.handleLinkClick}
                  >
                    <span className="layout__sidebar-link-icon-wrapper">
                      <Icon
                        className="layout__sidebar-link-icon"
                        style={{
                          width: item.iconWidth,
                        }}
                      />
                    </span>
                    {item.link.title}
                  </NavLink>
                </li>
              )
            })}
          </ul>
        </aside>
        <div className="layout__content">
          <div className="layout__top-bar">
            {this.props.menuType === Layout.menuTypes.admin && (
              <ul className="layout__years">
                {this.yearsSwitch.map(item =>
                  +item.title === +this.props.activeYear ? (
                    <li className="layout__year layout__year--is-active" key={item.id}>
                      {item.title}
                    </li>
                  ) : (
                    <li className="layout__year" key={item.id}>
                      <a href={`${changeYear(item.title)}${item.url}`} className="layout__year-button">
                        {item.title}
                      </a>
                    </li>
                  )
                )}
              </ul>
            )}

            <span className="layout-top-bar-user" ref={this.dropdownRef}>
              <button className="layout-top-bar-user__button" onClick={() => this.changeDropdownState(true)}>
                {this.name}
                <ChevronDownIcon className="layout-top-bar-user__icon" />
              </button>

              {this.state.isDropdownVisible && (
                <ul className="layout-top-bar-user__dropdown">
                  <li className="layout-top-bar-user__dropdown-item">
                    <button className="layout-top-bar-user__dropdown-item-button" onClick={this.handleLogout}>
                      {actions.logout}
                    </button>
                  </li>
                </ul>
              )}
            </span>
          </div>
          <div className="layout__scroll-content">
            <div className="layout__page-info">
              <h1 className="layout__page-title">{this.props.title}</h1>
              <ul className="breadcrumbs">
                <li className="breadcrumbs__item-wrapper">
                  <Link to="/" className="breadcrumbs__item">
                    Landsskyttertevnet
                  </Link>

                  <ChevronRightIcon className="breadcrumbs__item-arrow" />
                </li>

                <li className="breadcrumbs__item-wrapper">{this.props.title}</li>
              </ul>
            </div>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(Layout))
