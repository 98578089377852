import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Login from '../../components/Login'
import { availableYears } from '../../constants/allYears'
import { userCabinet } from '../../constants/messages'
import { ADMIN_MENU } from '../../constants/routePaths'
import UserEntity from '../../entities/UserEntity'
import Http from '../../services/Http'

const mapStateToProps = state => ({
  User: state.User,
})

const mapDispatchToProps = dispatch => ({})

class AdminLogin extends Component {
  static propTypes = {
    User: PropTypes.object,
  }

  user = new UserEntity()

  render() {
    return (
      <Login
        title={userCabinet.adminLoginToCabinet}
        successRoute={ADMIN_MENU(Object.values(availableYears)[0])}
        loginOptions={[
          {
            title: userCabinet.loginToCabinet,
            endpoint: Http.endpoints.admin.login,
          },
        ]}
        emailLabel={userCabinet.email}
      />
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminLogin)
