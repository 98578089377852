import React from 'react'
import PropTypes from 'prop-types'

import { getNameInitials } from '../../../services/shooterService'
import ClassNamesService from '../../../services/ClassNamesService'

const ShooterCell = ({ image, name, club, isStartList = false, className = null }) => {
  const hasNoImage = !image || image === ''

  return (
    <div
      className={ClassNamesService.execute([
        'cell cell--is-shooter',
        isStartList ? 'cell--is-startlist' : '',
        className,
      ])}
    >
      <span
        className={ClassNamesService.execute(['cell__image', hasNoImage && 'cell__image--has-no-image'])}
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        {hasNoImage && getNameInitials(name)}
      </span>
      <div className="cell__shooter-texts">
        <span className="cell__shooter-name">{name}</span>
        <span className="cell__club-name">{club}</span>
      </div>
    </div>
  )
}

ShooterCell.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  club: PropTypes.string.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
}

export default ShooterCell
