import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import {
  USER_CABINET_FORGOT_PASSWORD,
  USER_CABINET_INFO,
  USER_CABINET_LOGIN,
  USER_CABINET_RESET_PASSWORD,
  USER_CABINET_SHOOTERS,
  USER_CABINET_SIGN_UP,
} from '../constants/routePaths'
import UserEntity from '../entities/UserEntity'
import FourOhFour from '../pages/fourOhFour'
import FavoriteShooters from './containers/FavoriteShooters'
import UserInfo from './containers/UserInfo'
import ForgotPasswordForm from './ForgotPasswordForm'
import ProtectedRoute from './ProtectedRoute'
import ResetPassword from './ResetPassword'
import SignUp from './SignUp'
import UserLogin from './UserLogin'

const mapStateToProps = state => ({
  User: state.User,
})

const mapDispatchToProps = () => ({})

class Index extends Component {
  static propTypes = {
    User: PropTypes.object,
  }

  user = new UserEntity()

  render() {
    return (
      <>
        <Switch>
          <Route component={UserLogin} path={USER_CABINET_LOGIN} />
          <Route component={SignUp} path={USER_CABINET_SIGN_UP} />
          <Route component={ForgotPasswordForm} path={USER_CABINET_FORGOT_PASSWORD} />
          <Route component={ResetPassword} path={USER_CABINET_RESET_PASSWORD} />

          <ProtectedRoute
            component={UserInfo}
            path={USER_CABINET_INFO}
            redirectRoute={USER_CABINET_LOGIN}
            additionalCondition={!this.user.isAdmin}
          />
          <ProtectedRoute
            component={FavoriteShooters}
            path={USER_CABINET_SHOOTERS}
            redirectRoute={USER_CABINET_LOGIN}
            additionalCondition={!this.user.isAdmin}
          />

          <Route component={FourOhFour} />
        </Switch>
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)
