import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import * as Yup from 'yup'
import { Formik } from 'formik'
import messages, { actions, BEValidationMessages, notifications } from '../constants/messages'

import { userCabinet, validationMessages } from '../constants/messages'
import NotificationDTO from '../DTO/NotificationDTO'
import { notificationsEntity } from '../entities/NotificationsEntity'
import UserEntity from '../entities/UserEntity'
import WithBootstrap from '../HOC/WithBootstrap'
import { LoadingTemplate } from '../Middleware/ResponseMiddleware'
import { FavoritesAddInfo } from '../redux/actions/Favorites'
import { requestPendingFinish, requestPendingStart, requestTypes } from '../redux/actions/RequestPending'
import { userAddUserInfo } from '../redux/actions/User'
import ClassNamesService from '../services/ClassNamesService'
import httpInstance from '../services/Http'
import BackRedirect from '../UserCabinet/BackRedirect'

const mapStateToProps = state => ({
  User: state.User,
  RequestPending: state.RequestPending,
})

const mapDispatchToProps = dispatch => ({
  addUserInfo: user => dispatch(userAddUserInfo(user)),
  FavoritesAddInfo: () => dispatch(FavoritesAddInfo()),
  requestPendingStart: type => dispatch(requestPendingStart(type)),
  requestPendingFinish: type => dispatch(requestPendingFinish(type)),
})

class Login extends Component {
  static propTypes = {
    addUserInfo: PropTypes.func,
    FavoritesAddInfo: PropTypes.func,
    requestPendingStart: PropTypes.func,
    requestPendingFinish: PropTypes.func,

    User: PropTypes.object,
    RequestPending: PropTypes.object,

    signUpRoute: PropTypes.oneOfType([() => null, PropTypes.string]),
    forgotPasswordRoute: PropTypes.oneOfType([() => null, PropTypes.string]),
    emailLabel: PropTypes.string,
    successRoute: PropTypes.string.isRequired,
    additionalCondition: PropTypes.bool,
    title: PropTypes.string,
    loginOptions: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        endpoint: PropTypes.func.isRequired,
      })
    ),
  }

  static defaultProps = {
    signUpRoute: null,
    forgotPasswordRoute: null,
    emailLabel: userCabinet.emailOrSHID,
    title: userCabinet.loginToCabinet,
    additionalCondition: true,
  }

  user = new UserEntity()
  http = httpInstance

  schema = Yup.object({
    username: Yup.string().required(validationMessages.usernameIsRequired),
    password: Yup.string().required(validationMessages.passwordIsRequired),
  })

  get isLoading() {
    return this.props.RequestPending[requestTypes.FAVORITE] || this.props.RequestPending[requestTypes.LOGIN]
  }

  handleLinkClick = e => {
    if (this.isLoading) {
      e.preventDefault()
    }
  }

  resolveErrorResponse = data => {
    if (!data.hasOwnProperty('message')) {
      notificationsEntity.addNotification(
        new NotificationDTO(null, messages.error, notifications.somethingWentWrong, NotificationDTO.types.error)
      )

      return
    }

    const message = BEValidationMessages[data.message] || userCabinet.invalidCredentials

    notificationsEntity.addNotification(
      new NotificationDTO(null, messages.error, message, NotificationDTO.types.error, NotificationDTO.defaultDelay)
    )
  }

  /**
   *
   * @return {Promise<void>}
   */
  resolveUserNonVerified = async () => {
    notificationsEntity.addNotification(
      new NotificationDTO(
        null,
        messages.error,
        notifications.emailNotVerified,
        NotificationDTO.types.error,
        null,
        /**
         *
         * @param {NotificationDTO} notification
         * @return {Promise<void>}
         */
        async notification => {
          const sendingNotification = new NotificationDTO(
            null,
            messages.info,
            notifications.emailSendingInProcess,
            NotificationDTO.types.info
          )

          notificationsEntity.addNotification(sendingNotification)

          notificationsEntity.removeNotification(notification.id)

          try {
            await httpInstance.endpoints.user.resendEmailVerification()

            notificationsEntity.addNotification(
              new NotificationDTO(
                null,
                messages.success,
                notifications.emailVerificationSent,
                NotificationDTO.types.success,
                NotificationDTO.defaultDelay
              )
            )

            this.user.logout()
          } catch (e) {
            notificationsEntity.addNotification(
              new NotificationDTO(null, messages.error, notifications.emailSentFailed, NotificationDTO.types.error)
            )
          }

          notificationsEntity.removeNotification(sendingNotification.id)
        },
        actions.resendEmailVerification
      )
    )
  }

  handleLoginClick = async values => {
    this.props.requestPendingStart(requestTypes.LOGIN)
    let wasSuccess = false

    try {
      const user = await this.props.loginOptions[values.type].endpoint(values.username, values.password)
      this.props.addUserInfo(user)
      this.props.requestPendingStart(requestTypes.FAVORITE)

      if (!this.user.isVerified) {
        this.props.requestPendingFinish(requestTypes.FAVORITE)
        this.props.requestPendingFinish(requestTypes.LOGIN)

        return this.resolveUserNonVerified()
      }

      if (UserEntity.resolveAddingFavorites(user)) {
        await this.props.FavoritesAddInfo()
      }

      wasSuccess = true
    } catch (e) {
      this.resolveErrorResponse(e.response.data)
    }

    if (wasSuccess) {
      notificationsEntity.addNotification(
        new NotificationDTO(
          null,
          messages.success,
          notifications.loggedInSuccessfully,
          NotificationDTO.types.success,
          NotificationDTO.defaultDelay
        )
      )
    }

    this.props.requestPendingFinish(requestTypes.FAVORITE)
    this.props.requestPendingFinish(requestTypes.LOGIN)
  }

  render() {
    const { loginOptions, forgotPasswordRoute, signUpRoute } = this.props
    const loginOptionsLength = loginOptions.length - 1

    return (
      <WithBootstrap>
        <section className="d-flex align-items-center justify-content-center w-100 mt-auto mb-auto">
          {this.user.isAuthenticated && this.props.additionalCondition && (
            <BackRedirect redirectPath={this.props.successRoute} />
          )}
          {(!this.user.isAuthenticated || !this.props.additionalCondition) && (
            <div
              className="d-flex flex-column"
              style={{
                width: '400px',
                maxWidth: '90vw',
              }}
            >
              <h3 className="mb-4">{this.props.title}</h3>
              <Formik
                initialValues={{
                  username: '',
                  password: '',
                  type: 0,
                }}
                validationSchema={this.schema}
                onSubmit={this.handleLoginClick}
              >
                {props => {
                  const { handleSubmit, handleChange, touched, errors, values } = props

                  return (
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        handleSubmit(e)
                      }}
                      noValidate
                    >
                      <Form.Group>
                        <Form.Label>{this.props.emailLabel}</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder={this.props.emailLabel}
                          value={values.username}
                          onChange={handleChange}
                          isInvalid={!!errors.username && touched.username}
                          name="username"
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className={ClassNamesService.execute([touched.username && 'd-flex'])}
                        >
                          {errors.username}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label>{userCabinet.password}</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder={actions.enterPassword}
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password && touched.password}
                          name="password"
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className={ClassNamesService.execute([touched.password && 'd-flex'])}
                        >
                          {errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>

                      {this.isLoading ? (
                        <LoadingTemplate className="js-loaded--is-small" />
                      ) : (
                        <div className="d-flex mb-3">
                          {loginOptions.map((option, idx) => (
                            <Button
                              variant="primary"
                              className={ClassNamesService.execute([idx !== loginOptionsLength && 'mr-2', 'w-100'])}
                              key={idx}
                              disabled={this.isLoading}
                              type="submit"
                              onClick={() => {
                                values.type = idx
                              }}
                            >
                              {option.title}
                            </Button>
                          ))}
                        </div>
                      )}
                      {(forgotPasswordRoute || signUpRoute) && (
                        <div
                          className={ClassNamesService.execute([
                            'd-flex mb-3',
                            (forgotPasswordRoute || signUpRoute) && 'justify-content-center',
                            forgotPasswordRoute && signUpRoute && 'justify-content-between',
                          ])}
                        >
                          {forgotPasswordRoute && (
                            <NavLink
                              className={ClassNamesService.execute([this.isLoading && 'text-secondary'])}
                              to={forgotPasswordRoute}
                              onClick={this.handleLinkClick}
                            >
                              {userCabinet.forgotPassword}
                            </NavLink>
                          )}
                          {signUpRoute && (
                            <NavLink
                              className={ClassNamesService.execute([this.isLoading && 'text-secondary'])}
                              to={signUpRoute}
                              onClick={this.handleLinkClick}
                            >
                              {userCabinet.signUp}
                            </NavLink>
                          )}
                        </div>
                      )}
                    </Form>
                  )
                }}
              </Formik>
            </div>
          )}
        </section>
      </WithBootstrap>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
