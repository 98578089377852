class ActionDTO {
  type
  payload

  constructor(type, payload = null) {
    this.type = type
    this.payload = payload
  }

  get valueOf() {
    return {
      type: this.type,
      payload: this.payload,
    }
  }
}

export default ActionDTO
