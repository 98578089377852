import PropTypes from 'prop-types'

class AdminMenuChildItemDTO {
  static shape = {
    title: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }
  title
  type
  id

  constructor(title, type, id) {
    this.title = title
    this.type = type
    this.id = id

    return this
  }
}

export default AdminMenuChildItemDTO
