import PropTypes from 'prop-types'

class ReportDTO {
  static shape = {
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
    order: PropTypes.number,
    fontSize: PropTypes.number,
  }

  id
  name
  url
  order
  fontSize

  constructor(id, name, url, order, fontSize) {
    this.id = id || null
    this.name = name || ''
    this.url = url || ''
    this.order = order || 0
    this.fontSize = fontSize || 0

    return this
  }

  /**
   *
   * @param data
   * @return {ReportDTO}
   */
  fromBackend = data => {
    this.id = data.id || null
    this.name = data.name || ''
    this.url = data.url || ''
    this.order = data.order || 0
    this.fontSize = data.font_size || 0

    return this
  }
}

export default ReportDTO
