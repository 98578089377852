import ActionDTO from '../../DTO/ActionDTO'
import httpInstance from '../../services/Http'
import AdminMenuItemVO from '../../VO/AdminMenuItemVO'
import { MENU_ITEMS_ADD_ITEM } from '../ActionTypes'

export const menuItemsAddItems = (links, edition) => new ActionDTO(MENU_ITEMS_ADD_ITEM, { links, edition }).valueOf

export const MenuItemsAddItems = edition => async dispatch => {
  const data = await httpInstance.endpoints.menu.items(edition)

  const links = data.map(item => new AdminMenuItemVO().fromBackend(item))

  dispatch(menuItemsAddItems(links, edition))

  return links
}
