import PropTypes from 'prop-types'
import React from 'react'
import ClassNamesService from '../../../services/ClassNamesService'
import HtmlReactParser from 'html-react-parser'

/**
 *
 * @param heading
 * @param type
 * @param className
 * @param placeholder
 * @param handleChange
 * @param value
 * @param isDisabled
 * @param isTextarea
 * @param {string[]} errors
 * @return {*}
 * @constructor
 */
const Input = ({ heading, type, className, placeholder, handleChange, value, isDisabled, isTextarea, errors }) => (
  <label className={ClassNamesService.execute(['admin-input', className])}>
    {heading && <span className="admin-input__heading">{heading}</span>}
    {isTextarea ? (
      <textarea
        value={value}
        disabled={isDisabled}
        onChange={e => handleChange(e.target.value)}
        className={ClassNamesService.execute([
          'admin-input__element admin-input__element--is-textarea',
          errors.length && 'admin-input__element--has-error',
        ])}
        placeholder={placeholder}
      />
    ) : (
      <input
        type={type}
        value={value}
        disabled={isDisabled}
        onChange={e => handleChange(e.target.value)}
        className={ClassNamesService.execute([
          'admin-input__element',
          errors.length && 'admin-input__element--has-error',
        ])}
        placeholder={placeholder}
      />
    )}
    {!!errors.length && (
      <span className="admin-form-message admin-form-message--is-error">{HtmlReactParser(errors.join('<br />'))}</span>
    )}
  </label>
)

Input.propTypes = {
  placeholder: PropTypes.string,
  heading: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  isTextarea: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
}

Input.defaultProps = {
  className: '',
  placeholder: '',
  heading: '',
  type: 'text',
  value: '',
  isDisabled: false,
  isTextarea: false,
  errors: [],
}

export default Input
