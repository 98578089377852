import PropTypes from 'prop-types'
import v1 from 'uuid/v1'

class LinkDTO {
  static shape = {
    title: PropTypes.string,
    url: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }
  title
  url
  id

  constructor(title, url, id) {
    this.title = title
    this.url = this._relative(url)
    this.id = id || v1()

    return this
  }

  /**
   *
   * @param url
   * @return {string}
   * @private
   */
  _relative = url => {
    return url.replace(window.location.protocol + '//', '').replace(window.hostname, '')
  }
}

export default LinkDTO
