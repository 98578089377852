import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ClosestPolyfill from 'element-closest'
import { ADMIN_BASE, USER_CABINET_BASE, USER_CABINET_BASE_PASSWORD } from './constants/routePaths'
import UserEntity from './entities/UserEntity'

import Pages from './pages'
import { FavoritesAddInfo } from './redux/actions/Favorites'
import { requestPendingFinish, requestPendingStart, requestTypes } from './redux/actions/RequestPending'
import { AdminAddUserInfo, UserAddUserInfo } from './redux/actions/User'
import HeightPoints from './services/HeightPoints'
import Index from './UserCabinet/Index'
import AdminIndex from './UserCabinet/admin/Index'
import Notifications from './UserCabinet/Notifications'

ClosestPolyfill(window)
try {
  window.areCustomPropertiesSupported = CSS.supports('color', 'var(--primary)')
} catch (e) {
  window.areCustomPropertiesSupported = false
}

const mapDispatchToProps = dispatch => ({
  AddUserInfo: user => dispatch(UserAddUserInfo(user)),
  AdminAddUserInfo: user => dispatch(AdminAddUserInfo(user)),
  FavoritesAddInfo: () => dispatch(FavoritesAddInfo()),
  requestPendingStart: type => dispatch(requestPendingStart(type)),
  requestPendingFinish: type => dispatch(requestPendingFinish(type)),
})

class App extends React.Component {
  static propTypes = {
    User: PropTypes.object,

    AddUserInfo: PropTypes.func,
    AdminAddUserInfo: PropTypes.func,
    FavoritesAddInfo: PropTypes.func,
    requestPendingStart: PropTypes.func,
    requestPendingFinish: PropTypes.func,
  }

  user = new UserEntity()
  heightPoints = new HeightPoints()

  async componentDidMount() {
    this.props.requestPendingStart(requestTypes.LOGIN)
    try {
      await this.props.AddUserInfo()

      if (this.user.isAuthenticated && !this.user.isVerified) {
        await this.user.logout()

        this.props.requestPendingFinish(requestTypes.LOGIN)
        this.props.requestPendingFinish(requestTypes.FAVORITE)

        return
      }

      this.props.requestPendingStart(requestTypes.FAVORITE)
      await this.props.FavoritesAddInfo()
    } catch (e) {
      try {
        await this.props.AdminAddUserInfo()
      } catch (e) {
        console.log(e)
      }
    }
    this.props.requestPendingFinish(requestTypes.LOGIN)
    this.props.requestPendingFinish(requestTypes.FAVORITE)
  }

  componentWillUnmount() {
    this.heightPoints.unsubscribe()
  }

  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route path={USER_CABINET_BASE_PASSWORD} component={Index} />
            <Route path={USER_CABINET_BASE} component={Index} />
            <Route path={ADMIN_BASE} component={AdminIndex} />
            <Route component={Pages} />
          </Switch>
        </Router>
        <Notifications />
      </>
    )
  }
}

export default connect(
  null,
  mapDispatchToProps
)(App)
