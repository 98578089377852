import PropTypes from 'prop-types'
import React from 'react'
import ClassNamesService from '../../../services/ClassNamesService'

const MenuCell = ({ className, align, hasRightOffset, isInner, isInnerTitle, children }) => (
  <span
    className={ClassNamesService.execute([
      'admin-table__row-text',
      align === 'center' && 'admin-table__row-text--is-centered',
      align === 'end' && 'admin-table__row-text--is-align-end',
      isInner && !isInnerTitle && 'admin-table__row-text--is-inner',
      !isInner && isInnerTitle && 'admin-table__row-text--is-inner-title',
      hasRightOffset && 'admin-table__row-text--has-right-offset',
      className,
    ])}
  >
    {children}
  </span>
)

MenuCell.propTypes = {
  className: PropTypes.string,
  align: PropTypes.oneOf([null, 'end', 'center']),
  hasRightOffset: PropTypes.bool,
  isInner: PropTypes.bool,
  isInnerTitle: PropTypes.bool,
}

MenuCell.defaultProps = {
  className: '',
  align: null,
  hasRightOffset: false,
  isInner: false,
  isInnerTitle: false,
}

export default MenuCell
