import gql from 'graphql-tag'
import { LAGVIS_LIST, RANGED_FIELD, SAMLAGS_FYLKE, SAMLAGS_FYLKE_SHOOTERS } from './constants/localTypes'

export const resolvers = {
  Mutation: {
    openShooterModalMutation: (_, args, { cache }) => {
      cache.writeData({
        data: {
          isShooterModalOpen: true,
          shooterModalId: String(args.shooterModalId),
        },
      })
      return null
    },
    closeShooterModalMutation: (_, __, { cache }) => {
      cache.writeData({
        data: { isShooterModalOpen: false, shooterModalId: '' },
      })
      return null
    },

    rangedFieldUpdateDataMutation: (_, args, { cache }) => {
      cache.writeData({
        data: {
          rangedField: {
            __typename: RANGED_FIELD,
            ...args,
          },
        },
      })
      return null
    },

    lagvisListUpdateDataMutation: (_, args, { cache }) => {
      cache.writeData({
        data: {
          lagvisList: {
            __typename: LAGVIS_LIST,
            ...args,
          },
        },
      })
      return null
    },

    samlagsFylkeUpdateDataMutation: (_, args, { cache }) => {
      cache.writeData({
        data: {
          samlagsFylke: {
            __typename: SAMLAGS_FYLKE,
            ...args,
          },
        },
      })

      return null
    },

    samlagsFylkeUpdateShootersMutation: (_, { id, isOpened }, { cache }) => {
      const { samlagsFylkeShooters } = cache.readQuery({
        query: gql`
          query {
            samlagsFylkeShooters @client {
              list
            }
          }
        `,
      })

      const shootersHashMap = JSON.parse(samlagsFylkeShooters.list)
      const newObject =
        id === null
          ? {}
          : {
              ...shootersHashMap,
              [id]: isOpened,
            }

      cache.writeData({
        data: {
          samlagsFylkeShooters: {
            __typename: SAMLAGS_FYLKE_SHOOTERS,
            list: JSON.stringify(newObject),
          },
        },
      })

      return null
    },
  },
}
