import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

class ResizableTable extends Component {
  static propTypes = {
    updateParameters: PropTypes.object,
  }

  static defaultProps = {
    updateParameters: {},
  }

  state = {
    resizableColumns: {},
    update: false,
  }

  windowWidth = window.innerWidth
  resizableColumns = {}

  subscribeGenerator = columnId => (cellId, callback) => {
    const _columnId = this.processColumnId(columnId)

    if (!this.resizableColumns.hasOwnProperty(_columnId)) {
      this.resizableColumns[_columnId] = {}
    }

    this.resizableColumns[_columnId][cellId] = callback

    return _columnId
  }

  unSubscribeGenerator = columnId => cellId => {
    const _columnId = this.processColumnId(columnId)

    if (!this.resizableColumns.hasOwnProperty(_columnId)) {
      return
    }

    delete this.resizableColumns[_columnId][cellId]
  }

  setColumnWidth = (columnId, maxWidth, callback = () => {}) => {
    if (window.areCustomPropertiesSupported) {
      document.documentElement.style.setProperty(`--${this.processColumnId(columnId)}`, maxWidth)

      return
    }

    this.setState(
      {
        resizableColumns: {
          ...this.state.resizableColumns,
          [columnId]: {
            maxWidth,
          },
        },
      },
      callback
    )
  }

  processColumnId = columnId => {
    return columnId.replace(/[ .]/g, '-')
  }

  getMaxWidth = columnId => {
    const currentColumn = this.state.resizableColumns[this.processColumnId(columnId)] || {
      maxWidth: null,
    }

    return currentColumn.maxWidth
  }

  handleWithCustomProperties = (columnCells, columnId) => {
    this.setColumnWidth(columnId, 'auto')

    const widths = Object.values(columnCells).map(callback => callback() || 0)
    const maxWidth = Math.max(...widths)

    this.setColumnWidth(columnId, `${maxWidth + 5}px`)
  }

  handleWithoutCustomProperties = (columnCells, columnId) => {
    const updateColumnWidth = () => {
      const widths = Object.values(columnCells).map(callback => callback() || 0)
      const maxWidth = Math.max(...widths)

      this.setColumnWidth(columnId, `${maxWidth + 5}px`)
    }

    this.setColumnWidth(columnId, null, updateColumnWidth)
  }

  resizeHandler = debounce((forceUpdate = false) => {
    const newWidth = window.innerWidth

    if (newWidth === this.windowWidth && !(forceUpdate && typeof forceUpdate === 'boolean')) {
      return
    }

    for (let columnId in this.resizableColumns) {
      const columnCells = this.resizableColumns[columnId]

      window.areCustomPropertiesSupported && this.handleWithCustomProperties(columnCells, columnId)
      !window.areCustomPropertiesSupported && this.handleWithoutCustomProperties(columnCells, columnId)
    }

    this.windowWidth = newWidth
  }, 500)

  componentDidMount() {
    this.resizeHandler(true)

    window.addEventListener('resize', this.resizeHandler)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const equalityConditions = []

    for (let prop in this.props.updateParameters) {
      equalityConditions.push(prevProps.updateParameters[prop] === this.props.updateParameters[prop])
    }

    const areEqualProps = equalityConditions.reduce((acc, item) => acc && item, true)

    if (areEqualProps) {
      return
    }

    this.resizeHandler(true)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
  }

  render() {
    return (
      <>
        {React.cloneElement(this.props.children, {
          subscribeGenerator: this.subscribeGenerator,
          unSubscribeGenerator: this.unSubscribeGenerator,
          getMaxWidth: this.getMaxWidth,
        })}
      </>
    )
  }
}

export default ResizableTable
