import PropTypes from 'prop-types'
import QueryString from 'query-string'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'

const mapStateToProps = state => ({
  RequestPending: state.RequestPending,
})

class BackRedirect extends Component {
  static propTypes = {
    RequestPending: PropTypes.object,

    redirectPath: PropTypes.string,
  }

  static defaultProps = {
    redirectPath: '',
  }

  static resolveIsLoading(RequestsObject) {
    return Object.values(RequestsObject).reduce((acc, item) => (acc ? acc : !!item), false)
  }

  get redirectPath() {
    const search = QueryString.parse(this.props.location.search.slice(1))

    return search.from ? decodeURIComponent(search.from) : this.props.redirectPath
  }

  get isLoading() {
    return BackRedirect.resolveIsLoading(this.props.RequestPending)
  }

  render() {
    return this.isLoading ? <></> : <Redirect to={this.redirectPath} />
  }
}

export default withRouter(connect(mapStateToProps)(BackRedirect))
