import PropTypes from 'prop-types'
import React from 'react'
import ClassNamesService from '../../services/ClassNamesService'

const HeadSection = ({ children, className = null }) => (
  <li className={ClassNamesService.execute(['head-row', className])}>{children}</li>
)

HeadSection.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
}

export default HeadSection
