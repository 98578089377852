import axios from 'axios'
import AdminVO from '../VO/Http/AdminVO'
import EditionsTextsVO from '../VO/Http/EditionsTextsVO'
import FavoriteVO from '../VO/Http/FavoriteVO'
import MenuVO from '../VO/Http/MenuVO'
import ReportVO from '../VO/Http/ReportVO'
import UserVO from '../VO/Http/UserVO'

class Http {
  get baseURL() {
    const hostname = window.location.hostname
    const apiHash = {
      localhost: 'http://localhost:8001/',
      'lsres-stage.innocode.digital': 'https://api.lsres-stage.innocode.digital/',
      default: 'https://api.lsres.no/',
    }

    return apiHash.hasOwnProperty(hostname) ? apiHash[hostname] : apiHash.default
  }

  instance = axios.create({
    baseURL: this.baseURL,
    withCredentials: true,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
  })

  endpoints = {
    user: new UserVO(this.instance),
    favorites: new FavoriteVO(this.instance),
    admin: new AdminVO(this.instance),
    menu: new MenuVO(this.instance),
    reports: new ReportVO(this.instance),
    editionTexts: new EditionsTextsVO(this.instance),
  }
}

const httpInstance = new Http()
export default httpInstance
