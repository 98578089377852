import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Redirect, Route, withRouter } from 'react-router-dom'
import UserEntity from '../entities/UserEntity'
import QueryString from 'query-string'
import FourOhFour from '../pages'

class ProtectedRoute extends Component {
  static propTypes = {
    redirectRoute: PropTypes.string,
    component: PropTypes.elementType,
    path: PropTypes.string,
    additionalCondition: PropTypes.bool,
    showNotFoundOnAdditionalCondition: PropTypes.bool,
  }

  static defaultProps = {
    additionalCondition: true,
    showNotFoundOnAdditionalCondition: true,
  }

  user = new UserEntity()

  get redirectPath() {
    const search = QueryString.parse(this.props.location.search.slice(1))
    const { from, ...rest } = search

    const newSearch = {
      from: encodeURIComponent(this.props.location.pathname + '?' + QueryString.stringify(rest)),
    }

    return this.props.redirectRoute + '?' + QueryString.stringify(newSearch)
  }

  render() {
    const { additionalCondition, path, component, ...rest } = this.props

    if (!this.user.isAuthenticated) {
      return <Redirect to={this.redirectPath} />
    }

    if (additionalCondition) {
      return <Route path={path} component={component} {...rest} />
    }

    return <FourOhFour />
  }
}

export default withRouter(ProtectedRoute)
