import debounce from 'lodash/debounce'

class HeightPoints {
  unsubscribe = () => {}

  constructor() {
    this.unsubscribe = this.subscribe()
  }

  subscribe = () => {
    const handler = debounce(() => {
      document.documentElement.style.setProperty('--height-point', `${window.innerHeight * 0.01}px`)
    }, 50)

    window.addEventListener('resize', handler)
    handler()

    return () => {
      window.removeEventListener('resize', handler)
    }
  }
}

export default HeightPoints
