import React, { Component } from 'react'
import PropTypes from 'prop-types'

const uuid = require('uuid/v4')

class ResizableCellWidth extends Component {
  state = {
    cellId: '',
    columnId: '',
  }
  mainRef = React.createRef()

  componentDidMount() {
    const cellId = uuid()

    this.setState({
      cellId,
      columnId: this.props.subscribe(cellId, () => (this.mainRef.current ? this.mainRef.current.offsetWidth : 0)),
    })
  }

  componentWillUnmount() {
    this.props.unsubscribe(this.state.cellId)
  }

  render() {
    const { children, className, width } = this.props

    return (
      <div
        ref={this.mainRef}
        style={{
          display: 'flex',
          padding: '0',
        }}
      >
        {React.cloneElement(children, {
          width,
          className,
        })}
      </div>
    )
  }
}

ResizableCellWidth.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, () => null]),
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
  subscribe: PropTypes.func,
  unsubscribe: PropTypes.func,
}

ResizableCellWidth.defaultProps = {
  width: null,
  className: null,
  subscribe: () => {},
  unsubscribe: () => {},
}

export default ResizableCellWidth
