import React from 'react'
import PropTypes from 'prop-types'
import ClassNamesService from '../../../services/ClassNamesService'

const TotalScoreCell = ({ children, width = null, hasMidPadding = false, className = null }) => (
  <div
    className={ClassNamesService.execute([
      'cell cell--is-total',
      className,
      hasMidPadding && 'cell--has-mid-padding-right',
    ])}
    style={{
      width,
    }}
  >
    <span className="score-block score-block--is-total">{children}</span>
  </div>
)

TotalScoreCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, () => null]),
  hasMidPadding: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
}

export default TotalScoreCell
