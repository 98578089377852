import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import isEqual from 'lodash/isEqual'

import SelectedLink from '../entities/SelectedLink'
import Category from '../views/SidebarFilter/Category'
import '../styles/sidebar-filter/index.scss'

class SidebarFilter extends Component {
  static propTypes = {
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        slug: PropTypes.string,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            slug: PropTypes.string,
          })
        ),
      })
    ),
    selectFirstByDefault: PropTypes.bool,
    categoryComponent: PropTypes.oneOfType([() => null, PropTypes.elementType]),
    onSelectLink: PropTypes.func,
    cleanSelectedLink: PropTypes.bool,
  }

  static defaultProps = {
    categories: [],
    selectFirstByDefault: false,
    categoryComponent: null,
    onSelectLink: () => {},
  }

  state = {
    categories: [],
    openedCategories: [],
    selectedLink: '',
  }

  get firstCategoryLink() {
    if (!this.state.categories.length) {
      return undefined
    }

    const category = this.state.categories[0]

    if (!category.children.length) {
      return undefined
    }

    const link = category.children[0]
    const linkInstance = new SelectedLink()
    linkInstance.setLinkAsParameters(category.slug, link.slug)

    return linkInstance.linkString
  }

  resolveOpenedCategoriesByDefault = (preselectedLink = '', props = this.props) => {
    return props.categories
      .filter((category) => category.slug !== preselectedLink && category.isOpenedByDefault)
      .map((category) => category.slug)
  }

  async componentDidMount() {
    await new Promise((resolve) =>
      this.setState(
        {
          categories: this.props.categories,
        },
        resolve
      )
    )

    let { selectedLink } = queryString.parse(this.props.location.search)
    const firstAvailableLink = this.firstCategoryLink

    if (!selectedLink && (!this.props.selectFirstByDefault || !firstAvailableLink)) {
      this.setState({
        openedCategories: this.resolveOpenedCategoriesByDefault(),
      })

      return
    }

    if (!selectedLink) {
      selectedLink = firstAvailableLink
    }

    const linkEntity = new SelectedLink()
    linkEntity.setLinkAsString(selectedLink)

    this.setState({
      selectedLink,
      openedCategories: [linkEntity.category, ...this.resolveOpenedCategoriesByDefault(linkEntity.category)],
    })

    const category = this.props.categories.find((category) => category.slug === linkEntity.category)

    if (!category) {
      return
    }

    const option = category.children.find((child) => child.slug === linkEntity.link)
    this.props.onSelectLink(category, option)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Cleanup selected link if there is additional custom link

    if (!!prevProps.cleanSelectedLink && !!prevState.selectedLink) {
      this.setState({
        selectedLink: '',
      })
    }

    if (isEqual(prevProps.categories, this.props.categories)) {
      return
    }

    const { selectedLink } = queryString.parse(this.props.location.search)

    const linkEntity = new SelectedLink()
    linkEntity.setLinkAsString(selectedLink)

    this.setState({
      categories: this.props.categories,
      openedCategories: [linkEntity.category, ...this.resolveOpenedCategoriesByDefault(linkEntity.category)],
    })
  }

  toggleCategory = (categoryKey) => {
    const isCategoryOpened = this.isCategoryOpened(categoryKey)

    if (isCategoryOpened) {
      this.setState({
        openedCategories: this.state.openedCategories.filter((category) => category !== categoryKey),
      })

      return
    }

    this.setState({
      openedCategories: [...this.state.openedCategories, categoryKey],
    })
  }

  selectLink = (categoryKey, link) => {
    const linkEntity = new SelectedLink()
    linkEntity.setLinkAsParameters(categoryKey, link)

    this.setState({
      selectedLink: linkEntity.linkString,
    })

    const category = this.state.categories.find((category) => category.slug === categoryKey)
    const option = category.children.find((child) => child.slug === link)
    this.props.onSelectLink(category, option)

    const { pathname, search } = this.props.location
    const previousSearch = queryString.parse(search)

    this.props.history.push({
      pathname,
      search: queryString.stringify({
        ...previousSearch,
        selectedLink: linkEntity.linkString,
      }),
    })
  }

  isCategoryOpened = (categoryKey) => this.state.openedCategories.includes(categoryKey)

  render() {
    const { categories, selectedLink } = this.state

    return (
      <div className="sidebar-filter">
        {categories.map((category) => {
          const resolvedProps = this.props.categoryComponent
            ? {
                ...category,
                defaultComponent: this.props.categoryComponent,
              }
            : category

          return (
            <Category
              {...resolvedProps}
              key={category.slug}
              selectedLink={selectedLink}
              toggleCategory={this.toggleCategory}
              selectLink={this.selectLink}
              isOpened={this.isCategoryOpened(category.slug)}
            />
          )
        })}
      </div>
    )
  }
}

export default withRouter(SidebarFilter)
