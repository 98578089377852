import PropTypes from 'prop-types'
import React from 'react'

import '../styles/select-label.scss'
import { ReactComponent as CrossIcon } from '../images/cross-icon.svg'

const SelectLabel = ({ id, text, handleClick = () => {} }) => (
  <span className="select-label">
    {text} <CrossIcon className="select-label__icon" onClick={() => handleClick({ id })} />
  </span>
)

SelectLabel.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
}

export default SelectLabel
