import PropTypes from 'prop-types'
import v1 from 'uuid/v1'

class SelectItemDTO {
  static shape = {
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, () => null]),
  }
  title
  value

  constructor(title, value) {
    this.title = title || ''
    this.value = value === null ? v1() : value

    return this
  }
}

export default SelectItemDTO
