import PropTypes from 'prop-types'
import React, { Component } from 'react'
import '../styles/bootstrap.scss'
import ClassNamesService from '../services/ClassNamesService'

class WithBootstrap extends Component {
  static propTypes = {
    children: PropTypes.element,
    isAbsolute: PropTypes.bool,
    isSmall: PropTypes.bool,
  }

  static defaultProps = {
    isAbsolute: false,
    isSmall: false,
  }

  render() {
    return (
      <div
        className={ClassNamesService.execute([
          'bootstrap-styles',
          this.props.isAbsolute && 'bootstrap-styles--is-abs',
          this.props.isSmall && 'bootstrap-styles--is-small',
        ])}
      >
        {this.props.children}
      </div>
    )
  }
}

export default WithBootstrap
