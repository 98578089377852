import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { GET_SHOOTER } from '../constants/queries'

import '../styles/shooter-page.scss'
import { ContentShooterInfo } from '../components'
import ResponseMiddleware from '../Middleware/ResponseMiddleware'
import ClassNamesService from '../services/ClassNamesService'

let origBodyClass

class ShooterPage extends Component {
  componentDidMount() {
    origBodyClass = document.body.className
    document.body.className = ClassNamesService.execute([origBodyClass, 'shooterpage'])

    this.props.onShowSearch(false)
    this.props.onShowNav(false)
  }

  componentWillUnmount() {
    document.body.className = origBodyClass
  }

  render() {
    const response = new ResponseMiddleware()
    response.setComponent(data => {
      const shooterInfo = data.getShooter && data.getShooter[0]

      return <ContentShooterInfo shooterInfo={shooterInfo} />
    })

    return (
      <div className="full-page-wrapper animFadeIn" style={{ marginTop: '50px' }}>
        <Query query={GET_SHOOTER} variables={{ organizationId: this.props.match.params.id }}>
          {response.execute}
        </Query>
      </div>
    )
  }
}

export default ShooterPage
