import PropTypes from 'prop-types'
import React from 'react'
import ReactModal from 'react-modal'
import ModalClose from '../components/modalClose'
import ClassNamesService from '../services/ClassNamesService'

const ModalComponent = ({ className, children, isOpened, handleClose, title }) => (
  <>
    <ReactModal
      isOpen={isOpened}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      className={ClassNamesService.execute([className, 'modal-component'])}
      overlayClassName="modal-component__overlay"
    >
      <h4 className="modal-component__title">{title}</h4>
      <ModalClose onClick={handleClose} className="modal__close--is-alt" />
      {children}
    </ReactModal>
  </>
)

ModalComponent.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  isOpened: PropTypes.bool,
  handleClose: PropTypes.func,
}

ModalComponent.defaultProps = {
  title: '',
  className: '',
}

export default ModalComponent
