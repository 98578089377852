import React, { Component } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'

import { ReactComponent as ArrowIcon } from '../images/arrow-icon.svg'
import ClassNamesService from '../services/ClassNamesService'

import DropdownItem from '../views/DropdownItem'
import '../styles/dropdown.scss'

class Dropdown extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        slug: PropTypes.string,
      })
    ),
    postText: PropTypes.string,
    placeholderText: PropTypes.string,
    selectedOption: PropTypes.string,
    onChange: PropTypes.func,
    isOpened: PropTypes.bool,
    dropdownItem: PropTypes.elementType,
  }

  static defaultProps = {
    options: [],
    postText: ' - velg øvelse',
    placeholderText: 'Placeholder',
    selectedOption: '',
    onChange: () => {},
    isOpened: false,
    dropdownItem: DropdownItem,
  }

  state = {
    options: [
      {
        label: 'Option 1',
        slug: 'option-1',
      },
      {
        label: 'Option 2',
        slug: 'option-2',
      },
      {
        label: 'Option 3',
        slug: 'option-3',
      },
    ],
    isOpened: false,
    placeholderText: '',
    selectedOption: '',
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      isEqual(prevProps.options, this.props.options) &&
      prevProps.selectedOption === this.props.selectedOption &&
      prevProps.placeholderText === this.props.placeholderText &&
      prevProps.isOpened === this.props.isOpened
    ) {
      return
    }

    this.setState({
      ...this.props,
    })
  }

  componentDidMount() {
    this.setState({
      ...this.props,
    })
  }

  toggleOpened = () => {
    this.setState({
      isOpened: !this.state.isOpened,
    })
  }

  selectOption = selectedOption => {
    this.setState({
      selectedOption,
    })

    this.toggleOpened()
    this.props.onChange(selectedOption)
  }

  get selectedOption() {
    return this.state.options.find(option => option.slug === this.state.selectedOption) || { slug: '' }
  }

  get label() {
    return this.selectedOption.slug !== '' ? this.selectedOption.label : this.state.placeholderText
  }

  render() {
    const { options, isOpened } = this.state
    const Component = this.props.dropdownItem

    return (
      <div className="dropdown">
        <span className="dropdown__label" onClick={this.toggleOpened}>
          {this.label}
          {this.props.postText}
          <ArrowIcon
            className={ClassNamesService.execute(['dropdown__arrow', isOpened && 'dropdown__arrow--is-opened'])}
          />
        </span>
        <ul className={ClassNamesService.execute(['dropdown__options', isOpened && 'dropdown__options--is-opened'])}>
          {options.map(option => (
            <Component
              key={option.slug}
              {...option}
              onChange={this.selectOption}
              isSelected={this.selectedOption.slug === option.slug}
            />
          ))}
        </ul>
      </div>
    )
  }
}

export default Dropdown
