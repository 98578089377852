import PropTypes from 'prop-types'
import React from 'react'

import '../styles/messages.scss'
import ClassNamesService from '../services/ClassNamesService'

const Message = ({ children, text = null, style = {}, className = null }) => (
  <div className={ClassNamesService.execute(['message', className])}>
    {text && <p style={style}>{text}</p>}
    {!text && children ? children : <></>}
  </div>
)

Message.propTypes = {
  children: PropTypes.element,
  text: PropTypes.oneOfType([() => null, PropTypes.string]),
  style: PropTypes.object,
  className: PropTypes.oneOfType([() => null, PropTypes.string]),
}

export default Message
