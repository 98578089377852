import merge from 'lodash/merge'
import { PAGE_SETTINGS_ADD_ITEM } from '../ActionTypes'

const defaultState = {
  fp: {},
}

/**
 *
 * @param {Object} state
 * @param {Object} action
 * @param {String} action.type
 * @param {*} action.payload
 * @returns {Object}
 */
export default (state = defaultState, action) => {
  switch (action.type) {
    case PAGE_SETTINGS_ADD_ITEM:
      return merge(state, action.payload)

    default:
      return state
  }
}
