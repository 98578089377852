import React, { Component } from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'
import { Loader } from './index'

class LoadMore extends Component {
  static propTypes = {
    onLoadMore: PropTypes.func,
    isLoadingMore: PropTypes.bool,
    canLoadMore: PropTypes.bool,
  }

  static defaultProps = {
    onLoadMore: () => {},
    isLoadingMore: false,
    canLoadMore: true,
  }

  state = {
    isLoading: false,
  }
  tableRef = React.createRef()

  windowScrollHandler = throttle(() => {
    const windowScrolled = window.innerHeight + window.scrollY
    const tableHeight = this.tableRef.current.offsetHeight
    const subloadingDistance = window.innerHeight * 1.5

    if (windowScrolled < tableHeight - subloadingDistance || !this.props.canLoadMore) {
      return
    }

    this.props.onLoadMore()
  }, 100)

  componentDidMount() {
    window.addEventListener('scroll', this.windowScrollHandler)
    this.windowScrollHandler()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.windowScrollHandler)
  }

  render() {
    return (
      <div className="load-more-wrapper" ref={this.tableRef}>
        {this.props.children}
        {this.props.isLoadingMore && <Loader />}
      </div>
    )
  }
}

export default LoadMore
