import React, { PureComponent } from 'react'

const centerContent = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  textAlign: 'center',
}

class FourOhFour extends PureComponent {
  static defaultProps = {
    onShowNav: () => {},
    onShowSearch: () => {},
  }

  componentDidMount() {
    this.props.onShowNav(false)
    this.props.onShowSearch(false)
  }

  render() {
    return (
      <div className="wrapper" style={centerContent}>
        <h1>404</h1>
        <p>Try navigating somewhere else</p>
      </div>
    )
  }
}

export default FourOhFour
