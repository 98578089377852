import PropTypes from 'prop-types'
import React from 'react'
import ClassNamesService from '../../../services/ClassNamesService'

const NumberCell = ({ children, className = null }) => (
  <div className={ClassNamesService.execute(['cell cell--is-number', className])}>
    <span className="cell__text">{children}</span>
  </div>
)

NumberCell.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
}

export default NumberCell
