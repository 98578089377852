import v1 from 'uuid/v1'

class NotificationDTO {
  static types = {
    error: 'error',
    success: 'success',
    info: 'info',
  }

  static defaultDelay = 4000

  id
  title
  content
  type
  delay
  handler
  actionTitle
  createdAt = null
  addedToQueue = false

  /**
   *
   * @param id
   * @param title
   * @param content
   * @param type
   * @param {null|number} delay - ms
   * @param handler
   * @param actionTitle
   */
  constructor(id, title, content, type, delay = null, handler = null, actionTitle = null) {
    this.id = id || v1()
    this.title = title || ''
    this.content = content || ''
    this.type = type || NotificationDTO.types.info
    this.delay = delay
    this.handler = handler
    this.actionTitle = actionTitle
  }

  /**
   *
   * @return {NotificationDTO}
   */
  addToQueue = () => {
    this.createdAt = new Date().valueOf()
    this.addedToQueue = true

    return this
  }

  /**
   *
   * @param data
   * @return {NotificationDTO}
   */
  fromStore = data => {
    this.id = data.id
    this.title = data.title
    this.content = data.content
    this.type = data.type
    this.delay = data.delay
    this.handler = data.handler
    this.actionTitle = data.actionTitle
    this.createdAt = data.createdAt
    this.addedToQueue = data.addedToQueue

    return this
  }
}

export default NotificationDTO
