export default {
  nothingFound: 'Ingenting funnet',
  refineSearch: 'Avgrens søket',
  allDates: 'Alle dager',
  selectRegionAndDate: 'Velg område og dato',
  selectDate: 'Velg dato',
  selectRegion: 'Velg område',

  error: 'Feil',
  success: 'Korrekt',
  info: 'Info',
}

export const common = {
  favoriteAdd: 'Legg til favoritt',
  favoriteRemove: 'Fjern favoritt',
  sendEmailAgain: 'Send epost på nytt',
}

export const actions = {
  logout: 'Logg ut',
  enterName: 'Skriv inn brukernavn',
  enterEmail: 'Skriv inn epost',
  resendEmailVerification: 'Send instruksjoner på nytt',
  enterPassword: 'Skriv inn passord',
  enterNewPassword: 'Skriv inn nytt passord',

  save: 'Lagre',
  cancel: 'Avbryt',
  addMenuItem: 'Legg til menyelement',
  copyLink: 'Kopier link',
  generateReport: 'Generer rapport',
  select: 'Velg',
}

export const adminEdit = {
  menuEdit: 'Menyinnstillinger',
  mainNavigation: 'Hovednavigasjon',
  title: 'Tittel',
  order: 'Rekkefølge',
  type: 'Type',

  reportsGenerator: 'Rapporter Generator',
  link: 'Link',

  fpSetting: 'Forside Innstillinger',
  fpIntroText: 'Forside Introtekst',

  limit: 'Grense',
  view: 'Utsikt',
  group: 'Gruppe',
}

export const userCabinet = {
  name: 'Brukernavn',

  email: 'Epost',
  emailOrSHID: 'Epost eller SkytterID',
  enterEmailOrSHID: 'Skriv inn Epost eller SkytterID',

  signUp: 'Meld på',
  signUpToCabinet: 'Meld på',

  loginToCabinet: 'Logg inn',
  logInShooterId: 'Logg inn med SkytterID',
  logInEmail: 'Logg inn med epost',
  adminLoginToCabinet: 'Admin logg inn',

  invalidCredentials: 'Feil epost eller passord',

  password: 'Passord',
  forgotPassword: 'Glemt passord',
  resetPassword: 'Reset passord',
  resetPasswordCabinet: 'Reset passord',

  userInfo: 'Brukerinfo',

  favoriteShooters: 'Favoritt skyttere',
  profileSettings: 'Mine innstillinger',
}

export const validationMessages = {
  fieldIsRequired: 'Felt er obligatorisk',

  usernameIsRequired: 'Epost eller SkytterID er obligatorisk',
  emailIsRequired: 'Epost er obligatorisk',
  emailMustBeValid: 'Epost er på ugyldig format',
  passwordIsRequired: 'Passord er obligatorisk',
  emailIsNotUnique: 'Epost er allerede brukt',
  passwordLength: 'Passord må være minst 8 tegn',
  absentEmail: 'Ingen bruker er registrert',
  invalidToken: 'Token er ugyldig.',

  titleIsRequired: 'Tittel er obligatorisk',
  urlIsRequired: 'URL må legges inn',
  urlIsInvalid: 'URL er ugyldig',
}

export const BEValidationMessages = {
  'The email has already been taken.': validationMessages.emailIsNotUnique,
  'The password must be at least 8 characters.': validationMessages.passwordLength,
  'The given data was invalid.': userCabinet.invalidCredentials,
  "We can't find a user with that e-mail address.": validationMessages.absentEmail,
  'This password reset token is invalid.': validationMessages.invalidToken,
}

export const notifications = {
  loggedInSuccessfully: 'Innlogging vellykket',
  registeredSuccessfully: 'Registreringen var vellykket',
  somethingWentWrong: 'Noe gikk feil, prøv igjen senere.',
  emailNotVerified: 'Epost ikke verifisert, følg link i epost  og følg instruksjoner.',
  emailVerificationSent: 'Verifikasjons instruksjoner har blitt sendt på epost.',
  emailSendingInProcess: 'Sender epost',
  emailSentFailed: 'Feil med sending, prøv senere.',
  resetPasswordSuccess: 'Passord ble tilbakestilt.',
  resetWasSucceed: 'Følg instruksjonene sendt til e-posten',

  deletedSuccessfully: (type = 'Post') => `${type} ble slettet`,
  updatedSuccessfully: (type = 'Post') => `${type} ble oppdatert`,
  createdSuccessfully: (type = 'Post') => `${type} ble opprettet`,
  linkCopied: (name) => `Link til "${name}" ble kopiert`,
}
