import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import { ClubShooterTable } from '../components'
import { CLUB_SHOOTER_EVENTS_QUERY } from '../constants/queries'
import { HOME_PATH } from '../constants/routePaths'

import {
  BANE_A,
  BANE_DATE,
  BANE_FT,
  BANE_K,
  BANE_L,
  BANE_LAG,
  BANE_MB,
  BANE_MF,
  BANE_N,
  BANE_SK,
  BANE_TIME,
  FELT_DATE,
  FELT_LAG,
  FELT_SK,
  FELT_TIME,
  KLASS,
  NAME,
} from '../constants/shootersTable'
import ResponseMiddleware from '../Middleware/ResponseMiddleware'
import ClassNamesService from '../services/ClassNamesService'

let origBodyClass

const sortingCells = [
  { label: 'Klass', sortKey: KLASS },
  { label: 'Navn', sortKey: NAME },
  { label: 'Lag', sortKey: BANE_LAG },
  { label: 'Sk.', sortKey: BANE_SK },
  { label: 'Dato.', sortKey: BANE_DATE },
  { label: 'Opprop', sortKey: BANE_TIME },
  { label: 'A', sortKey: BANE_A },
  { label: 'K', sortKey: BANE_K },
  { label: 'L', sortKey: BANE_L },
  { label: 'N', sortKey: BANE_N },
  { label: 'MB', sortKey: BANE_MB },
  { label: 'MF', sortKey: BANE_MF },
  { label: 'FT', sortKey: BANE_FT },
  { label: 'Lag', sortKey: FELT_LAG },
  { label: 'Sk.', sortKey: FELT_SK },
  { label: 'Dato', sortKey: FELT_DATE },
  { label: 'Opprop', sortKey: FELT_TIME },
]

class ClubTablePage extends Component {
  componentDidMount() {
    origBodyClass = document.body.className
    document.body.className = ClassNamesService.execute([origBodyClass, 'clubtablepage'])

    this.props.onShowSearch(false)
    this.props.onShowNav(false)
  }

  componentWillUnmount() {
    document.body.className = origBodyClass
  }

  isValidClub = () => {
    const { id: clubId } = this.props.match.params
    return !!(parseInt(clubId) && clubId > 0)
  }

  // 2221109
  // 10077
  render() {
    const { id: clubId } = this.props.match.params
    const renderError = () => (
      <div className="wrapper error-wrapper">
        <p>Club# {clubId} not found 😕</p>
      </div>
    )

    const response = new ResponseMiddleware()
    response.setComponent(data =>
      data.getShooterByClub && data.getShooterByClub.length ? (
        <div className="full-page-wrapper animFadeIn">
          <h1>Resultater for klubb# {clubId}</h1>
          <ClubShooterTable shooters={data.getShooterByClub} sortingCells={sortingCells} />
        </div>
      ) : (
        renderError()
      )
    )
    response.setErrorTemplate(renderError)

    return this.isValidClub() ? (
      <Query query={CLUB_SHOOTER_EVENTS_QUERY} variables={{ clubOrganizationId: clubId }}>
        {response.execute}
      </Query>
    ) : (
      <Redirect to={HOME_PATH} />
    )
  }
}

export default ClubTablePage
