export const USER_ADD_INFO = 'USER_ADD_INFO'
export const USER_LOGOUT = 'USER_LOGOUT'

export const FAVORITES_ADD_INFO = 'FAVORITES_ADD_INFO'
export const FAVORITES_ADD_SHOOTER = 'FAVORITES_ADD_SHOOTER'
export const FAVORITES_REMOVE_SHOOTER = 'FAVORITES_REMOVE_SHOOTER'

export const REQUEST_PENDING_START = 'REQUEST_PENDING_START'
export const REQUEST_PENDING_FINISH = 'REQUEST_PENDING_FINISH'

export const MENU_ITEMS_ADD_ITEM = 'MENU_ITEMS_ADD_ITEM'

export const REPORTS_ADD_ITEM = 'REPORTS_ADD_ITEM'

export const PAGE_SETTINGS_ADD_ITEM = 'PAGE_SETTINGS_ADD_ITEM'

export const NOTIFICATIONS_ADD_ITEM = 'NOTIFICATIONS_ADD_ITEM'
export const NOTIFICATIONS_UPDATE_ITEM = 'NOTIFICATIONS_UPDATE_ITEM'
export const NOTIFICATIONS_DELETE_ITEM = 'NOTIFICATIONS_DELETE_ITEM'
