import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import FavoritesButton from '../containers/FavoritesButton'
import ImageModal from './imageModal'
import ShootingCardModal from './shootingCardModal'
import { ReactComponent as LinkOutIcon } from '../images/link_out.svg'

import ShooterImg from './shooterImg'
import ShooterResultTable from './shooterResultTable'
import { SHOOTER_PATH, CLUBTABLE_PATH } from '../constants/routePaths'
import { ktsLinksInitial, ktsLinksFinale, ktsLinksTeam } from '../constants/menuLinks'

import '../styles/shooter-info-box.scss'

const ContentShooterInfo = ({ shooterInfo, closeShooterModal }) => {
  const [state, setState] = useState({
    showModal: false,
    modalImgLink: null,
    showShootingCardModal: false,
    shootingInfo: null,
  })

  const handleOpenModal = (imgLink, label, type) => {
    const event = shooterInfo.events.find((item) => item.name === label && item.eventType === type)

    if (event && event.svgScoringCard) {
      setState({ showShootingCardModal: true, shootingInfo: { shooter: shooterInfo, event: event } })
    } else {
      setState({ showModal: true, modalImgLink: [...imgLink.map((link) => `${link}?v=${Math.random()}`)] })
    }
  }

  const handleCloseModal = () => {
    setState({ showModal: false, modalImgLink: null })
  }
  const handleCloseShootingCardModal = () => {
    setState({ showShootingCardModal: false, shootingInfo: null })
  }

  return (
    <div className="content-shooter">
      {!closeShooterModal ? (
        <div className="content-shooter__avatar">
          <ShooterImg shooterId={shooterInfo.organizationId} />
        </div>
      ) : null}
      <div className="content-shooter__box-wrap">
        <div className="content-shooter__top">
          <div className="content-shooter__left">
            <div className="content-shooter__idname">
              <div className="content-shooter__id">ID: {shooterInfo.organizationId}</div>
              <div className="content-shooter__name">
                {shooterInfo.name}
                {closeShooterModal ? (
                  <a
                    href={`${SHOOTER_PATH}${shooterInfo.organizationId}`}
                    onClick={() => {
                      closeShooterModal()
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkOutIcon />
                  </a>
                ) : null}
                <a href={`https://mittdfs.no/hovedside/verdt-a-vite/it-verktoy/ls-statistikk/?actorid=${shooterInfo.organizationId}`} target="_blank" rel="noopener noreferrer">Se statistikk</a>
              </div>
            </div>

            <div className="content-shooter__club">{shooterInfo.clubName}</div>
            <div className="badges">
              {shooterInfo.categories && shooterInfo.categories.length
                ? shooterInfo.categories.map((cat) => (
                    <span className="badge bold-badge" key={cat}>
                      {cat}
                    </span>
                  ))
                : null}
            </div>
          </div>
          {closeShooterModal ? (
            <div className="content-shooter__avatar">
              <ShooterImg shooterId={shooterInfo.organizationId} />
            </div>
          ) : null}
        </div>

        <div className="content-shooter__tablegraphics">
          <div className="content-shooter__table">
            <ShooterResultTable shooterInfo={shooterInfo} />
          </div>

          <div className="content-shooter__graphics">
            <ImageModal
              modalImgLink={state.modalImgLink}
              showModal={state.showModal}
              handleCloseModal={handleCloseModal}
            />
            <ShootingCardModal
              shootingInfo={state.shootingInfo}
              showModal={state.showShootingCardModal}
              handleCloseModal={handleCloseShootingCardModal}
            />

            <div className="content-shooter__graphics-heading">Grafiske resultater</div>
            <div className="content-shooter__graphics-row">
              <div className="content-shooter__graphics-subheading">Skytekort innledende</div>
              {ktsLinksInitial(shooterInfo.organizationId).map((link) => (
                <button key={link.to} className="btn" onClick={() => handleOpenModal(link.to, link.label, 'MAIN')}>
                  {link.label}
                </button>
              ))}
            </div>
            <div className="content-shooter__graphics-row">
              <div className="content-shooter__graphics-subheading">Skytekort finale</div>
              {ktsLinksFinale(shooterInfo.organizationId).map((link) => (
                <button key={link.to} className="btn" onClick={() => handleOpenModal(link.to, link.label, 'FINALE')}>
                  {link.label}
                </button>
              ))}
            </div>
            <div className="content-shooter__graphics-row">
              <div className="content-shooter__graphics-subheading">Skytekort lagskyting</div>
              {ktsLinksTeam(shooterInfo.organizationId).map((link) => (
                <button key={link.to} className="btn" onClick={() => handleOpenModal(link.to, link.label, 'MAIN')}>
                  {link.label}
                </button>
              ))}
            </div>
          </div>
        </div>

        <div className="content-shooter__bottom">
          <Link
            to={`${CLUBTABLE_PATH}${shooterInfo.clubOrganizationId}`}
            target="_blank"
            onClick={() => (closeShooterModal ? closeShooterModal() : false)}
            className="content-shooter__full-button"
          >
            se alle påmeldte fra skytterlaget
          </Link>
          <FavoritesButton shooterId={shooterInfo.organizationId} className="btn--is-full-width" />
        </div>
      </div>
    </div>
  )
}
// }

export default ContentShooterInfo
