class ReportVO {
  instance

  constructor(instance) {
    this.instance = instance
  }

  /**
   *
   * @param id
   * @return {Promise<void>}
   */
  get = async id => {
    const response = await this.instance.get(`/reports/${id}`)

    return response.data
  }

  /**
   *
   * @param edition
   * @return {Promise<*>}
   */
  items = async edition => {
    const query = {
      edition,
    }

    const response = await this.instance.get('/reports', {
      params: query,
    })

    return response.data
  }

  /**
   *
   * @param {ReportDTO} data
   * @param edition
   * @return {Promise<void>}
   */
  create = async (data, edition) => {
    const response = await this.instance.post('/reports', {
      name: data.name,
      url: data.url,
      edition: edition,
      order: data.order || 0,
      font_size: data.fontSize,
    })

    return response.data
  }

  /**
   *
   * @param {ReportDTO} data
   * @param edition
   * @return {Promise<*>}
   */
  update = async (data, edition) => {
    const query = {
      name: data.name,
      url: data.url,
      edition: edition,
      order: data.order || 0,
      font_size: data.fontSize,
    }

    const response = await this.instance.put(`/reports/${data.id}`, query)

    return response.data
  }

  /**
   *
   * @param id
   * @return {Promise<*>}
   */
  delete = async id => {
    const response = await this.instance.delete(`/reports/${id}`)

    return response.data
  }
}

export default ReportVO
