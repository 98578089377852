import React, { PureComponent } from 'react'
import queryString from 'query-string'
import Messages from '../constants/messages'

import { Query } from 'react-apollo'
import { SEARCH_SHOOTER } from '../constants/queries'

import { SearchResultShooter } from '../containers'

import '../styles/search-page.scss'
import ResponseMiddleware from '../Middleware/ResponseMiddleware'

let origBodyClass

class SearchPage extends PureComponent {
  // state = {
  //   querySearch: queryString.parse(this.props.location.search)
  // };

  componentDidMount() {
    origBodyClass = document.body.className
    document.body.className = origBodyClass + (origBodyClass ? ' ' : '') + 'searchpage'

    this.props.onShowSearch(true)
    this.props.onShowNav(false)
  }
  componentWillUnmount() {
    document.body.className = origBodyClass
  }

  render() {
    const querySearch = queryString.parse(this.props.location.search)
    const response = new ResponseMiddleware()
    response.setComponent(data => (
      <>
        <div className="animFadeIn search-results__wrapper wrapper">
          {!data.searchShooter || !data.searchShooter.length ? (
            <p>{Messages.nothingFound}</p>
          ) : (
            <>
              <div className="search-results__th">
                <div className="sr__col-1">Navn</div>
                <div className="sr__col-2">Skytterlag</div>
                <div className="sr__col-3">Klasse</div>
                <div className="sr__col-4" />
              </div>
              <div className="search-results__table">
                {data.searchShooter.map(shooter => (
                  <SearchResultShooter key={`${shooter.name}${shooter.clubOrganizationId}`} {...shooter} />
                ))}
              </div>
            </>
          )}
        </div>
      </>
    ))

    return (
      !!querySearch.s && (
        <Query query={SEARCH_SHOOTER} variables={{ name: querySearch.s }}>
          {response.execute}
        </Query>
      )
    )
  }
}

export default SearchPage
