import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import * as Yup from 'yup'
import messages, { actions, notifications } from '../constants/messages'
import { BEValidationMessages, userCabinet, validationMessages } from '../constants/messages'
import { USER_CABINET_LOGIN } from '../constants/routePaths'
import NotificationDTO from '../DTO/NotificationDTO'
import { notificationsEntity } from '../entities/NotificationsEntity'
import UserEntity from '../entities/UserEntity'
import WithBootstrap from '../HOC/WithBootstrap'
import { requestPendingFinish, requestPendingStart, requestTypes } from '../redux/actions/RequestPending'
import { userAddUserInfo } from '../redux/actions/User'
import ClassNamesService from '../services/ClassNamesService'
import Http from '../services/Http'
import BackRedirect from './BackRedirect'

const mapStateToProps = state => ({
  User: state.User,
  RequestPending: state.RequestPending,
})

const mapDispatchToProps = dispatch => ({
  addUserInfo: user => dispatch(userAddUserInfo(user)),

  requestPendingStart: type => dispatch(requestPendingStart(type)),
  requestPendingFinish: type => dispatch(requestPendingFinish(type)),
})

class SignUp extends Component {
  static propTypes = {
    addUserInfo: PropTypes.func,
    requestPendingStart: PropTypes.func,
    requestPendingFinish: PropTypes.func,
  }

  static defaultProps = {}

  user = new UserEntity()

  loginRoute = USER_CABINET_LOGIN
  schema = Yup.object({
    email: Yup.string()
      .email(validationMessages.emailMustBeValid)
      .required(validationMessages.emailIsRequired),
    name: Yup.string().required(validationMessages.emailIsRequired),
    password: Yup.string().required(validationMessages.passwordIsRequired),
  })

  BEFields = {
    email: userCabinet.email,
    password: userCabinet.password,
    name: userCabinet.name,
  }

  get isLoading() {
    return BackRedirect.resolveIsLoading(this.props.RequestPending)
  }

  handleResponseErrors = response => {
    if (!response.hasOwnProperty('errors')) {
      notificationsEntity.addNotification(
        new NotificationDTO(null, messages.error, notifications.somethingWentWrong, NotificationDTO.types.error)
      )

      return
    }

    Object.keys(response.errors).forEach(key => {
      const field = response.errors[key]

      const notificationName = this.BEFields[key] || key
      const texts = field.map(text => BEValidationMessages[text] || text)

      notificationsEntity.addNotification(
        new NotificationDTO(null, notificationName, texts.join('\n'), NotificationDTO.types.error)
      )
    })
  }

  handleSignUpClick = async (values, { resetForm }) => {
    this.props.requestPendingStart(requestTypes.LOGIN)

    try {
      await Http.endpoints.user.signUp(values.name, values.email, values.password)

      notificationsEntity.addNotification(
        new NotificationDTO(
          null,
          messages.success,
          [notifications.registeredSuccessfully, notifications.emailVerificationSent].join('\n'),
          NotificationDTO.types.success
        )
      )

      await this.user.logout()

      resetForm()
    } catch (e) {
      this.handleResponseErrors(e.response.data)
    }

    this.props.requestPendingFinish(requestTypes.LOGIN)
  }

  render() {
    return (
      <WithBootstrap>
        <section className="d-flex align-items-center justify-content-center w-100 mt-auto mb-auto">
          <div
            className="d-flex flex-column"
            style={{
              width: '400px',
              maxWidth: '90vw',
            }}
          >
            <h3 className="mb-4">{userCabinet.signUpToCabinet}</h3>
            <Formik
              initialValues={{
                name: '',
                email: '',
                password: '',
              }}
              validationSchema={this.schema}
              onSubmit={this.handleSignUpClick}
            >
              {props => {
                const { handleSubmit, handleChange, touched, errors, values } = props

                return (
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      handleSubmit(e)
                    }}
                    noValidate
                  >
                    <Form.Group>
                      <Form.Label>{userCabinet.name}</Form.Label>
                      <Form.Control
                        type="name"
                        placeholder={actions.enterName}
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={!!errors.name && touched.name}
                        name="name"
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className={ClassNamesService.execute([touched.name && 'd-flex'])}
                      >
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>{userCabinet.email}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={actions.enterEmail}
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email && touched.email}
                        name="email"
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className={ClassNamesService.execute([touched.email && 'd-flex'])}
                      >
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>{userCabinet.password}</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={actions.enterPassword}
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password && touched.password}
                        name="password"
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className={ClassNamesService.execute([touched.password && 'd-flex'])}
                      >
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <div className="d-flex mb-3">
                      <Button variant="primary" className="w-100" disabled={this.isLoading} type="submit">
                        {userCabinet.signUp}
                      </Button>
                    </div>
                    {this.loginRoute && (
                      <div className="d-flex mb-3 justify-content-center">
                        <NavLink
                          className={ClassNamesService.execute([this.isLoading && 'text-secondary'])}
                          to={this.loginRoute}
                          onClick={this.handleLinkClick}
                        >
                          {userCabinet.loginToCabinet}
                        </NavLink>
                      </div>
                    )}
                  </Form>
                )
              }}
            </Formik>
          </div>
        </section>
      </WithBootstrap>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp)
