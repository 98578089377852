import PropTypes from 'prop-types'
import React, { Component } from 'react'

import TableComponent from '../ShootersTable'
import DefaultTable from '../../components/RangedBane/DefaultTable'
import Sk15Table from '../../components/RangedBane/Sk15Table'
import Sk25Table from '../../components/RangedBane/Sk25Table'
import Sk35Table from '../../components/RangedBane/Sk35Table'

class ShootersTable extends Component {
  static propTypes = {
    organizationId: PropTypes.string,
  }

  componentsTree = {
    default: DefaultTable,
    BA: {
      default: DefaultTable,
      BA_1To3: Sk15Table,
      BA_1To4: Sk25Table,
      BA_1To5: Sk35Table,
    },
  }

  render() {
    const { organizationId } = this.props

    return <TableComponent organizationId={organizationId} componentsTree={this.componentsTree} />
  }
}

export default ShootersTable
