import NotificationDTO from '../DTO/NotificationDTO'
import { notificationsAddItem, notificationsDeleteItem, notificationsUpdateItem } from '../redux/actions/Notifications'
import storeObject from '../redux/store'

class NotificationsEntity {
  /**
   *
   * @type {number}
   * @private
   */
  _maxItems
  /**
   *
   * @type {boolean}
   * @private
   */
  _isCleanupRunning = false

  /**
   *
   * @param {NotificationDTO} notification
   * @return {boolean}
   */
  static isValidNotification = notification => {
    if (notification.delay === null || !notification.addedToQueue) {
      return true
    }

    const date = new Date().valueOf()

    return date < notification.createdAt + notification.delay
  }

  constructor(maxItems = null) {
    this._maxItems = maxItems || 3
  }

  /**
   *
   * @return {{items: object, ids: string[]|number[]}}
   */
  get state() {
    return storeObject.getState().Notifications
  }

  /**
   *
   * @return {number}
   */
  get maxItems() {
    return this._maxItems
  }

  /**
   * @param state
   * @return {NotificationDTO[]}
   */
  notificationsToDisplay = (state = this.state) => {
    const items = []

    state.ids.forEach(id => {
      if (items.length === this.maxItems) {
        return
      }

      const item = state.items[id]

      if (!item.addedToQueue) {
        return
      }

      items.push(item)
    })

    return items
  }

  /**
   *
   * @param {number} amount
   * @return {NotificationDTO[]}
   * @private
   */
  _candidatesToDisplay = amount => {
    const items = []

    this.state.ids.forEach(id => {
      if (items.length === amount) {
        return
      }

      const item = this.state.items[id]

      if (item.addedToQueue) {
        return
      }

      items.push(item)
    })

    return items
  }

  /**
   *
   * @return {NotificationsEntity}
   * @private
   */
  _processNextNotifications = () => {
    const availableAmount = this.maxItems - this.notificationsToDisplay().length

    if (!availableAmount) {
      return this
    }

    const candidates = this._candidatesToDisplay(availableAmount)

    candidates.forEach(candidate => this.displayNotification(candidate.id))

    return this
  }

  /**
   *
   * @private
   */
  _removeExpiredNotifications = () => {
    const items = this.notificationsToDisplay()

    if (!items.length) {
      this._isCleanupRunning = false

      return
    }

    this._isCleanupRunning = true

    items.forEach(item => {
      if (NotificationsEntity.isValidNotification(item)) {
        return
      }

      this.removeNotification(item.id)
    })

    this._processNextNotifications()

    setTimeout(() => {
      this._removeExpiredNotifications()
    }, 300)
  }

  /**
   *
   * @param {NotificationDTO} notification
   * @return {NotificationsEntity}
   */
  addNotification = notification => {
    storeObject.dispatch(notificationsAddItem(notification))

    this._processNextNotifications()

    if (!this._isCleanupRunning) {
      this._removeExpiredNotifications()
    }

    return this
  }

  /**
   *
   * @param {string|number} id
   * @return {NotificationsEntity}
   */
  removeNotification = id => {
    storeObject.dispatch(notificationsDeleteItem(new NotificationDTO(id)))

    return this
  }

  /**
   *
   * @param id
   * @return {NotificationsEntity}
   */
  displayNotification = id => {
    const data = this.state.items[id]

    if (!data) {
      return this
    }

    const instance = new NotificationDTO(null).fromStore(data).addToQueue()

    storeObject.dispatch(notificationsUpdateItem(instance))

    return this
  }
}

// export default NotificationsEntity

export const notificationsEntity = new NotificationsEntity(5)
