import React from 'react'
import PropTypes from 'prop-types'

import ClassNamesService from '../../services/ClassNamesService'

const RadioOption = ({ label, slug, isSelected, selectLink, className }) => (
  <li
    className={ClassNamesService.execute([
      'filter__item',
      'filter__item--is-radio',
      isSelected && 'filter__item--is-selected',
      className,
    ])}
    onClick={() => selectLink(slug)}
  >
    <span
      className={ClassNamesService.execute(['filter__item-title', isSelected && 'filter__item-title--is-selected'])}
    >
      {label}
    </span>

    <span
      className={ClassNamesService.execute([
        'filter__item-checkbox',
        isSelected && 'filter__item-checkbox--is-selected',
      ])}
    />
  </li>
)

RadioOption.propTypes = {
  label: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  selectLink: PropTypes.func,
  isSelected: PropTypes.bool,
  className: PropTypes.oneOfType([() => null, PropTypes.string]),
}

RadioOption.defaultProps = {
  selectLink: () => {},
  isSelected: false,
  className: null,
}

export default RadioOption
