import React from 'react'
import { Mutation } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import { OPEN_SHOOTER_MODAL_MUTATION } from '../constants/mutations'
import { SHOOTER_PATH } from '../constants/routePaths'
import { isMobile } from '../services/screenService'

const OpenShooterModal = ({ children, history }) => (
  <>
    <Mutation mutation={OPEN_SHOOTER_MODAL_MUTATION}>
      {openModal => {
        const onOpen = options => {
          options = {
            from: 0,
            to: 10000,
            ...options,
          }

          const { shooterId, from, to } = options

          if (!(window.innerWidth > from && window.innerWidth < to)) {
            return
          }

          isMobile() ? history.push(SHOOTER_PATH + shooterId) : openModal({ variables: { shooterModalId: shooterId } })
        }

        return <>{React.cloneElement(children({ onOpen }))}</>
      }}
    </Mutation>
  </>
)

export default withRouter(OpenShooterModal)
