import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { connect } from 'react-redux'
import QueryString from 'query-string'
import { Redirect, withRouter } from 'react-router-dom'
import * as Yup from 'yup'
import messages, { actions, BEValidationMessages, notifications } from '../constants/messages'
import { userCabinet, validationMessages } from '../constants/messages'
import { USER_CABINET_INFO } from '../constants/routePaths'
import NotificationDTO from '../DTO/NotificationDTO'
import { notificationsEntity } from '../entities/NotificationsEntity'
import UserEntity from '../entities/UserEntity'
import WithBootstrap from '../HOC/WithBootstrap'
import { FavoritesAddInfo } from '../redux/actions/Favorites'
import { requestPendingFinish, requestPendingStart, requestTypes } from '../redux/actions/RequestPending'
import { UserAddUserInfo } from '../redux/actions/User'
import ClassNamesService from '../services/ClassNamesService'
import Http from '../services/Http'

const mapStateToProps = state => ({
  User: state.User,
  RequestPending: state.RequestPending,
})

const mapDispatchToProps = dispatch => ({
  requestPendingStart: type => dispatch(requestPendingStart(type)),
  requestPendingFinish: type => dispatch(requestPendingFinish(type)),
  UserAddUserInfo: () => dispatch(UserAddUserInfo()),
  FavoritesAddInfo: () => dispatch(FavoritesAddInfo()),
})

class ResetPassword extends Component {
  static propTypes = {
    User: PropTypes.object,
    RequestPending: PropTypes.object,

    requestPendingStart: PropTypes.func,
    requestPendingFinish: PropTypes.func,
    UserAddUserInfo: PropTypes.func,
    FavoritesAddInfo: PropTypes.func,
  }

  user = new UserEntity()

  schema = Yup.object({
    password: Yup.string().required(validationMessages.passwordIsRequired),
  })

  BEFields = {
    password: userCabinet.password,
    name: userCabinet.name,
  }

  /**
   *
   * @return {{email: string, token: string}}
   */
  get parameters() {
    const search = QueryString.parse(this.props.location.search.slice(1))

    return {
      token: this.props.match.params.token,
      email: search.email,
    }
  }

  get isLoading() {
    return this.props.RequestPending[requestTypes.FAVORITE] || this.props.RequestPending[requestTypes.LOGIN]
  }

  resolveErrorResponse = data => {
    if (!data.hasOwnProperty('errors') && !data.hasOwnProperty('message')) {
      notificationsEntity.addNotification(
        new NotificationDTO(null, messages.error, notifications.somethingWentWrong, NotificationDTO.types.error)
      )

      return
    }

    if (!data.hasOwnProperty('errors')) {
      const message = BEValidationMessages[data.message] || data.message

      notificationsEntity.addNotification(
        new NotificationDTO(null, messages.error, message, NotificationDTO.types.error)
      )

      return
    }

    Object.keys(data.errors).forEach(key => {
      const field = data.errors[key]

      const notificationName = this.BEFields[key] || key
      const texts = field.map(text => BEValidationMessages[text] || text)

      notificationsEntity.addNotification(
        new NotificationDTO(null, notificationName, texts.join('\n'), NotificationDTO.types.error)
      )
    })
  }

  handleResetPasswordClick = async values => {
    const { email, token } = this.parameters

    this.props.requestPendingStart(requestTypes.LOGIN)

    try {
      await Http.endpoints.user.sendResetPassword(token, email, values.password)
      await this.props.UserAddUserInfo()
      await this.props.FavoritesAddInfo()
    } catch (e) {
      console.log(e)
      this.resolveErrorResponse(e.response.data)
    }

    this.props.requestPendingFinish(requestTypes.LOGIN)
  }

  render() {
    return this.user.isAuthenticated ? (
      <Redirect to={USER_CABINET_INFO} />
    ) : (
      <WithBootstrap>
        <section className="d-flex align-items-center justify-content-center w-100 mt-auto mb-auto">
          <div
            className="d-flex flex-column"
            style={{
              width: '400px',
              maxWidth: '90vw',
            }}
          >
            <h3 className="mb-4">{actions.enterNewPassword}</h3>
            <Formik
              initialValues={{
                password: '',
              }}
              validationSchema={this.schema}
              onSubmit={this.handleResetPasswordClick}
            >
              {props => {
                const { handleSubmit, handleChange, touched, errors, values } = props

                return (
                  <Form
                    onSubmit={e => {
                      e.preventDefault()
                      handleSubmit(e)
                    }}
                    noValidate
                  >
                    <Form.Group>
                      <Form.Label>{userCabinet.password}</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={actions.enterNewPassword}
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password && touched.password}
                        name="password"
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className={ClassNamesService.execute([touched.password && 'd-flex'])}
                      >
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <div className="d-flex mb-3">
                      <Button variant="primary" className="w-100" disabled={this.isLoading} type="submit">
                        {userCabinet.resetPassword}
                      </Button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </section>
      </WithBootstrap>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResetPassword)
)
