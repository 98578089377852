import { REPORT_PATH } from '../constants/routePaths'
import { ReportsListInstance as RBInstance } from '../containers/RangedBane/Sidebar'
import { ReportsListInstance as RFInstance } from '../containers/RangedField/Sidebar'
import SelectItemDTO from '../DTO/SelectItemDTO'
import QueryString from 'query-string'

class AdminReportItemVO {
  static categories = {
    rb: 'Rangert Bane',
    rf: 'Rangert Felt',
  }

  static instances = {
    rb: RBInstance,
    rf: RFInstance,
  }

  static limits = {
    10: '10',
    15: '15',
    16: '16',
  }

  static views = {
    default: 'Standard',
    'cycle-three-items': 'Vis tre elementer hver',
  }

  static keys = {
    category: 'category',
    type: 'type',
    group: 'group',
    classType: 'class-type',
    limit: 'limit',
    view: 'view',
  }

  category
  type
  limit
  group
  classType
  view
  groupFilters = {}

  get categoryValues() {
    return Object.keys(AdminReportItemVO.categories).map(
      (id) => new SelectItemDTO(AdminReportItemVO.categories[id], id)
    )
  }

  get limitValues() {
    return Object.keys(AdminReportItemVO.limits).map((key) => new SelectItemDTO(AdminReportItemVO.limits[key], key))
  }

  get viewValues() {
    return Object.keys(AdminReportItemVO.views).map((key) => new SelectItemDTO(AdminReportItemVO.views[key], key))
  }

  /**
   *
   * @return {string}
   */
  get urlQuery() {
    const object = {
      [AdminReportItemVO.keys.category]: this.category,
      [AdminReportItemVO.keys.type]: this.type,
      [AdminReportItemVO.keys.limit]: this.limit,
      [AdminReportItemVO.keys.group]: this.group,
      [AdminReportItemVO.keys.classType]: this.classType,
      [AdminReportItemVO.keys.view]: this.view,
    }

    return REPORT_PATH + '?' + QueryString.stringify(object)
  }

  /**
   *
   * @param url
   * @return {AdminReportItemVO}
   */
  decodeQuery = (url) => {
    const [, search] = url.split('?')
    const object = QueryString.parse(search)

    this.category = object[AdminReportItemVO.keys.category]
    this.type = object[AdminReportItemVO.keys.type]
    this.limit = AdminReportItemVO.limits.hasOwnProperty(object[AdminReportItemVO.keys.limit])
      ? object[AdminReportItemVO.keys.limit]
      : AdminReportItemVO.limits['10']
    this.group = object[AdminReportItemVO.keys.group]
    this.classType = object[AdminReportItemVO.keys.classType]
    this.view = AdminReportItemVO.views.hasOwnProperty(object[AdminReportItemVO.keys.view])
      ? object[AdminReportItemVO.keys.view]
      : AdminReportItemVO.views['default']

    return this
  }

  /**
   *
   * @param value
   * @return {SelectItemDTO[]|null}
   */
  handleCategoryChange = (value) => {
    if (!value) {
      this.category = null

      this.handleTypeChange()

      return null
    }

    if (!AdminReportItemVO.instances.hasOwnProperty(value)) {
      return null
    }

    this.category = value

    return this.mapTypeVariants(value)
  }

  /**
   *
   * @param value
   * @return {null|SelectItemDTO[]}
   */
  mapTypeVariants = (value) => {
    if (!value) {
      return null
    }

    if (!AdminReportItemVO.instances.hasOwnProperty(value)) {
      return null
    }

    return AdminReportItemVO.instances[value].categories.map((item) => new SelectItemDTO(item.name, item.id))
  }

  /**
   *
   * @param value
   * @return {SelectItemDTO[]|null}
   */
  handleTypeChange = (value) => {
    if (!value) {
      this.type = null

      this.handleGroupChange()

      return null
    }

    const variants = this.mapGroupVariants(value)

    if (!variants) {
      return null
    }

    this.type = value

    return variants
  }

  /**
   *
   * @param {string} value
   * @return {*}
   */
  handleLimitChange = (value) => {
    if (!AdminReportItemVO.limits.hasOwnProperty(value)) {
      return
    }

    this.limit = value

    return this
  }
  handleViewChange = (value) => {
    if (!AdminReportItemVO.views.hasOwnProperty(value)) {
      return
    }

    this.view = value

    return this
  }

  /**
   *
   * @param value
   * @return {null|SelectItemDTO[]}
   */
  mapGroupVariants = (value) => {
    const instance = AdminReportItemVO.instances[this.category]

    if (!instance) {
      return null
    }

    const codeValue = instance.codes[value]

    if (!codeValue) {
      return null
    }

    return Object.values(codeValue).map((item) => new SelectItemDTO(item.value, item.id))
  }

  /**
   *
   * @param value
   * @return {SelectItemDTO[]|null}
   */
  handleGroupChange = (value) => {
    if (!value && this.type !== 'PS') {
      this.group = null

      this.handleClassTypeChange()

      return null
    }

    this.group = value

    if (!this.groupFilters.hasOwnProperty(value)) {
      return null
    }

    return this.groupFilters[value]
  }

  /**
   *
   * @param value
   * @return {AdminReportItemVO}
   */
  handleClassTypeChange = (value) => {
    if (!value) {
      this.classType = null

      return this
    }

    this.classType = value

    return this
  }

  /**
   *
   * @param {Object[]} response
   * @param {Boolean} response.webEnabled
   * @param {String} response.organizationGroup2Id
   * @param {String} response.organizationId
   * @param {String} response.nameShort
   * @param {null|Number} response.sortOrder
   * @return {AdminReportItemVO}
   */
  handleGroupFilters = (response) => {
    const groups = {}

    response.forEach((group) => {
      if (!group.webEnabled) {
        return
      }

      if (!groups.hasOwnProperty(group.organizationGroup2Id)) {
        groups[group.organizationGroup2Id] = []
      }

      groups[group.organizationGroup2Id].push(new SelectItemDTO(group.nameShort, group.organizationId))
    })

    this.groupFilters = groups

    return this
  }
}

export default AdminReportItemVO
