import React from 'react'
import PropTypes from 'prop-types'
import ClassNamesService from '../../../services/ClassNamesService'

import TotalScoreCell from './TotalScoreCell'
import ScoreCell from './ScoreCell'

const TotalStarCell = ({ score, star, width = null, isAlignedEnd = false, className = null }) => (
  <div
    className={ClassNamesService.execute([
      'cell cell--is-total-star',
      className,
      isAlignedEnd && 'cell--is--aligned-end',
    ])}
    style={{
      width,
    }}
  >
    <TotalScoreCell>{score}</TotalScoreCell>
    <ScoreCell className="cell--is-aligned-start" width="100px">
      {star}
    </ScoreCell>
  </div>
)

TotalStarCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, () => null]),
  isAlignedEnd: PropTypes.bool,
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  star: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
}

export default TotalStarCell
