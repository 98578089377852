import PropTypes from 'prop-types'
import React, { Component } from 'react'

import TableComponent from '../LagvisListTable'
import LagvisListLiveTable from '../LagvisListLiveTable'
import DefaultTable from '../../components/InitialLagvisList/DefaultTable'
import Lagvis100Table from '../../components/InitialLagvisList/Lagvis100Table'
import Lagvis200Table from '../../components/InitialLagvisList/Lagvis200Table'
import Finfelt from '../../components/InitialLagvisList/Finfelt'

class ShootersTable extends Component {
  static propTypes = {
    organizationId: PropTypes.string,
  }

  componentsTree = {
    default: DefaultTable,
    'Lagvis Finfelt': {
      default: Finfelt,
    },
    'Lagvis 100m': {
      default: Lagvis100Table,
    },
    'Lagvis 200m': {
      default: Lagvis200Table,
    },
  }

  render() {
    const { organizationId, number, type } = this.props

    const isLive = type.includes('live')

    return (
      <>
        {isLive ? (
          <LagvisListLiveTable organizationId={organizationId} />
        ) : (
          <TableComponent
            organizationId={organizationId}
            organizationGroup1Id={type}
            number={number}
            componentsTree={this.componentsTree}
          />
        )}
      </>
    )
  }
}

export default ShootersTable
