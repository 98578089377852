import PropTypes from 'prop-types'
import React, { Component } from 'react'

class DropDownHOC extends Component {
  state = {
    isOpened: false,
    wrapperId: 'shadow-wrapper-' + `${Math.random()}`.slice(2),
  }

  static defaultProps = {
    isDisabled: false,
  }

  static propTypes = {
    isDisabled: PropTypes.bool,
  }

  openWrapper = () => {
    if (this.props.isDisabled) {
      return
    }

    this.setState({
      isOpened: true,
    })
  }

  closeWrapper = () => {
    if (this.props.isDisabled) {
      return
    }

    this.setState({
      isOpened: false,
    })
  }

  toggleWrapper = () => {
    if (this.props.isDisabled) {
      return
    }

    this.setState({
      isOpened: !this.state.isOpened,
    })
  }

  bindOutsideClick = () => {
    if (this.props.isDisabled) {
      return
    }

    const handleOutsideClick = e => {
      if (e.target.closest(`#${this.state.wrapperId}`)) {
        return
      }

      this.closeWrapper()

      document.body.removeEventListener('click', handleOutsideClick)
    }

    document.body.addEventListener('click', handleOutsideClick)
  }

  render() {
    const { wrapperId, isOpened } = this.state

    return React.cloneElement(this.props.children, {
      openWrapper: this.openWrapper,
      closeWrapper: this.closeWrapper,
      toggleWrapper: this.toggleWrapper,
      bindOutsideClick: this.bindOutsideClick,
      wrapperId,
      isWrapperOpened: isOpened,
    })
  }
}

export default DropDownHOC
