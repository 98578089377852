import React from 'react'
import PropTypes from 'prop-types'

import SelectedLink from '../../entities/SelectedLink'
import CollapsibleCategory from './CollapsibleCategory'
import RadioOption from './RadioOption'

const selectedLinkGenerator = (slug, selectedLink) => linkSlug => {
  const linkEntity = new SelectedLink()
  linkEntity.setLinkAsParameters(slug, linkSlug)

  return linkEntity.linkString === selectedLink
}

const Category = ({
  slug,
  children,
  label,
  isOpened,
  selectedLink,
  toggleCategory,
  selectLink,
  defaultComponent,
  className,
}) => {
  const isLinkSelected = selectedLinkGenerator(slug, selectedLink)
  const Component = defaultComponent

  return (
    <CollapsibleCategory label={label} slug={slug} isOpened={isOpened} toggleCategory={toggleCategory}>
      {isOpened &&
        children.map(option => (
          <Component
            {...option}
            key={option.slug}
            className={className}
            selectLink={link => selectLink(slug, link)}
            isSelected={isLinkSelected(option.slug)}
          />
        ))}
    </CollapsibleCategory>
  )
}

Category.propTypes = {
  label: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  className: PropTypes.oneOfType([() => null, PropTypes.string]),
  children: PropTypes.arrayOf(PropTypes.shape({})),
  selectedLink: PropTypes.string.isRequired,
  toggleCategory: PropTypes.func,
  selectLink: PropTypes.func,
  isOpened: PropTypes.bool,
  defaultComponent: PropTypes.elementType,
}

Category.defaultProps = {
  isOpened: false,
  selectLink: () => {},
  toggleCategory: () => {},
  defaultComponent: RadioOption,
  className: null,
}

export default Category
