import ActionDTO from '../../DTO/ActionDTO'
import Http from '../../services/Http'
import { USER_ADD_INFO, USER_LOGOUT } from '../ActionTypes'

export const userAddUserInfo = payload => new ActionDTO(USER_ADD_INFO, payload).valueOf
export const userLogout = () => new ActionDTO(USER_LOGOUT).valueOf

export const UserAddUserInfo = () => async dispatch => {
  try {
    const user = await Http.endpoints.user.info()
    dispatch(userAddUserInfo(user))
  } catch (e) {
    throw new Error(e)
  }
}

export const AdminAddUserInfo = () => async dispatch => {
  try {
    const user = await Http.endpoints.admin.info()
    dispatch(userAddUserInfo(user))
  } catch (e) {
    throw new Error(e)
  }
}
