import PropTypes from 'prop-types'
import qs from 'query-string'
import React, { Component } from 'react'

import '../styles/sidebar-select-box.scss'
import { withRouter } from 'react-router-dom'
import ClassNamesService from '../services/ClassNamesService'
import ResultsList from '../views/ResultsList'
import { ReactComponent as Caret } from '../images/caret-icon.svg'
import SelectLabel from '../views/SelectLabel'

const stringVariables = {
  SelectBoxType: 'SelectBoxType',
}

class SidebarSelectBox extends Component {
  state = {
    selectedVariants: {},
  }

  static defaultProps = {
    placeholder: 'Velg Klasse',
    stringKey: 'sidebar-select-class',
    onSelect: () => {},
    isDisabled: false,
    variants: [],
  }

  static propTypes = {
    placeholder: PropTypes.string,
    stringKey: PropTypes.string,
    onSelect: PropTypes.func,
    isDisabled: PropTypes.bool,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
      })
    ),
  }

  get selectedVariants() {
    return Object.values(this.state.selectedVariants)
  }

  componentDidMount() {
    const search = JSON.parse(JSON.stringify(qs.parse(window.location.search)))

    if (!search.hasOwnProperty(this.props.stringKey)) {
      return
    }

    const stringVariants = search[this.props.stringKey].split(',')
    const selectedVariants = {}
    const propsVariants = {}
    this.props.variants.forEach(variant => {
      propsVariants[variant.id] = {
        __typename: stringVariables.SelectBoxType,
        ...variant,
      }
    })

    stringVariants.forEach(id => {
      propsVariants.hasOwnProperty(id) && (selectedVariants[id] = propsVariants[id])
    })

    this.setState({
      selectedVariants,
    })

    this.props.onSelect(Object.values(selectedVariants))
  }

  resolveNextVariants = variant => {
    const variantsCopy = JSON.parse(JSON.stringify(this.state.selectedVariants))

    if (variantsCopy.hasOwnProperty(variant.id)) {
      delete variantsCopy[variant.id]

      return variantsCopy
    }

    variantsCopy[variant.id] = {
      __typename: stringVariables.SelectBoxType,
      ...variant,
    }

    return variantsCopy
  }

  handleVariantSelect = variant => {
    if (this.props.isDisabled) {
      return
    }

    const variantsCopy = this.resolveNextVariants(variant)
    const previousSearch = JSON.parse(JSON.stringify(qs.parse(this.props.location.search)))

    this.props.history.push({
      search: qs.stringify({
        ...previousSearch,
        [this.props.stringKey]: Object.keys(variantsCopy).join(','),
      }),
    })

    this.props.onSelect(Object.values(variantsCopy))

    this.setState({
      selectedVariants: variantsCopy,
    })
  }

  handleVariantHighlighted = variant => {
    return this.state.selectedVariants.hasOwnProperty(variant.id)
  }

  render() {
    const { isWrapperOpened, placeholder, toggleWrapper, variants } = this.props

    return (
      <>
        <div className="sidebar-select">
          <span
            onClick={toggleWrapper}
            className={ClassNamesService.execute([
              'sidebar-select__label',
              isWrapperOpened && 'sidebar-select__label--is-opened',
            ])}
          >
            {placeholder} <Caret className="sidebar-select__label-icon" />
          </span>
          <ResultsList
            variantsList={variants}
            handleVariantHighlight={this.handleVariantHighlighted}
            onSelect={this.handleVariantSelect}
            isVisible={isWrapperOpened}
            className="results-list__wrapper--is-sidebar-select"
          />
        </div>
        <div className="sidebar-select__labels">
          {this.selectedVariants.map(variant => (
            <SelectLabel {...variant} key={variant.id} handleClick={this.handleVariantSelect} />
          ))}
        </div>
      </>
    )
  }
}

export default withRouter(SidebarSelectBox)
