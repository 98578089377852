import { REPORTS_ADD_ITEM } from '../ActionTypes'

/**
 *
 * @param {Object} state
 * @param {Object} action
 * @param {String} action.type
 * @param {*} action.payload
 * @returns {Object}
 */
export default (state = {}, action) => {
  switch (action.type) {
    case REPORTS_ADD_ITEM: {
      return {
        ...state,
        [action.payload.edition]: action.payload.links,
      }
    }

    default:
      return state
  }
}
