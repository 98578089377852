import PropTypes from 'prop-types'
import React from 'react'
import ClassNamesService from '../../../services/ClassNamesService'

const AdditionalCell = ({ children, className = null }) => (
  <>
    {React.cloneElement(children, {
      className: ClassNamesService.execute(['cell--is-additional', className]),
    })}
  </>
)

AdditionalCell.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
}

export default AdditionalCell
