import PropTypes from 'prop-types'
import React, { Component } from 'react'

import TableComponent from '../ShootersTable'
import DefaultTable from '../../components/RangedField/DefaultTable'
import MinneTable from '../../components/RangedField/MinneTable'
import Finfelt42Table from '../../components/RangedField/Finfelt42Table'
import Finfelt30Table from '../../components/RangedField/Finfelt30Table'

class ShootersTable extends Component {
  static propTypes = {
    organizationId: PropTypes.string,
  }

  componentsTree = {
    default: DefaultTable,
    MI: {
      default: MinneTable,
    },
    FE: {
      default: DefaultTable,
      FE_1To6: Finfelt42Table,
      FE_1To5: Finfelt30Table,
    },
  }

  render() {
    const { organizationId } = this.props

    return <TableComponent organizationId={organizationId} componentsTree={this.componentsTree} />
  }
}

export default ShootersTable
