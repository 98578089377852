import HtmlReactParser from 'html-react-parser'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import SelectItemDTO from '../../../DTO/SelectItemDTO'
import { ReactComponent as ChevronDownIcon } from '../../../images/chevron-down.svg'
import ClassNamesService from '../../../services/ClassNamesService'

class Select extends Component {
  static propTypes = {
    heading: PropTypes.string,
    className: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(SelectItemDTO.shape)),
    selectedItem: PropTypes.oneOfType([PropTypes.shape(SelectItemDTO.shape), () => null]),
    handleChange: PropTypes.func,
    hasFirstSelected: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
  }

  static defaultProps = {
    className: '',
    heading: '',
    hasFirstSelected: false,
    errors: [],
  }

  render() {
    return (
      <label
        className={ClassNamesService.execute([
          'admin-select',
          this.props.heading.length && 'admin-select--has-heading',
          this.props.className,
        ])}
      >
        {this.props.heading && <span className="admin-select__heading">{this.props.heading}</span>}
        <ChevronDownIcon className="admin-select__arrow" />
        <select
          value={this.props.selectedItem.value}
          onChange={e => this.props.handleChange(e.target.value)}
          className={ClassNamesService.execute([
            'admin-select__element',
            this.props.errors.length && 'admin-select__element--has-error',
          ])}
        >
          {!this.props.hasFirstSelected && (
            <option value={this.props.selectedItem.value} disabled>
              {this.props.selectedItem.title}
            </option>
          )}
          {this.props.items.map(item => (
            <option key={item.value} value={item.value}>
              {item.title}
            </option>
          ))}
        </select>
        {!!this.props.errors.length && (
          <span className="admin-form-message admin-form-message--is-error">
            {HtmlReactParser(this.props.errors.join('<br />'))}
          </span>
        )}
      </label>
    )
  }
}

export default Select
