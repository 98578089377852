import queryString from 'query-string'
import React, { Component } from 'react'
import { Mutation, Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { REPORTS_LIST_QUERY } from '../../constants/queries'
import { RANGED_FIELD_UPDATE_DATA_MUTATION } from '../../constants/mutations'
import SidebarFilter from '../../components/SidebarFilter'
import Dropdown from '../../components/Dropdown'
import ReportsList from '../../entities/ReportsList'
import SubMenuCategory from '../../entities/SubMenuCategory'
import SubMenuChild from '../../entities/SubMenuChild'
import ResponseMiddleware from '../../Middleware/ResponseMiddleware'
import types from '../../Store'

export const ReportsListInstance = new ReportsList()

const BA = new SubMenuCategory('BA', 'Bane')
BA.addCategories([
  new SubMenuChild('BA_4', '10 skudd'),
  new SubMenuChild('BA_1To3', '15 skudd'),
  new SubMenuChild('BA_1To4', '25 skudd'),
  new SubMenuChild('BA_1To5', '35 skudd'),
])

const LA = new SubMenuCategory('LA', 'Lagskyting')
LA.addCategories([new SubMenuChild('BA_1To4', 'Lagskyting kvalifisering', true)])
LA.addCategories([new SubMenuChild('LA_1', 'Lagskyting')])

const SM = new SubMenuCategory('SA', 'Samlagsskyting')
SM.addCategories([new SubMenuChild('BA_4', 'Samlagskyting')])

ReportsListInstance.addCategories([BA, LA, SM])

class Sidebar extends Component {
  mutationHandler = () => {}

  state = {
    types: [BA.value, LA.value, SM.value],
    selectedType: BA.id,
  }

  componentDidMount() {
    const { selectedType } = queryString.parse(this.props.location.search)

    if (!selectedType) {
      return
    }

    this.setState({
      selectedType,
    })
  }

  /**
   *
   * @param {Object[]} response
   * @param {Boolean} response.webEnabled
   * @param {String} response.organizationGroup2Id
   * @param {String} response.organizationId
   * @param {String} response.nameShort
   * @param {null|Number} response.sortOrder
   * @returns {any[]}
   */
  formatCategoriesList = response => {
    return ReportsListInstance.formatCategoriesList(response, this.state.selectedType)
  }

  updateLinkSelect = (category, option, callback) => {
    const activeType = this.state.types.find(type => type.slug === this.state.selectedType) || {
      label: '',
    }

    callback({
      variables: {
        type: activeType.label,
        category: category.label,
        option: option.label,
        organizationId: option.organizationId,
      },
    })

    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    this.props.onCloseSidebar()
  }

  onSelectType = selectedType => {
    this.setState({
      selectedType,
    })

    const { pathname, search } = this.props.location
    const previousSearch = queryString.parse(search)

    this.props.history.push({
      pathname,
      search: queryString.stringify({
        ...previousSearch,
        selectedType,
      }),
    })
  }

  componentWillUnmount() {
    this.mutationHandler({
      variables: types.rangedField,
    })
  }

  render() {
    const { types, selectedType } = this.state

    const response = new ResponseMiddleware()
    response.setComponent(data => (
      <Mutation mutation={RANGED_FIELD_UPDATE_DATA_MUTATION}>
        {handler => {
          this.mutationHandler = handler

          return (
            <SidebarFilter
              onSelectLink={(category, option) => this.updateLinkSelect(category, option, handler)}
              categories={this.formatCategoriesList(data.getReportList)}
            />
          )
        }}
      </Mutation>
    ))

    return (
      <>
        <Dropdown options={types} selectedOption={selectedType} onChange={this.onSelectType} />

        <Query query={REPORTS_LIST_QUERY} variables={{ organizationGroup1Id: selectedType }}>
          {response.execute}
        </Query>
      </>
    )
  }
}

export default withRouter(Sidebar)
