import dayjs from 'dayjs'
import queryString from 'query-string'
import React, { Component, useEffect } from 'react'
import { Mutation, Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import { RELAY_GROUP_QUERY } from '../../constants/queries'
import { LAGVIS_LIST_UPDATE_DATA_MUTATION } from '../../constants/mutations'
import { ReactComponent as ArrowIcon } from '../../images/arrow-icon.svg'

import SidebarFilter from '../../components/SidebarFilter'
import Dropdown from '../../components/Dropdown'

import types from '../../Store'
import ClassNamesService from '../../services/ClassNamesService'

import 'dayjs/locale/nb'
import utc from 'dayjs/plugin/utc'
import ResponseMiddleware from '../../Middleware/ResponseMiddleware'
import LagTimeOption from '../../views/SidebarFilter/LagTimeOption'
dayjs.locale('nb')
dayjs.extend(utc)

const subMenu = {
  BA_100: [
    {
      label: '100m akkurat nå',
      slug: 'BA_100_live',
    },
  ],
  BA_200: [
    {
      label: '200m akkurat nå',
      slug: 'BA_200_live',
    },
  ],
  BA_MIL: [
    {
      label: '200m akkurat nå',
      slug: 'BA_MIL_live',
    },
  ],
}

class Sidebar extends Component {
  mutationHandler = () => {}

  state = {
    types: [
      {
        label: 'Lagvis 100m',
        slug: 'BA_100',
      },
      {
        label: 'Lagvis 200m',
        slug: 'BA_200',
      },
      {
        label: 'Lagvis Finfelt',
        slug: 'FE_FIN',
      },
      {
        label: 'Lagvis Grovfelt',
        slug: 'FE_GROV',
      },
      {
        label: 'Lagvis MIL Bane',
        slug: 'BA_MIL',
      },
      {
        label: 'Lagvis MIL Felt',
        slug: 'FE_MIL',
      },
      {
        label: 'Lagvis Stang/Felthurtig',
        slug: 'ST',
      },
      {
        label: 'Lagvis MIL Stang/Felthurtig',
        slug: 'ST_MIL',
      },
    ],
    selectedType: 'BA_100',
  }

  componentDidMount() {
    const { selectedType, selectedLink } = queryString.parse(this.props.location.search)

    if (!selectedType && !selectedLink) {
      return
    }

    this.setState({
      selectedType,
      selectedLink,
    })
  }

  formatCategoriesList = (response) => {
    const categories = {}

    if (!response.length) {
      return Object.values(categories)
    }

    response[0].relays.forEach((category) => {
      if (category.relayType !== 'MAIN') {
        return
      }

      const [date] = category.checkinDateTime.split('T')

      const slug = `${this.state.selectedType}-${date}`

      const isCategoryExists = categories.hasOwnProperty(date)

      const itemLabel = `Lag ${category.number} - ${dayjs(category.checkinDateTime).utc().format('HH.mm')} - ${
        category.name
      }`

      const item = {
        slug: itemLabel.split(' ').join('-'),
        label: category.name,
        lag: `Lag ${category.number}`,
        time: dayjs(category.checkinDateTime).utc().format('HH:mm'),
        timeString: category.checkinDateTime,
        organizationId: response[0].organizationId,
        number: category.number,
      }

      if (isCategoryExists) {
        categories[date].children.push(item)

        return
      }

      categories[date] = {
        label: dayjs(category.checkinDateTime).utc().format('dddd D.M').toUpperCase(),
        timestamp: dayjs(category.checkinDateTime).utc().unix(),
        slug,
        children: [item],
      }
    })

    return Object.values(categories)
      .map((category) => ({
        ...category,
        children: category.children.sort((a, b) => a.number - b.number),
      }))
      .sort((a, b) => a.timestamp - b.timestamp)
  }

  updateLinkSelect = (category, option, callback) => {
    const activeType = this.state.types.find((type) => type.slug === this.state.selectedType) || {
      label: '',
    }
    if (option === 'custom_link') {
      callback({
        variables: {
          type: category,
          category: category,
          option: category,
          timeString: category,
          number: category,
          organizationId: category.replace('_live', ''),
        },
      })
      const { pathname, search } = this.props.location
      const previousSearch = queryString.parse(search)

      this.setState({
        selectedLink: category,
      })

      this.props.history.push({
        pathname,
        search: queryString.stringify({
          ...previousSearch,
          selectedLink: category,
          selectedType: category.replace('_live', ''),
        }),
      })

      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
      this.props.onCloseSidebar()
      return
    } else if (!option) {
      return
    }

    callback({
      variables: {
        type: activeType.label,
        category: category.label,
        option: option.label,
        timeString: option.timeString,
        number: option.number,
        organizationId: option.organizationId,
      },
    })
    this.setState({
      selectedLink: '',
    })

    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
    this.props.onCloseSidebar()
  }

  onSelectType = (selectedType) => {
    this.setState({
      selectedType,
    })

    const { pathname, search } = this.props.location
    const previousSearch = queryString.parse(search)

    this.props.history.push({
      pathname,
      search: queryString.stringify({
        ...previousSearch,
        selectedType,
      }),
    })
  }

  componentWillUnmount() {
    this.mutationHandler({
      variables: types.lagvisList,
    })
  }

  render() {
    const { types, selectedType } = this.state

    const response = new ResponseMiddleware()
    response.setComponent((data) => (
      <Mutation mutation={LAGVIS_LIST_UPDATE_DATA_MUTATION}>
        {(handler) => {
          this.mutationHandler = handler
          return (
            <>
              {['BA_100', 'BA_200', 'BA_MIL'].includes(this.state.selectedType) ? (
                <>
                  <AdditionalLink
                    selectLink={(slug) => this.updateLinkSelect(slug, 'custom_link', handler)}
                    label={subMenu[this.state.selectedType][0].label}
                    slug={subMenu[this.state.selectedType][0].slug}
                    selectedLink={this.state.selectedLink}
                  />
                </>
              ) : null}

              <SidebarFilter
                onSelectLink={(category, option) => this.updateLinkSelect(category, option, handler)}
                categories={this.formatCategoriesList(data.getRelayGroup)}
                categoryComponent={LagTimeOption}
                cleanSelectedLink={['BA_100_live', 'BA_200_live', 'BA_MIL_live'].includes(this.state.selectedLink)}
              />
            </>
          )
        }}
      </Mutation>
    ))

    return (
      <>
        <Dropdown options={types} selectedOption={selectedType} onChange={this.onSelectType} />

        <Query query={RELAY_GROUP_QUERY} variables={{ organizationId: selectedType }}>
          {response.execute}
        </Query>
      </>
    )
  }
}

export default withRouter(Sidebar)

const AdditionalLink = ({ label, slug, selectedLink, selectLink }) => {
  const isSelected = selectedLink === slug
  useEffect(() => {
    isSelected && selectLink(slug)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ul className={ClassNamesService.execute(['filter__category', isSelected && 'filter__category--is-opened'])}>
      <li
        className={ClassNamesService.execute([
          'filter__category-label',
          isSelected && 'filter__category-label--is-opened',
        ])}
        onClick={() => selectLink(slug)}
      >
        <span>{label}</span>

        <ArrowIcon
          className={ClassNamesService.execute([
            'filter__category-arrow',
            isSelected && 'filter__category-arrow--is-opened-no-rotation',
          ])}
        />
      </li>
    </ul>
  )
}
