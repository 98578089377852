import React from 'react'
import propTypes from 'prop-types'

import { ReactComponent as CircleCross } from '../images/circle-cross.svg'
import ClassNamesService from '../services/ClassNamesService'

const ModalClose = ({ onClick, className }) => {
  return (
    <button className={ClassNamesService.execute(['modal__close', className])} onClick={onClick}>
      <CircleCross />
    </button>
  )
}

export default ModalClose

ModalClose.propTypes = {
  onClick: propTypes.func.isRequired,
  className: propTypes.string,
}
ModalClose.defaultProps = {
  onClick: () => alert('ModalClose clicked'),
  className: '',
}
