import UserDTO from '../../DTO/UserDTO'
import UserEntity from '../../entities/UserEntity'

class UserVO {
  instance

  constructor(instance) {
    this.instance = instance
  }

  /**
   *
   * @param email
   * @param password
   * @return {Promise<UserDTO>}
   */
  login = async (email, password) => {
    const { data } = await this.instance.post('/login', {
      email,
      password,
    })

    return new UserDTO(
      data.user.id,
      data.user.name,
      data.user.email,
      data.user.email_verified_at,
      UserEntity.roles.USER
    )
  }

  logout = () => {
    return this.instance.post('/logout')
  }

  /**
   *
   * @param username
   * @param password
   * @return {*}
   * @constructor
   */
  DFSLogin = async (username, password) => {
    const { data } = await this.instance.post('/dfs/login', {
      username,
      password,
    })

    return new UserDTO(
      data.user.id,
      data.user.name,
      data.user.email,
      data.user.email_verified_at,
      UserEntity.roles.USER
    )
  }

  /**
   *
   * @param name
   * @param email
   * @param password
   * @return {Promise<UserDTO>}
   */
  signUp = async (name, email, password) => {
    await this.instance.post('/register', {
      name,
      email,
      password,
      password_confirmation: password,
    })

    return new UserDTO(1, name, email, null, UserEntity.roles.USER)
  }

  /**
   *
   * @return {Promise<*>}
   */
  resendEmailVerification = async () => {
    return this.instance.get('/email/resend')
  }

  requestResetPassword = async email => {
    return this.instance.post('/password/email', {
      email,
    })
  }

  sendResetPassword = async (token, email, password) => {
    return this.instance.post('/password/reset', {
      token,
      email,
      password,
      password_confirmation: password,
    })
  }

  /**
   *
   * @return {Promise<UserDTO>}
   */
  info = async () => {
    const { data } = await this.instance.get('/me')

    // await DelayVO.delay(5000)
    // await DelayVO.delay(10000 * 6 * 5)

    return new UserDTO(data.id, data.name, data.email, data.email_verified_at, UserEntity.roles.USER)
  }
}

export default UserVO
