import React from 'react'
import propTypes from 'prop-types'
import tryService from '../../services/tryService'
import { findResults, getEventDate, getEventTime } from '../../services/shooterService'

const ClubShooterTableRow = ({ shooter }) => {
  //   console.log(shooter);
  const { categories } = shooter
  let baneResults, feltResults
  baneResults = findResults(shooter.events, 'Bane', 'MAIN', 'ORDINARY')
  feltResults = findResults(shooter.events, 'Felt', 'MAIN', 'ORDINARY')
  const baneLag = tryService(() => baneResults.relayNumber)
  const baneSk = tryService(() => baneResults.targetNumber)
  const baneDate = tryService(() => baneResults.checkinDateTime && getEventDate(baneResults.checkinDateTime))
  const baneTime = tryService(() => baneResults.checkinDateTime && getEventTime(baneResults.checkinDateTime))
  const catA = tryService(() => categories.includes('U')) && 'U'
  const catK = tryService(() => categories.includes('K')) && 'K'
  const catL = tryService(() => categories.includes('L')) && 'L'
  const catN = tryService(() => categories.includes('N')) && 'N'
  const catMB = tryService(() => categories.includes('MB')) && 'MB'
  const catMF = tryService(() => categories.includes('MF')) && 'MF'
  const catFT = tryService(() => categories.includes('FT')) && 'FT'
  const feltLag = tryService(() => feltResults.relayNumber)
  const feltSk = tryService(() => feltResults.targetNumber)
  const feltDate = tryService(() => feltResults.checkinDateTime && getEventDate(feltResults.checkinDateTime))
  const feltTime = tryService(() => feltResults.checkinDateTime && getEventTime(feltResults.checkinDateTime))

  return (
    <tr>
      <td>{shooter.defaultClassOrganizationId}</td>
      <td>{shooter.name}</td>
      <td>{baneLag}</td>
      <td>{baneSk}</td>
      <td>{baneDate}</td>
      <td>{baneTime}</td>
      <td>{catA}</td>
      <td>{catK}</td>
      <td>{catL}</td>
      <td>{catN}</td>
      <td>{catMB}</td>
      <td>{catMF}</td>
      <td>{catFT}</td>
      <td>{feltLag}</td>
      <td>{feltSk}</td>
      <td>{feltDate}</td>
      <td>{feltTime}</td>
    </tr>
  )
}

export default ClubShooterTableRow

ClubShooterTableRow.defaultProps = {
  shooter: propTypes.shape({
    name: propTypes.string.isRequired,
    clubName: propTypes.string.isRequired,
    categories: propTypes.arrayOf(propTypes.string),
    countyName: propTypes.string,
    defaultClassName: propTypes.string,
    defaultClassOrganizationId: propTypes.string,
    events: propTypes.array,
  }),
}
