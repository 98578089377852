import { LAGVIS_LIST, RANGED_FIELD, SAMLAGS_FYLKE, SAMLAGS_FYLKE_SHOOTERS } from './constants/localTypes'

export default {
  isShooterModalOpen: false,
  shooterModalId: '',
  rangedField: {
    __typename: RANGED_FIELD,
    type: '',
    category: '',
    option: '',
    organizationId: '',
  },
  lagvisList: {
    __typename: LAGVIS_LIST,
    type: '',
    category: '',
    timeString: '',
    option: '',
    number: 0,
    organizationId: '',
  },
  samlagsFylke: {
    __typename: SAMLAGS_FYLKE,
    organizationId: '',
    fromDate: '',
    toDate: '',
    classesSelected: [],
    label: '',
  },
  samlagsFylkeShooters: {
    __typename: SAMLAGS_FYLKE_SHOOTERS,
    list: '{}',
  },
}
