import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { common } from '../constants/messages'
import { USER_CABINET_LOGIN } from '../constants/routePaths'
import UserEntity from '../entities/UserEntity'
import { requestPendingFinish, requestPendingStart, requestTypes } from '../redux/actions/RequestPending'
import ClassNamesService from '../services/ClassNamesService'
import Button from '../views/Button'
import { FavoritesAddShooter, FavoritesRemoveShooter } from '../redux/actions/Favorites'

const mapStateToProps = state => ({
  User: state.User,
  Favorites: state.Favorites,
  RequestPending: state.RequestPending,
})

const mapDispatchToProps = dispatch => ({
  FavoritesAddShooter: id => dispatch(FavoritesAddShooter(id)),
  FavoritesRemoveShooter: id => dispatch(FavoritesRemoveShooter(id)),
  startFavoritesRequest: () => dispatch(requestPendingStart(requestTypes.FAVORITE)),
  finishFavoritesRequest: () => dispatch(requestPendingFinish(requestTypes.FAVORITE)),
})

class FavoritesButton extends Component {
  static propTypes = {
    User: PropTypes.object,
    Favorites: PropTypes.object,
    RequestPending: PropTypes.object,

    FavoritesAddShooter: PropTypes.func,
    FavoritesRemoveShooter: PropTypes.func,
    startFavoritesRequest: PropTypes.func,
    finishFavoritesRequest: PropTypes.func,

    className: PropTypes.string,
    shooterId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }

  static defaultProps = {
    className: '',
  }

  user = new UserEntity()

  get buttonText() {
    return this.props.Favorites.hasOwnProperty(this.props.shooterId) ? common.favoriteRemove : common.favoriteAdd
  }

  get isLoading() {
    return this.props.RequestPending[requestTypes.FAVORITE]
  }

  favoritesAction = async () => {
    if (this.props.Favorites.hasOwnProperty(this.props.shooterId)) {
      await this.props.FavoritesRemoveShooter(this.props.shooterId)
      return
    }

    await this.props.FavoritesAddShooter(this.props.shooterId)
  }

  handleButtonClick = async () => {
    if (this.isLoading) {
      return
    }

    if (this.user.isAdmin) {
      await this.user.logout()
    }

    if (!this.user.canAddFavorite) {
      this.props.history.push(USER_CABINET_LOGIN)

      return
    }

    this.props.startFavoritesRequest()
    try {
      await this.favoritesAction()
    } catch (e) {
      console.log(e)
    }
    this.props.finishFavoritesRequest()
  }

  render() {
    return (
      <Button
        onClick={this.handleButtonClick}
        className={ClassNamesService.execute([
          'btn--is-secondary-row btn--has-top-offset',
          this.props.className,
          this.isLoading && 'btn--is-loading',
        ])}
      >
        {this.buttonText}
      </Button>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FavoritesButton)
)
