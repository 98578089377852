import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, withRouter } from 'react-router-dom'

import LinkDTO from '../DTO/LinkDTO'
import { applicationYear } from '../constants/allYears'
import { MenuItemsAddItems } from '../redux/actions/MenuItems'
import MenuFactoryVO from '../VO/MenuFactoryVO'
import { HOME_PATH } from '../constants/routePaths'
import { Navigation, HeaderSearch } from '../components'
import { ReactComponent as MenuIcon } from '../images/header-menu.svg'

import DFSLogo from '../images/dfs_logo.svg'
import { CSSTransition } from 'react-transition-group'
import '../styles/header.scss'

/**
 *
 * @param {string} link
 * @param match
 * @param location
 * @return {boolean}
 */
const resolveActive = (link, match, location) => {
  if (match) {
    return true
  }

  return `${location.pathname}${location.search}` === link
}

const Header = ({ history, location, match, appState, onShowNav, onShowSearch }) => {
  const [menuLinks, setMenuLinks] = useState([])
  const storeMenuLinks = useSelector((state) => state.MenuItems)
  const dispatch = useDispatch()
  const addStoreMenuLink = async (edition) => {
    const handler = MenuItemsAddItems(edition)

    return await handler(dispatch)
  }

  useEffect(() => {
    ;(async () => {
      try {
        const edition = applicationYear
        const links = storeMenuLinks[edition]

        if (links && links.length) {
          // To do create this link ind DB
          const menu = new MenuFactoryVO().parseAsLinks(links)
          if (menu.length && applicationYear === '2024') {
            menu.splice(4, 0, new LinkDTO('NM skogsløp', '/nm-skogslop', 100))
          }
          setMenuLinks(menu)

          return
        }

        const newLinks = await addStoreMenuLink(edition)
        // To do create this link ind DB
        const menu = new MenuFactoryVO().parseAsLinks(newLinks)

        if (menu.length && applicationYear === '2024') {
          menu.splice(4, 0, new LinkDTO('NM skogsløp', '/nm-skogslop', 100))
        }

        setMenuLinks(menu)
      } catch (e) {
        console.log(e)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="site-header__wrapper">
      <CSSTransition
        in={appState.isNavShown}
        timeout={300}
        classNames="site-navigation"
        unmountOnExit
        onEnter={() => onShowNav(true)}
        onExited={() => onShowNav(false)}
      >
        <Navigation onClose={() => onShowNav(false)} />
      </CSSTransition>
      <header className="site-header">
        <div className="site-header__menu">
          <button className="header-btn header-btn__menu" onClick={() => onShowNav(true)}>
            <MenuIcon /> <span>Meny</span>
          </button>
        </div>

        <div className="site-header__logo">
          <Link to={HOME_PATH}>
            <img src={DFSLogo} alt="DFS Logo" />
            <div className="site-header__logo-year">{applicationYear}</div>
          </Link>
        </div>

        <div className="site-header__search">
          <HeaderSearch
            history={history}
            location={location}
            match={match}
            appState={appState}
            onShowSearch={onShowSearch}
          />
        </div>
      </header>
      <nav className="site-menu">
        {menuLinks.map((item) => (
          <li className="site-menu__item" key={item.id}>
            <NavLink
              to={item.url}
              exact
              isActive={(match, location) => resolveActive(item.url, match, location)}
              className="site-menu__item-link"
              activeClassName="site-menu__item-link--is-active"
            >
              {item.title}
            </NavLink>
          </li>
        ))}
      </nav>
    </div>
  )
}

export default withRouter(Header)
