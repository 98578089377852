import React from 'react'
import PropTypes from 'prop-types'

import ClassNamesService from '../../services/ClassNamesService'

const LagTimeOption = ({ label, slug, lag, time, isSelected, selectLink }) => (
  <li
    className={ClassNamesService.execute([
      'filter__item',
      'filter__item--is-lag-time',
      isSelected && 'filter__item--is-selected',
    ])}
    onClick={() => selectLink(slug)}
  >
    <span className="filter__item-lag">{lag}</span>

    <span className="filter__item-title filter__item-title--is-lag-time">{label}</span>

    <span className="filter__item-time">{time}</span>
  </li>
)

LagTimeOption.propTypes = {
  label: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  lag: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  selectLink: PropTypes.func,
  isSelected: PropTypes.bool,
}

LagTimeOption.defaultProps = {
  selectLink: () => {},
  isSelected: false,
}

export default LagTimeOption
