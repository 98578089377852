import React from 'react'
import Img from 'react-image'
import ReactModal from 'react-modal'
import ImageService from '../services/ImageService'
import Loader from './loader'
import NoImage from '../images/no_image.png'
import ModalClose from './modalClose'

const ImageModal = ({ modalImgLink, showModal, handleCloseModal }) => {
  const images = modalImgLink ? [...modalImgLink.map(ImageService.execute), NoImage] : [null]

  return (
    <>
      <ReactModal
        isOpen={showModal}
        contentLabel="onRequestClose Example"
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={{
          overlay: {
            position: 'fixed',
            zIndex: 40,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
          },
          content: {
            position: 'absolute',
            top: '40px',
            left: '50%',
            right: 'auto',
            transform: 'translateX(-50%)',
            bottom: '40px',
            minWidth: '290px',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '40px 30px 20px 30px',
          },
        }}
      >
        <ModalClose onClick={handleCloseModal} />
        <Img src={images} loader={<Loader />} />
      </ReactModal>
    </>
  )
}

export default ImageModal
