import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { actions, adminEdit } from '../../../constants/messages'
import SelectItemDTO from '../../../DTO/SelectItemDTO'
import ModalComponent from '../../../views/ModalComponent'
import SubmitButton from '../views/SubmitButton'
import Select from './Select'

class ReportsModal extends Component {
  static propTypes = {
    isOpened: PropTypes.bool,
    isEdit: PropTypes.bool,
    handleClose: PropTypes.func,

    category: PropTypes.shape(SelectItemDTO.shape),
    type: PropTypes.shape(SelectItemDTO.shape),
    limit: PropTypes.shape(SelectItemDTO.shape),
    view: PropTypes.shape(SelectItemDTO.shape),
    group: PropTypes.shape(SelectItemDTO.shape),
    classType: PropTypes.shape(SelectItemDTO.shape),

    categoryVariants: PropTypes.arrayOf(PropTypes.shape(SelectItemDTO.shape)),
    typeVariants: PropTypes.arrayOf(PropTypes.shape(SelectItemDTO.shape)),
    limitVariants: PropTypes.arrayOf(PropTypes.shape(SelectItemDTO.shape)),
    viewVariants: PropTypes.arrayOf(PropTypes.shape(SelectItemDTO.shape)),
    groupVariants: PropTypes.arrayOf(PropTypes.shape(SelectItemDTO.shape)),
    classTypeVariants: PropTypes.arrayOf(PropTypes.shape(SelectItemDTO.shape)),

    categoryErrors: PropTypes.arrayOf(PropTypes.string),
    typeErrors: PropTypes.arrayOf(PropTypes.string),
    groupErrors: PropTypes.arrayOf(PropTypes.string),
    classTypeErrors: PropTypes.arrayOf(PropTypes.string),

    handleCategoryChange: PropTypes.func,
    handleTypeChange: PropTypes.func,
    handleLimitChange: PropTypes.func,
    handleViewChange: PropTypes.func,
    handleGroupChange: PropTypes.func,
    handleClassTypeChange: PropTypes.func,

    handleSave: PropTypes.func,

    isLoading: PropTypes.bool,
  }

  static defaultProps = {
    categoryErrors: [],
    typeErrors: [],
    groupErrors: [],
    classTypeErrors: [],
  }

  render() {
    return (
      <ModalComponent
        handleClose={this.props.handleClose}
        isOpened={this.props.isOpened}
        className="modal-component--is-menu-edit"
        title={this.props.isEdit ? 'Rediger Rapport' : 'Legg til Rapport'}
      >
        <Select
          items={this.props.categoryVariants}
          selectedItem={this.props.category}
          heading="Ranged type"
          handleChange={this.props.handleCategoryChange}
          hasFirstSelected
          errors={this.props.categoryErrors}
        />

        <Select
          items={this.props.typeVariants}
          selectedItem={this.props.type}
          heading="Variant"
          handleChange={this.props.handleTypeChange}
          hasFirstSelected
          errors={this.props.typeErrors}
        />

        <Select
          items={this.props.limitVariants}
          selectedItem={this.props.limit}
          heading={adminEdit.limit}
          handleChange={this.props.handleLimitChange}
          hasFirstSelected
        />

        <Select
          items={this.props.groupVariants}
          selectedItem={this.props.group}
          heading={adminEdit.group}
          handleChange={this.props.handleGroupChange}
          hasFirstSelected
          errors={this.props.groupErrors}
        />

        <Select
          items={this.props.classTypeVariants}
          selectedItem={this.props.classType}
          heading="Klasse"
          handleChange={this.props.handleClassTypeChange}
          hasFirstSelected
          errors={this.props.classTypeErrors}
        />

        <Select
          items={this.props.viewVariants}
          selectedItem={this.props.view}
          heading={adminEdit.view}
          handleChange={this.props.handleViewChange}
          hasFirstSelected
        />

        <div className="admin-modal__row">
          <SubmitButton
            className="admin-button--is-modal-submit"
            handleSubmit={this.props.handleSave}
            isLoading={this.props.isLoading}
          >
            {actions.save}
          </SubmitButton>
        </div>
      </ModalComponent>
    )
  }
}

export default ReportsModal
