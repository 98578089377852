import React from 'react'
import PropTypes from 'prop-types'
import ClassNamesService from '../../../services/ClassNamesService'

const ShotsCell = ({ score = '', shots = '', hasBorder = false, width = null, className = null }) => (
  <div
    className={ClassNamesService.execute(['cell cell--is-shots', className, hasBorder && 'cell--has-right-border'])}
    style={{
      width,
    }}
  >
    <span
      className={ClassNamesService.execute([
        'score-block score-block--is-shots',
        shots === '' && 'score-block--is-big',
      ])}
    >
      {score}
    </span>
    <span className="shots-block">{shots}</span>
  </div>
)

ShotsCell.propTypes = {
  score: PropTypes.string,
  shots: PropTypes.string,
  hasBorder: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, () => null]),
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
}

export default ShotsCell
