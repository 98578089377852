import dayjs from 'dayjs'
import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'

import '../styles/ranged-tables.scss'
import SingleStepRender from '../components/SingleStepRender'
import StepRender from '../components/StepRender'
import { FULL_DAYS } from '../constants/Dates'
import { SAMLAGS_FYLKE_QUERY, SHOOTERS_BY_COUNTY_QUERY, SHOOTERS_BY_DISTRICT_QUERY } from '../constants/queries'
import ResponseMiddleware from '../Middleware/ResponseMiddleware'
import Message from '../views/Message'

import messages from '../constants/messages'

import Heading from '../views/TypeHeading'
import Sidebar from '../containers/SamlagsFylkesvise/Sidebar'
import ShootersTable from '../containers/SamlagsFylkesvise/ShootersTable'
import SidebarHOC from '../components/SidebarHOC'

class SamlagsFylkesvise extends Component {
  componentDidMount() {
    this.props.onShowSearch(false)
    this.props.onShowNav(false)
  }

  /**
   *
   * @param {Object} data
   * @param {Object[]} classesSelected
   */
  rangeData = (data, classesSelected) => {
    const events = {}
    const classesIds = {}

    classesSelected.forEach(classItem => {
      classesIds[classItem.id] = classItem
    })

    const classesLength = Object.values(classesIds).length
    const key = Object.keys(data)[0]

    if (!key) {
      return []
    }

    data[key].forEach(shooter => {
      shooter.events.forEach(event => {
        if (!classesIds.hasOwnProperty(shooter.defaultClassOrganizationId) && classesLength) {
          return
        }

        const eventName = [event.name, shooter.defaultClassOrganizationId, event.eventType].join(':')

        if (!events.hasOwnProperty(eventName)) {
          events[eventName] = []
        }

        events[eventName].push({
          eventName,
          name: shooter.name,
          className: shooter.defaultClassName,
          clubName: shooter.clubName,
          date: event.resultDateTime,
          organizationId: shooter.organizationId,
          timestamp: dayjs(event.resultDateTime).valueOf(),
          series: event.series,
        })
      })
    })

    return Object.values(events).sort((a, b) => a[0].timestamp - b[0].timestamp)
  }

  /**
   *
   * @param {Object} fylkeVariables
   * @param {String} fylkeVariables.countyOrganizationId
   * @param {String} fylkeVariables.fromDate
   * @param {String} fylkeVariables.toDate
   * @param {String[]} fylkeVariables.classesSelected
   */
  performShootersQuery = fylkeVariables => {
    const response = new ResponseMiddleware()
    response.setComponent(data => {
      const rangedData = this.rangeData(data, fylkeVariables.classesSelected)

      return (
        <StepRender className="content-wrapper" windowsCount={2}>
          {rangedData.map(event => {
            const [name] = event[0].eventName.split(':')
            const currentDate = dayjs(event[0].date)
            const dayName = FULL_DAYS[currentDate.get('d')] + ' ' + currentDate.format('DD.MM')
            const capitalizedName = dayName[0].toUpperCase() + dayName.slice(1)
            const className = event[0].className

            return (
              <SingleStepRender key={event[0].eventName} tableKey={event[0].eventName}>
                <>
                  <Heading className="ranged-field" type={capitalizedName} category={name} option={className} />

                  <ShootersTable data={event} organizationId={event[0].eventName} type={capitalizedName} />
                </>
              </SingleStepRender>
            )
          })}
        </StepRender>
      )
    })

    return (
      fylkeVariables.label !== '' && (
        <Query
          query={fylkeVariables.label === 'fylke' ? SHOOTERS_BY_COUNTY_QUERY : SHOOTERS_BY_DISTRICT_QUERY}
          variables={{ ...fylkeVariables, id: Math.random() }}
        >
          {response.execute}
        </Query>
      )
    )
  }

  render() {
    const response = new ResponseMiddleware()
    response.setComponent(data => {
      const { organizationId, fromDate, toDate } = data.samlagsFylke
      const variables = [organizationId, fromDate, toDate]
      const areSomeEmpty = variables => variables.reduce((acc, item) => (!acc ? item === '' : acc), false)

      if (areSomeEmpty(variables)) {
        return <Message text={messages.selectRegionAndDate} />
      }

      if (areSomeEmpty([fromDate, toDate])) {
        return <Message text={messages.selectDate} />
      }

      if (areSomeEmpty([organizationId])) {
        return <Message text={messages.selectRegion} />
      }

      return this.performShootersQuery(data.samlagsFylke)
    })

    return (
      <div className="full-page-wrapper full-page-wrapper--is-fluid full-page-wrapper--is-full-width shooters-table">
        <SidebarHOC>
          <Sidebar />
        </SidebarHOC>

        <Query query={SAMLAGS_FYLKE_QUERY}>{response.execute}</Query>
      </div>
    )
  }
}

export default withRouter(SamlagsFylkesvise)
