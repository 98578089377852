import PropTypes from 'prop-types'
import React from 'react'
import ClassNamesService from '../../../services/ClassNamesService'

const LettersHead = ({ className = null, letters = [] }) => (
  <div className={ClassNamesService.execute(['head-cell head-cell--is-letters', className])}>
    {letters.map((letter, idx) => (
      <span className="head-cell__text" key={idx}>
        {letter}
      </span>
    ))}
  </div>
)

LettersHead.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
  letters: PropTypes.arrayOf(PropTypes.string),
}

export default LettersHead
