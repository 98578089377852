import React from 'react'
import PropTypes from 'prop-types'

import ClassNamesService from '../services/ClassNamesService'

const DropdownItem = ({ label, slug, onChange, isSelected }) => (
  <li
    className={ClassNamesService.execute(['dropdown__option', isSelected && 'dropdown__option--is-selected'])}
    onClick={() => onChange(slug)}
  >
    {label}
  </li>
)

DropdownItem.propTypes = {
  label: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isSelected: PropTypes.bool,
}

DropdownItem.defaultProps = {
  onChange: () => {},
  isSelected: false,
}

export default DropdownItem
