import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { connect } from 'react-redux'
import { NavLink, Redirect } from 'react-router-dom'
import * as Yup from 'yup'
import messages, { actions, notifications } from '../constants/messages'
import { BEValidationMessages, userCabinet, validationMessages } from '../constants/messages'
import { USER_CABINET_INFO, USER_CABINET_LOGIN } from '../constants/routePaths'
import NotificationDTO from '../DTO/NotificationDTO'
import { notificationsEntity } from '../entities/NotificationsEntity'
import UserEntity from '../entities/UserEntity'
import WithBootstrap from '../HOC/WithBootstrap'
import { requestPendingFinish, requestPendingStart, requestTypes } from '../redux/actions/RequestPending'
import ClassNamesService from '../services/ClassNamesService'
import Http from '../services/Http'

const mapStateToProps = state => ({
  User: state.User,
  RequestPending: state.RequestPending,
})

const mapDispatchToProps = dispatch => ({
  requestPendingStart: type => dispatch(requestPendingStart(type)),
  requestPendingFinish: type => dispatch(requestPendingFinish(type)),
})

class ForgotPasswordForm extends Component {
  static propTypes = {
    RequestPending: PropTypes.object,
    User: PropTypes.object,

    requestPendingStart: PropTypes.func,
    requestPendingFinish: PropTypes.func,
  }

  user = new UserEntity()

  state = {
    wasRequestSuccessful: false,
  }

  loginRoute = USER_CABINET_LOGIN
  schema = Yup.object({
    email: Yup.string()
      .email(validationMessages.emailMustBeValid)
      .required(validationMessages.emailIsRequired),
  })

  get isLoading() {
    return this.props.RequestPending[requestTypes.FAVORITE] || this.props.RequestPending[requestTypes.LOGIN]
  }

  resolveErrorResponse = data => {
    if (!data.hasOwnProperty('message')) {
      notificationsEntity.addNotification(
        new NotificationDTO(null, messages.error, notifications.somethingWentWrong, NotificationDTO.types.error)
      )

      return
    }

    const message = BEValidationMessages[data.message] || userCabinet.invalidCredentials

    notificationsEntity.addNotification(
      new NotificationDTO(null, messages.error, message, NotificationDTO.types.error, NotificationDTO.defaultDelay)
    )
  }

  handleResetPasswordClick = async value => {
    if (this.isLoading) {
      return
    }

    this.props.requestPendingStart(requestTypes.LOGIN)

    let wasRequestSuccessful = true

    try {
      await Http.endpoints.user.requestResetPassword(value.email)
    } catch (e) {
      wasRequestSuccessful = false

      this.resolveErrorResponse(e.response.data)
    }

    this.setState(
      {
        wasRequestSuccessful,
      },
      () => this.props.requestPendingFinish(requestTypes.LOGIN)
    )
  }

  render() {
    const { wasRequestSuccessful } = this.state

    return this.user.isAuthenticated ? (
      <Redirect to={USER_CABINET_INFO} />
    ) : (
      <WithBootstrap>
        <section className="d-flex align-items-center justify-content-center w-100 mt-auto mb-auto">
          {wasRequestSuccessful && (
            <div
              className="d-flex flex-column"
              style={{
                width: '400px',
                maxWidth: '90vw',
              }}
            >
              <h3 className="mb-4">{notifications.resetPasswordSuccess}</h3>
              <p>{notifications.resetWasSucceed}</p>
            </div>
          )}
          {!wasRequestSuccessful && (
            <div
              className="d-flex flex-column"
              style={{
                width: '400px',
                maxWidth: '90vw',
              }}
            >
              <h3 className="mb-4">{userCabinet.resetPasswordCabinet}</h3>
              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={this.schema}
                onSubmit={this.handleResetPasswordClick}
              >
                {props => {
                  const { handleSubmit, handleChange, touched, errors, values } = props

                  return (
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        handleSubmit(e)
                      }}
                      noValidate
                    >
                      <Form.Group>
                        <Form.Label>{userCabinet.email}</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder={actions.enterEmail}
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email && touched.email}
                          name="email"
                        />
                        <Form.Control.Feedback
                          type="invalid"
                          className={ClassNamesService.execute([touched.email && 'd-flex'])}
                        >
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <div className="d-flex mb-3">
                        <Button variant="primary" className="w-100" disabled={this.isLoading} type="submit">
                          {userCabinet.resetPassword}
                        </Button>
                      </div>
                      {this.loginRoute && (
                        <div className="d-flex mb-3 justify-content-center">
                          <NavLink
                            className={ClassNamesService.execute([this.isLoading && 'text-secondary'])}
                            to={this.loginRoute}
                            onClick={this.handleLinkClick}
                          >
                            {userCabinet.loginToCabinet}
                          </NavLink>
                        </div>
                      )}
                    </Form>
                  )
                }}
              </Formik>
            </div>
          )}
        </section>
      </WithBootstrap>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordForm)
