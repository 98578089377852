import PropTypes from 'prop-types'
import React from 'react'
import ClassNamesService from '../../../services/ClassNamesService'

const StarCell = ({ children, className = null }) => (
  <div className={ClassNamesService.execute(['cell cell--is-star', className])}>
    <span className="star-block">{children}</span>
  </div>
)

StarCell.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
}

export default StarCell
