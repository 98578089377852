import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { ReactComponent as Arrow } from '../../images/arrow-icon.svg'
import ClassNamesService from '../../services/ClassNamesService'

class Row extends Component {
  static defaultProps = {
    secondaryRows: null,
    updateKey: '',
    className: null,
    isAdditionalInfoOpened: false,
    onClick: () => {},
    toggleHandler: () => {},
  }

  static propTypes = {
    updateKey: PropTypes.string,
    className: PropTypes.oneOfType([PropTypes.string, () => null]),
    isAdditionalInfoOpened: PropTypes.bool,
    onClick: PropTypes.func,
    toggleHandler: PropTypes.func,
  }

  state = {
    isAdditionalInfoOpened: this.props.isAdditionalInfoOpened,
  }

  toggleInfoOpened = () => {
    this.setState(
      prevState => ({
        isAdditionalInfoOpened: !prevState.isAdditionalInfoOpened,
      }),
      () => {
        this.props.toggleHandler(this.state.isAdditionalInfoOpened)
      }
    )
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.updateKey !== this.props.updateKey) {
      this.setState({
        isAdditionalInfoOpened: false,
      })
    }
  }

  render() {
    const { children, secondaryRows, className, onClick } = this.props
    const { isAdditionalInfoOpened } = this.state

    return (
      <li className={ClassNamesService.execute(['row', className])} onClick={onClick}>
        <div
          onClick={this.toggleInfoOpened}
          className={ClassNamesService.execute([
            'row__primary',
            className,
            isAdditionalInfoOpened && 'row__primary--is-opened',
          ])}
        >
          {children}
          {!!secondaryRows && <Arrow />}
        </div>

        {!!secondaryRows && (
          <div
            className={ClassNamesService.execute([
              'row__secondary',
              className,
              isAdditionalInfoOpened && 'row__secondary--is-opened',
            ])}
          >
            {secondaryRows}
          </div>
        )}
      </li>
    )
  }
}

export default Row
