import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import messages, { actions, adminEdit, notifications } from '../../constants/messages'
import { ADMIN_FP_SETTINGS } from '../../constants/routePaths'
import NotificationDTO from '../../DTO/NotificationDTO'
import PageSettingDTO from '../../DTO/Storage/PageSettingDTO'
import { notificationsEntity } from '../../entities/NotificationsEntity'
import { PageSettingsAddItems, pageSettingsAddItems } from '../../redux/actions/PageSettings'
import httpInstance from '../../services/Http'
import LoadingState from '../../VO/LoadingState'
import Layout from '../containers/Layout'
import Input from './views/Input'
import Paper from './views/Paper'
import SubmitButton from './views/SubmitButton'

const mapStateToProps = state => ({
  PageSettings: state.PageSettings,
})

const mapDispatchToProps = dispatch => ({
  PageSettingsAddItems: data => dispatch(PageSettingsAddItems(data)),
  pageSettingsAddItems: settings => dispatch(pageSettingsAddItems(settings)),
})

class FPSettings extends Component {
  static propTypes = {
    pageSettingsAddItems: PropTypes.func,
    PageSettingsAddItems: PropTypes.func,
    PageSettings: PropTypes.object,
  }

  state = {
    introText: '',
    isEdit: false,
  }
  loadingKeys = {
    introText: 'introText',
  }
  loadingState = new LoadingState(this, this.loadingKeys)

  getActiveYear = (props = this.props) => {
    return Number(props.match.params.year)
  }

  /**
   *
   * @param props
   * @return {PageSettingDTO}
   */
  getBasePageSettingsInstance(props = this.props) {
    return new PageSettingDTO('fp', this.getActiveYear(props), 'introText')
  }

  handleChange = (value, name) => {
    this.setState({
      [name]: value,
    })
  }

  handleSave = async () => {
    if (this.loadingState.isLoading(this.loadingKeys.introText)) {
      return
    }

    await this.loadingState.startLoading([this.loadingKeys.introText])

    try {
      const instance = this.getBasePageSettingsInstance()
      instance.value = this.state.introText
      const method = this.state.isEdit ? 'update' : 'create'
      await httpInstance.endpoints.editionTexts[method](instance)

      this.props.pageSettingsAddItems([instance])
    } catch (e) {
      console.log(e)
      notificationsEntity.addNotification(
        new NotificationDTO(
          null,
          messages.error,
          messages.somethingWentWrong,
          NotificationDTO.types.error,
          NotificationDTO.defaultDelay
        )
      )
    }

    await this.loadingState.finishLoading([this.loadingKeys.introText])
    notificationsEntity.addNotification(
      new NotificationDTO(
        null,
        messages.success,
        notifications.updatedSuccessfully(adminEdit.fpIntroText),
        NotificationDTO.types.success,
        NotificationDTO.defaultDelay
      )
    )
  }

  requestEdition = async () => {
    try {
      await this.props.PageSettingsAddItems(this.getBasePageSettingsInstance())
      this.setState({
        isEdit: true,
      })
    } catch (e) {
      console.log(e)
      this.setState({
        isEdit: false,
      })
    }
  }

  async componentDidMount() {
    await this.requestEdition()
    this.handleChange(this.getBasePageSettingsInstance().getValue(this.props.PageSettings), 'introText')
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.getActiveYear() !== this.getActiveYear(prevProps)) {
      await this.requestEdition()
    }

    const newIntroText = this.getBasePageSettingsInstance()
    const oldIntroText = this.getBasePageSettingsInstance(prevProps)

    if (newIntroText.getValue(this.props.PageSettings) !== oldIntroText.getValue(prevProps.PageSettings)) {
      this.handleChange(newIntroText.getValue(this.props.PageSettings), 'introText')
    }
  }

  render() {
    return (
      <div>
        <Layout
          title={adminEdit.fpSetting}
          menuType={Layout.menuTypes.admin}
          yearChange={ADMIN_FP_SETTINGS}
          activeYear={`${this.getActiveYear()}`}
        >
          <Paper>
            <Input
              isTextarea
              isDisabled={this.loadingState.isLoading(this.loadingKeys.introText)}
              handleChange={value => this.handleChange(value, 'introText')}
              value={this.state.introText}
              heading={adminEdit.fpIntroText}
            />
            <SubmitButton
              className="admin-button--is-modal-submit"
              handleSubmit={this.handleSave}
              isDisabled={this.loadingState.isLoading(this.loadingKeys.introText)}
            >
              {actions.save}
            </SubmitButton>
          </Paper>
        </Layout>
      </div>
    )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FPSettings)
)
