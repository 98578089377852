import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Loader } from '../components'
import messages from '../constants/messages'
import { SINGLE_ACTIVE_RELAY_QUERY } from '../constants/queries'
import ResizableTable from '../components/ShootersTable/ResizableTable'
import LoadMore from '../components/LoadMore'
import DefaultTable from '../components/InitialLagvisList/DefaultTable'
import Lagvis100Table from '../components/InitialLagvisList/Lagvis100Table'
import Lagvis200Table from '../components/InitialLagvisList/Lagvis200Table'
import Heading from '../views/TypeHeadingTime'
import dayjs from 'dayjs'

import { ErrorTemplate, LoadingTemplate } from '../Middleware/ResponseMiddleware'
import Message from '../views/Message'

class LagvisListLiveTable extends Component {
  static propTypes = {
    organizationId: PropTypes.string,
    componentsTree: PropTypes.object,
    postsLimit: PropTypes.number,
  }

  static defaultProps = {
    postsLimit: 20,
  }

  state = {
    canLoadMore: true,
    postsLimit: this.props.postsLimit,
  }
  isNewLoading = true

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.organizationId !== this.props.organizationId) {
      this.setState({
        canLoadMore: true,
      })
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.number !== this.props.number || nextProps.organizationId !== this.props.organizationId) {
      this.isNewLoading = true
    }
  }

  resolveComponent = (organizationId) => {
    if (organizationId === 'BA_100') {
      return Lagvis100Table
    }

    if (organizationId === 'BA_200') {
      return Lagvis200Table
    }

    return DefaultTable
  }

  load = (fetchMore, data) => {
    return fetchMore({
      variables: {
        offset: data.getActiveRelay.targets.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult.getActiveRelay.targets.length) {
          this.setState({
            canLoadMore: false,
          })
          return prev
        }

        if (fetchMoreResult.getActiveRelay.targets.length < this.state.postsLimit) {
          this.setState({
            canLoadMore: false,
          })
        }

        return {
          ...data,
          getActiveRelay: [
            {
              ...data.getActiveRelay,
              targets: [...data.getActiveRelay.targets, ...fetchMoreResult.getActiveRelay.targets],
            },
          ],
        }
      },
    })
  }

  render() {
    const { organizationId } = this.props
    const { canLoadMore, postsLimit } = this.state

    return organizationId ? (
      <Query
        query={SINGLE_ACTIVE_RELAY_QUERY}
        variables={{
          relayGroupOrganizationId: organizationId,
          limit: postsLimit,
          offset: 0,
        }}
        pollInterval={30000}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data, fetchMore }) => {
          if (loading && this.isNewLoading) {
            return <LoadingTemplate />
          }

          this.isNewLoading = false

          if (error) {
            return ErrorTemplate(error)
          }

          if (!data.getActiveRelay) {
            return <Message text={messages.refineSearch} />
          }

          const shooters = data.getActiveRelay.targets

          if (!shooters.length) {
            return <Message text={messages.nothingFound} />
          }

          const Component = this.resolveComponent(this.props.organizationId)

          return (
            <>
              <Heading
                className="ranged-field"
                type={`LAG ${data.getActiveRelay.number}`}
                option={data.getActiveRelay.name}
                time={dayjs(data.getActiveRelay.checkinDateTime).utc().format('DD.MM.YYYY HH:mm')}
                preTitle="Oppropstid:"
              />
              <LoadMore isLoadingMore={loading} canLoadMore={canLoadMore}>
                <ResizableTable
                  updateParameters={{
                    organizationId,
                  }}
                >
                  <Component activeRelay={true} data={data} />
                </ResizableTable>
                {this.state.loadingMore && <Loader />}
              </LoadMore>
            </>
          )
        }}
      </Query>
    ) : (
      <Message text={messages.refineSearch} />
    )
  }
}

export default LagvisListLiveTable
