import UserDTO from '../../DTO/UserDTO'
import UserEntity from '../../entities/UserEntity'
import dayjs from 'dayjs'

class AdminVO {
  instance

  /**
   *
   * @param {string} email
   * @return {string}
   */
  static resolveAdminName(email) {
    if (!email.includes('@')) {
      return email
    }

    const [name] = email.split('@')

    return name
  }

  constructor(instance) {
    this.instance = instance
  }

  /**
   *
   * @param email
   * @param password
   * @return {Promise<UserDTO>}
   */
  login = async (email, password) => {
    const { data } = await this.instance.post('/admin/login', {
      email,
      password,
    })
    const date = dayjs().format('YYYY-MM-DD hh:mm:ss')

    return new UserDTO(
      data.user.id,
      AdminVO.resolveAdminName(data.user.email),
      data.user.email,
      date,
      UserEntity.roles.ADMIN
    )
  }

  /**
   *
   * @return {Promise<UserDTO>}
   */
  info = async () => {
    const { data } = await this.instance.get('/admin/me')
    const date = dayjs().format('YYYY-MM-DD HH:mm:ss')

    // await DelayVO.delay(5000)

    return new UserDTO(data.id, AdminVO.resolveAdminName(data.email), data.email, date, UserEntity.roles.ADMIN)
  }
}

export default AdminVO
