import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { Loader } from '../components'
import SingleStepRender from '../components/SingleStepRender'
import messages from '../constants/messages'
import { SINGLE_REPORT_QUERY } from '../constants/queries'
import ResizableTable from '../components/ShootersTable/ResizableTable'
import LoadMore from '../components/LoadMore'
import { ErrorTemplate, LoadingTemplate } from '../Middleware/ResponseMiddleware'
import StepRender from '../components/StepRender'
import Heading from '../views/TypeHeading'
import Message from '../views/Message'

class ShootersTable extends Component {
  static propTypes = {
    organizationId: PropTypes.string,
    componentsTree: PropTypes.object,
    postsLimit: PropTypes.number,
  }

  static defaultProps = {
    postsLimit: 20,
  }

  state = {
    canLoadMore: true,
    postsLimit: this.props.postsLimit,
  }
  isNewLoading = true

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.organizationId !== this.props.organizationId) {
      this.setState({
        canLoadMore: true,
      })
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (nextProps.organizationId !== this.props.organizationId) {
      this.isNewLoading = true
    }
  }

  resolveComponent = (organizationGroup1Id, organizationGroup2Id = null) => {
    if (!this.props.componentsTree.hasOwnProperty(organizationGroup1Id)) {
      return this.props.componentsTree.default
    }

    if (!this.props.componentsTree[organizationGroup1Id].hasOwnProperty(organizationGroup2Id)) {
      return this.props.componentsTree[organizationGroup1Id].default
    }

    return this.props.componentsTree[organizationGroup1Id][organizationGroup2Id]
  }

  load = (fetchMore, data) => {
    return fetchMore({
      variables: {
        offset: data.getReport.ranks.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult.getReport.ranks.length) {
          this.setState({
            canLoadMore: false,
          })
          return prev
        }

        if (fetchMoreResult.getReport.ranks.length < this.state.postsLimit) {
          this.setState({
            canLoadMore: false,
          })
        }

        return {
          ...data,
          getReport: {
            ...data.getReport,
            ranks: [...data.getReport.ranks, ...fetchMoreResult.getReport.ranks],
          },
        }
      },
    })
  }

  render() {
    const { organizationId } = this.props
    const { canLoadMore, postsLimit } = this.state

    return (
      <Query
        query={SINGLE_REPORT_QUERY}
        variables={{
          organizationId,
          limit: postsLimit,
          offset: 0,
        }}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data, fetchMore }) => {
          if (loading && this.isNewLoading) {
            return <LoadingTemplate />
          }

          this.isNewLoading = false

          if (error) {
            return ErrorTemplate(error)
          }

          if (!data.getReport) {
            return <Message text={messages.refineSearch} />
          }

          const shooters = data.getReport.ranks

          if (!shooters.length) {
            return <Message text={messages.nothingFound} />
          }

          const Component = this.resolveComponent(
            data.getReport.organizationGroup1Id,
            data.getReport.organizationGroup2Id
          )

          return (
            <>
              {data.getReport.multipleShooters ? (
                <LoadMore
                  onLoadMore={() => this.load(fetchMore, data)}
                  isLoadingMore={loading}
                  canLoadMore={canLoadMore}
                >
                  <StepRender windowsCount={2}>
                    {data.getReport.ranks.map((rank, idx) => {
                      const key = data.getReport.organizationGroup2Id + '_' + rank.shooters[0].clubOrganizationId
                      const clubName = rank.name.trim()
                      const sum = rank.sum.trim()
                      const sumInner = rank.sumInner.trim()

                      return (
                        <SingleStepRender key={key} tableKey={key}>
                          <>
                            <Heading
                              className="multiple-shooters"
                              type={`${idx + 1}. ${sum}`}
                              category={sumInner}
                              option={clubName}
                            />
                            <ResizableTable
                              updateParameters={{
                                organizationId,
                              }}
                            >
                              <Component
                                data={{
                                  getReport: {
                                    ranks: rank.shooters.map(shooter => ({
                                      name: shooter.name,
                                      sum: shooter.sum,
                                      rank: parseInt(shooter.rank) ? shooter.rank : 'R',
                                      sumInner: shooter.sumInner,
                                      shooters: [shooter],
                                    })),
                                  },
                                }}
                              />
                            </ResizableTable>
                          </>
                        </SingleStepRender>
                      )
                    })}
                  </StepRender>
                  {this.state.loadingMore && <Loader />}
                </LoadMore>
              ) : (
                <LoadMore
                  onLoadMore={() => this.load(fetchMore, data)}
                  isLoadingMore={loading}
                  canLoadMore={canLoadMore}
                >
                  <ResizableTable
                    updateParameters={{
                      organizationId,
                    }}
                  >
                    <Component data={data} />
                  </ResizableTable>
                  {this.state.loadingMore && <Loader />}
                </LoadMore>
              )}
            </>
          )
        }}
      </Query>
    )
  }
}

export default ShootersTable
