import { NOTIFICATIONS_ADD_ITEM, NOTIFICATIONS_DELETE_ITEM, NOTIFICATIONS_UPDATE_ITEM } from '../ActionTypes'

const initialState = {
  ids: [],
  items: {},
}

/**
 *
 * @param {Object} state
 * @param {Object} action
 * @param {String} action.type
 * @param {NotificationDTO} action.payload
 * @returns {Object}
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_ADD_ITEM: {
      if (!!state.items[action.payload.id]) {
        return state
      }

      return {
        ids: [...state.ids, action.payload.id],
        items: {
          ...state.items,
          [action.payload.id]: action.payload,
        },
      }
    }

    case NOTIFICATIONS_UPDATE_ITEM: {
      const stateItem = state.items[action.payload.id]

      if (!stateItem) {
        return state
      }

      const itemsClone = {}

      Object.values(state.items).forEach(item => {
        if (item.id === action.payload.id) {
          return
        }

        itemsClone[item.id] = item
      })

      return {
        ids: state.ids,
        items: {
          ...itemsClone,
          [action.payload.id]: action.payload,
        },
      }
    }

    case NOTIFICATIONS_DELETE_ITEM: {
      const stateItem = state.items[action.payload.id]

      if (!stateItem) {
        return state
      }

      const itemsClone = {}

      Object.values(state.items).forEach(item => {
        if (item.id === action.payload.id) {
          return
        }

        itemsClone[item.id] = item
      })

      return {
        ids: state.ids.filter(id => id !== action.payload.id),
        items: itemsClone,
      }
    }

    default:
      return state
  }
}
