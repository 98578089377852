import React, { Component } from 'react'

import Table from '../../views/Table/Table'
import HeadSection from '../../views/Table/HeadSection'
import OpenShooterModal from '../../views/OpenShooterModal'
import ShooterMedal from '../../VO/ShooterMedal'
import Row from '../Table/Row'
import { NumberCell, ShooterCell, TotalStarCell } from '../../views/Table/Cells'
import { NumberHead, ShooterHead, TotalStarHead } from '../../views/Table/Heads'

class MinneTable extends Component {
  TOTAL_CELL = 'TOTAL_CELL'

  getSerie = series => name =>
    series.find(serie => serie.name === name) || {
      sum: 0,
      sumInner: 0,
    }

  render() {
    const { data } = this.props

    const shooters = data.getReport.ranks

    return (
      <Table>
        <HeadSection>
          <NumberHead>PL.</NumberHead>
          <ShooterHead>Navn og Skytterlag</ShooterHead>

          <TotalStarHead score="Minne" star="*" />
        </HeadSection>

        {shooters.map((shooter, key) => {
          const minneData = shooter.sum
          const starData = shooter.sumInner

          const shooterImage = ShooterMedal.medalImage(shooter)

          return (
            <OpenShooterModal key={key}>
              {({ onOpen }) => (
                <Row key={key} onClick={() => onOpen({ shooterId: shooter.shooters[0].organizationId })}>
                  <NumberCell>{shooter.rank}</NumberCell>
                  <ShooterCell image={shooterImage} name={shooter.name} club={shooter.shooters[0].clubName} />

                  <TotalStarCell score={minneData} star={starData} />
                </Row>
              )}
            </OpenShooterModal>
          )
        })}
      </Table>
    )
  }
}

export default MinneTable
