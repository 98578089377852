import { favoritesAddInfo } from '../redux/actions/Favorites'
import { userLogout } from '../redux/actions/User'
import storeObject from '../redux/store'
import httpInstance from '../services/Http'

class UserEntity {
  static roles = {
    ADMIN: 0,
    USER: 1,
  }

  /**
   *
   * @param {UserDTO} user
   * @return {boolean}
   */
  static resolveAddingFavorites(user) {
    return user.role !== UserEntity.roles.ADMIN
  }

  /**
   *
   * @return {UserDTO}
   */
  get state() {
    return storeObject.getState().User
  }

  get isAuthenticated() {
    return this.state.id
  }

  get isVerified() {
    return this.state.emailVerifiedAt
  }

  get isAdmin() {
    return this.isAuthenticated && this.state.role === UserEntity.roles.ADMIN
  }

  get canAddFavorite() {
    return this.isAuthenticated && !this.isAdmin
  }

  logout = async () => {
    await httpInstance.endpoints.user.logout()

    storeObject.dispatch(userLogout())
    storeObject.dispatch(favoritesAddInfo({}))
  }
}

export default UserEntity
