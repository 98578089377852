import { IFRAME_PATH } from '../constants/routePaths'
import LinkDTO from '../DTO/LinkDTO'
import AdminMenuItemVO from './AdminMenuItemVO'
import LinkVO from './LinkVO'

class MenuFactoryVO {
  /**
   *
   * @param response
   * @return {AdminMenuItemVO[]}
   */
  parse = response => {
    return [new AdminMenuItemVO()]
  }

  /**
   *
   * @param response
   * @return {LinkDTO[]}
   */
  parseAsLinks = response => {
    const typeHandlers = {
      [AdminMenuItemVO.types.iframe]: item => {
        const link = new LinkVO(item)

        return `${IFRAME_PATH}?url=${encodeURIComponent(link.absolute)}`
      },
      default: item => item,
    }

    return response.map(item => {
      const handler = typeHandlers.hasOwnProperty(item.type) ? typeHandlers[item.type] : typeHandlers.default

      return new LinkDTO(item.title, handler(item.url), item.id)
    })
  }
}

export default MenuFactoryVO
