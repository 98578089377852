import React, { Component } from 'react'
import ClassNamesService from '../../services/ClassNamesService'

import { formatShootsHeadsId, getShotsCode } from '../../services/shooterService'

import Table from '../../views/Table/Table'
import HeadSection from '../../views/Table/HeadSection'
import Row from '../Table/Row'
import ResizableCell from '../Table/ResizableCell'
import { NumberCell, ShotsCell, ShooterCell, TotalScoreCell, AdditionalCell, ScoreCell } from '../../views/Table/Cells'
import { NumberHead, ScoreHead, ShooterHead, TotalHead, AdditionalHead } from '../../views/Table/Heads'
import Button from '../../views/Button'
import OpenShooterModal from '../../views/OpenShooterModal'

class Finfelt extends Component {
  TOTAL_CELL = 'TOTAL_CELL'

  getSerie = series => organizationId => {
    return (
      series.find(serie => serie.organizationId === organizationId) || {
        sum: 0,
        sumInner: 0,
      }
    )
  }

  headsToObject = heads => heads.reduce((acc, item) => ({ ...acc, [item.organizationId]: '' }), {})

  resolveHeads767 = middleHeads => {
    if (!middleHeads.length) {
      return []
    }

    const middleHeadsObject = this.headsToObject(middleHeads)

    return [
      {
        name: 'Hold 1',
        organizationId: 'ST_1',
      },
      {
        name: 'Hold 2',
        organizationId: 'ST_2',
      },
      {
        name: 'Stang',
        organizationId: 'ST_1To2',
      },
    ].filter(head => middleHeadsObject.hasOwnProperty(head.organizationId))
  }

  resolveHeads1199 = middleHeads => {
    if (!middleHeads.length) {
      return []
    }

    const middleHeadsObject = this.headsToObject(middleHeads)

    return [
      {
        name: '30 Skudd',
        organizationId: 'FE_1To5',
      },
      {
        name: 'Minne',
        organizationId: 'MI_1',
      },
    ].filter(head => middleHeadsObject.hasOwnProperty(head.organizationId))
  }

  resolveHeadsMax = middleHeads => {
    if (!middleHeads.length) {
      return []
    }

    const existingHeads = {
      ...this.headsToObject(this.resolveHeads767(middleHeads)),
      ...this.headsToObject(this.resolveHeads1199(middleHeads)),
    }

    return middleHeads.filter(head => !existingHeads.hasOwnProperty(head.organizationId))
  }

  middleHeads = shooter => {
    const formattedHeads = formatShootsHeadsId(shooter)
    const middleHeads = formattedHeads.slice(0, -1)
    const lastElement = formattedHeads.slice(-1)
    const heads767 = this.resolveHeads767(middleHeads)
    const heads1199 = this.resolveHeads1199(middleHeads)
    const headsMax = this.resolveHeadsMax(middleHeads)

    return {
      items: [
        {
          className: 'hidden-up-to-1439',
          heads: headsMax,
        },
        {
          className: 'hidden-up-to-1199',
          heads: heads1199,
        },
        {
          className: 'hidden-up-to-767',
          heads: heads767,
        },
      ],
      secondaryColumns: [
        {
          className: 'hidden-from-1440',
          heads: headsMax,
        },
        {
          className: 'hidden-from-1200',
          heads: heads1199,
        },
        {
          className: 'hidden-from-768',
          heads: heads767,
        },
      ],
      formattedHeads,
      lastHead: lastElement.length ? lastElement[0] : { name: '' },
      middleHeads,
    }
  }

  render() {
    const { data } = this.props

    const shooters = data.getRelay[0].targets
    const shooter = shooters.find(shooter => shooter.series.length)
    const resolvedMiddleHeads = this.middleHeads(shooter || shooters[0])
    const lastHead = resolvedMiddleHeads.lastHead

    const totalSubscribe = this.props.subscribeGenerator(this.TOTAL_CELL)
    const totalUnsubscribe = this.props.unSubscribeGenerator(this.TOTAL_CELL)
    const totalMaxWidth = this.props.getMaxWidth(this.TOTAL_CELL)

    const middleColumnHandlers = {}

    resolvedMiddleHeads.middleHeads.forEach(headName => {
      middleColumnHandlers[headName.organizationId] = {
        subscribe: this.props.subscribeGenerator(headName.organizationId),
        unsubscribe: this.props.unSubscribeGenerator(headName.organizationId),
        width: this.props.getMaxWidth(headName.organizationId),
      }
    })

    return (
      <Table>
        <HeadSection className="head-row--up-to-1440">
          <NumberHead>SK.</NumberHead>
          <ShooterHead>Navn og Skytterlag</ShooterHead>

          {resolvedMiddleHeads.items.map(group =>
            group.heads.map((head, idx) => (
              <AdditionalHead key={idx} className={group.className}>
                <ResizableCell {...middleColumnHandlers[head.organizationId]}>
                  <ScoreHead>{head.name}</ScoreHead>
                </ResizableCell>
              </AdditionalHead>
            ))
          )}
          <ResizableCell subscribe={totalSubscribe} unsubscribe={totalUnsubscribe} width={totalMaxWidth}>
            <TotalHead>{lastHead.name}</TotalHead>
          </ResizableCell>
        </HeadSection>

        {shooters.map((shooter, key) => {
          const shooterSeries = this.getSerie(shooter.series)
          const lastSerie = shooterSeries(lastHead.organizationId)

          const secondaryColumns = (
            <OpenShooterModal>
              {({ onOpen }) => (
                <>
                  {resolvedMiddleHeads.secondaryColumns.map(group =>
                    group.heads.map((headName, idx) => {
                      const cellSerie = shooterSeries(headName.organizationId)

                      return (
                        <div key={idx} className={ClassNamesService.execute(['secondary-line', group.className])}>
                          <TotalHead className="head-cell--is-secondary-head">{headName.name}</TotalHead>
                          <ScoreCell className="cell--is-thin cell--additional-is-shots">
                            {getShotsCode(cellSerie.shots || [])}
                          </ScoreCell>
                          <ResizableCell
                            subscribe={totalSubscribe}
                            unsubscribe={totalUnsubscribe}
                            width={totalMaxWidth}
                          >
                            <TotalScoreCell>{cellSerie.sum}</TotalScoreCell>
                          </ResizableCell>
                        </div>
                      )
                    })
                  )}
                  <Button
                    className="btn--is-secondary-row"
                    onClick={() => onOpen({ shooterId: shooter.shooterOrganizationId })}
                  >
                    Vis skytterkort
                  </Button>
                </>
              )}
            </OpenShooterModal>
          )

          return (
            <OpenShooterModal key={key}>
              {({ onOpen }) => (
                <Row
                  secondaryRows={resolvedMiddleHeads.secondaryColumns.length && secondaryColumns}
                  className="row--up-to-1440"
                  updateKey={data.getRelay.name}
                  onClick={() => onOpen({ shooterId: shooter.shooterOrganizationId, from: 1439 })}
                >
                  <NumberCell>{key + 1}</NumberCell>
                  <ShooterCell image="" name={shooter.shooterName} club={shooter.clubName} />

                  {resolvedMiddleHeads.items.map(group =>
                    group.heads.map((headName, idx) => {
                      const cellSerie = shooterSeries(headName.organizationId)

                      return (
                        <AdditionalCell className={group.className} key={idx}>
                          <ResizableCell {...middleColumnHandlers[headName.organizationId]}>
                            <ShotsCell
                              hasBorder={idx !== resolvedMiddleHeads.formattedHeads.length - 1}
                              score={String(cellSerie.sum)}
                              shots={getShotsCode(cellSerie.shots || [])}
                            />
                          </ResizableCell>
                        </AdditionalCell>
                      )
                    })
                  )}

                  <ResizableCell subscribe={totalSubscribe} unsubscribe={totalUnsubscribe} width={totalMaxWidth}>
                    <TotalScoreCell>{lastSerie.sum}</TotalScoreCell>
                  </ResizableCell>
                </Row>
              )}
            </OpenShooterModal>
          )
        })}
      </Table>
    )
  }
}

export default Finfelt
