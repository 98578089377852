import ActionDTO from '../../DTO/ActionDTO'
import Http from '../../services/Http'
import { FAVORITES_ADD_INFO, FAVORITES_ADD_SHOOTER, FAVORITES_REMOVE_SHOOTER } from '../ActionTypes'

export const favoritesAddInfo = payload => new ActionDTO(FAVORITES_ADD_INFO, payload).valueOf
export const favoritesAddShooter = id => new ActionDTO(FAVORITES_ADD_SHOOTER, id).valueOf
export const favoritesRemoveShooter = id => new ActionDTO(FAVORITES_REMOVE_SHOOTER, id).valueOf

export const FavoritesAddInfo = () => async dispatch => {
  try {
    const shooters = await Http.endpoints.favorites.list()
    dispatch(favoritesAddInfo(shooters))
  } catch (e) {
    throw new Error(e)
  }
}

export const FavoritesAddShooter = id => async dispatch => {
  try {
    await Http.endpoints.favorites.create(id)
    dispatch(favoritesAddShooter(id))
  } catch (e) {
    throw new Error(e)
  }
}

export const FavoritesRemoveShooter = id => async dispatch => {
  try {
    await Http.endpoints.favorites.delete(id)
    dispatch(favoritesRemoveShooter(id))
  } catch (e) {
    throw new Error(e)
  }
}
