import React from 'react'
import { Loader } from '../components'
import Message from '../views/Message'

export const LoadingTemplate = props => {
  const className = props && props.className

  return (
    <div className="wrapper">
      <Loader className={className} />
    </div>
  )
}

export const ErrorTemplate = error => (
  <Message>
    {error && error.hasOwnProperty('message') ? `Error! ${error.message}` : 'Error happen. Please, reload the page'}
  </Message>
)

class ResponseMiddleware {
  _component = () => <div />

  _handleError = ErrorTemplate

  _handleLoading = LoadingTemplate

  _handleComponent = data => this._component(data)

  /**
   *
   * @param component
   * @return {ResponseMiddleware}
   */
  setComponent = component => {
    this._component = component

    return this
  }

  /**
   *
   * @param component
   * @return {ResponseMiddleware}
   */
  setErrorTemplate = component => {
    this._handleError = component

    return this
  }

  /**
   *
   * @param component
   * @return {ResponseMiddleware}
   */
  setLoadingTemplate = component => {
    this._handleLoading = component

    return this
  }

  execute = ({ loading, error, data, refetch }) => {
    if (loading) {
      return this._handleLoading(refetch)
    }

    if (error) {
      return this._handleError(error, refetch)
    }

    return this._handleComponent(data)
  }
}

export default ResponseMiddleware
