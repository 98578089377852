export const HOME_PATH = '/'
export const SEARCH_PATH = '/search/'
export const SHOOTER_PATH = '/shooter/'
export const CLUBTABLE_PATH = '/clubtable/'
export const RANGED_FIELD_PATH = '/rangert-felt/'
export const RANGED_BANE_PATH = '/rangert-bane/'
export const START_LISTER_PATH = '/nm-skogslop/'
export const INITIAL_LAGVIS_LIST_PATH = '/lagvis-lister-inledende/'
export const FINAL_LAGVIS_LIST_PATH = '/lagvis-lister-finale/'
export const SAMLAGS_FYLKESVISE_LIST_PATH = '/samlags-og-fylkesvise-resultater/'
export const REPORT_PATH = '/report/'
export const IFRAME_PATH = '/iframe/'
export const ERROR_PATH = '/404'

export const USER_CABINET_BASE = '/user-cabinet'
export const USER_CABINET_INFO = USER_CABINET_BASE + '/me'
export const USER_CABINET_SHOOTERS = USER_CABINET_BASE + '/shooters'
export const USER_CABINET_LOGIN = USER_CABINET_BASE + '/login'
export const USER_CABINET_SIGN_UP = USER_CABINET_BASE + '/sign-up'
export const USER_CABINET_FORGOT_PASSWORD = USER_CABINET_BASE + '/forgot-password'

export const USER_CABINET_BASE_PASSWORD = '/password'
export const USER_CABINET_RESET_PASSWORD = USER_CABINET_BASE_PASSWORD + '/reset/:token'

export const ADMIN_BASE = '/admin'
export const ADMIN_LOGIN = ADMIN_BASE + '/login'
export const ADMIN_MENU = (year = ':year') => ADMIN_BASE + '/menu/' + year
export const ADMIN_REPORT = (year = ':year') => ADMIN_BASE + '/report/' + year
export const ADMIN_FP_SETTINGS = (year = ':year') => ADMIN_BASE + '/fp-settings/' + year

/**
 *
 * @param {string} pathname
 * @param {string|function} path - router path
 * @param {string[]|number[]} payload
 * @return {boolean}
 */
export const resolveIsActive = (pathname, path, payload = []) => {
  if (typeof path === 'string') {
    return path === pathname
  }

  return path(...payload) === path
}
