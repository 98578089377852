import React, { useState } from 'react'
import allYears from '../constants/allYears'
// import menuLinks from '../constants/menuLinks'

import { applicationYear } from '../constants/allYears'

import { ReactComponent as CrossIcon } from '../images/cross-icon.svg'
import { devModeToken, renderAppInApolloClient } from '../index'
import ClassNamesService from '../services/ClassNamesService'
import { exitDevMode, isDevModeActive, setNewLoginToken } from '../services/loginService'

const devModeEnabler = devModeHandler => {
  let counter = 0
  let timeout

  return () => {
    clearTimeout(timeout)

    if (counter === 4) {
      counter = 0
      devModeHandler()

      return
    }

    counter++

    timeout = setTimeout(() => {
      counter = 0
    }, 1000)
  }
}

const Navigation = ({ onClose }) => {
  const [isDevModeEnabled, setIsDevModeEnabled] = useState(isDevModeActive())
  const [enableDevMode] = useState(() =>
    devModeEnabler(async () => {
      if (isDevModeEnabled) {
        return
      }

      const login = await devModeToken(false)

      if (!login) {
        return
      }

      setNewLoginToken(login)
      setIsDevModeEnabled(true)
      renderAppInApolloClient(login)
    })
  )

  return (
    <>
      <div className="site-aside-navigation">
        <div className="nav__close-block">
          <div className="nav__close-wrap">
            <button className="nav__close header-btn header-btn__menu" onClick={onClose}>
              <CrossIcon className="animFadeIn" style={{ fill: '#BFC5D2' }} /> Meny
            </button>
          </div>
        </div>

        <nav className="nav-wrapper">
          <div className="nav-years">
            {allYears.map(year => {
              const isYearActive = year.label === applicationYear
              const nodeClassName = ClassNamesService.execute(['btn nav-year', isYearActive && 'active-year'])

              return isYearActive ? (
                <span
                  key={year.label}
                  style={{ marginRight: '10px' }}
                  className={nodeClassName}
                  onClick={enableDevMode}
                >
                  {year.label}
                </span>
              ) : (
                <a className={nodeClassName} key={year.label} href={year.to} style={{ marginRight: '10px' }}>
                  {year.label}
                </a>
              )
            })}

            {isDevModeEnabled && (
              <button
                className="btn nav-year"
                style={{ marginRight: '10px', width: '100%' }}
                onClick={() => {
                  exitDevMode()
                }}
              >
                Exit dev mode
              </button>
            )}
          </div>
        </nav>
      </div>
      <div className="nav__close-layer" onClick={onClose} />
    </>
  )
}

export default Navigation
