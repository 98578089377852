import React, { Component } from 'react'
import propTypes from 'prop-types'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import sortShooters, { getMethods } from '../../services/sortShooters'

import ClubShooterTableRow from './clubShooterTableRow'
import '../../styles/club-table.scss'

class ClubShooterTable extends Component {
  state = {
    shooters: [],
    filteredShooters: [],

    filterString: '',
  }

  componentDidMount() {
    const extraShooters = this.props.shooters.map(shooter => {
      shooter.searchString = getMethods.map(method => method(shooter) || '').join(' ')
      return shooter
    })

    this.setState({
      shooters: extraShooters,
    })
  }

  handleSortingClass = sortKey => {
    const { sort_by, order } = queryString.parse(this.props.location.search)
    if (sortKey === sort_by) {
      return order ? `is-${order.toLowerCase()}` : ``
    }
  }

  handleClick = ({ sortKey }) => {
    const locationSearch = queryString.parse(this.props.location.search)
    const { sort_by, order } = locationSearch
    const { pathname } = this.props.location
    locationSearch.sort_by = sortKey

    const differentOrder = order === 'desc' ? 'asc' : 'desc'
    locationSearch.order = sort_by === sortKey ? differentOrder : 'desc'

    this.props.history.push({
      pathname,
      search: queryString.stringify(locationSearch),
    })
  }

  handleFilter = () => {
    const { filterString } = this.state
    if (!filterString) return

    this.setState({
      filteredShooters: this.state.shooters.filter(shooter =>
        shooter.searchString.toLowerCase().includes(filterString.toLowerCase())
      ),
    })
  }

  handleChange = e => {
    this.setState(
      {
        filterString: e.target.value,
      },
      this.handleFilter
    )
  }

  render() {
    const locationSearch = queryString.parse(this.props.location.search)
    const { sort_by, order } = locationSearch
    const { filterString, shooters } = this.state
    const filteredShooters = filterString ? this.state.filteredShooters : shooters
    return (
      <>
        <input
          className="input clubshooter-search__input"
          onChange={this.handleChange}
          type="text"
          placeholder="Filter"
        />
        <div className="clubshooter-table__wrapper">
          <table className="clubshooter-table">
            <thead>
              <tr>
                <th />
                <th />
                <th className="category-th" colSpan="11">
                  B A N E
                </th>
                <th className="category-th" colSpan="4">
                  F E L T
                </th>
              </tr>
              <tr>
                {this.props.sortingCells.map(cell => (
                  <th
                    className={this.handleSortingClass(cell.sortKey)}
                    key={cell.sortKey}
                    onClick={() => this.handleClick(cell)}
                  >
                    {cell.label}
                  </th>
                ))}
              </tr>
              <tr />
            </thead>
            <tbody>
              {sortShooters(filteredShooters, { sort_by, order }).map(shooter => (
                <ClubShooterTableRow shooter={shooter} key={`${shooter.name}${shooter.clubName}`} />
              ))}
            </tbody>
          </table>
        </div>
      </>
    )
  }
}

export default withRouter(ClubShooterTable)

ClubShooterTable.propTypes = {
  shooters: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string.isRequired,
      categories: propTypes.array.isRequired,
      clubName: propTypes.string.isRequired,
      countyName: propTypes.string,
      defaultClassName: propTypes.string,
      defaultClassOrganizationId: propTypes.string,
      events: propTypes.array.isRequired,
    })
  ),
  sortingCells: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string.isRequired,
      sortKey: propTypes.string.isRequired,
    })
  ),
}

ClubShooterTable.defaultProps = {
  shooters: [],
  sortingCells: [{ label: 'Klass', sortKey: 'Klass' }, { label: 'Navn', sortKey: 'Navn' }],
}
