import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { userCabinet } from '../../constants/messages'
import UserEntity from '../../entities/UserEntity'
import Input from '../admin/views/Input'
import Paper from '../admin/views/Paper'
import Layout from './Layout'

const mapStateToProps = state => ({
  User: state.User,
})

const mapDispatchToProps = dispatch => ({})

class UserInfo extends Component {
  static propTypes = {
    User: PropTypes.object,
  }

  user = new UserEntity()

  render() {
    return (
      <Layout title={userCabinet.userInfo} menuType={Layout.menuTypes.user}>
        <Paper>
          <div className="user-info">
            <Input heading={userCabinet.name} value={this.user.state.name} isDisabled />
            <Input heading={userCabinet.email} value={this.user.state.email} isDisabled />
          </div>
        </Paper>
      </Layout>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInfo)
