import tryService from '../services/tryService'

import CUP_BIG from '../images/cups/Cup_large.png'
import CUP_MEDIUM from '../images/cups/Cup_medium.png'
import CUP_SMALL from '../images/cups/Cup_small.png'
import CUP_CUP from '../images/cups/Cup_cup.png'

import MEDAL_STAR from '../images/cups/Medal_medal.png'
import MEDAL_GOLDEN from '../images/cups/Medal_gold.png'
import MEDAL_MEDAL from '../images/cups/Medal_silver.png'

class ShooterMedal {
  static types = {
    CUP: {
      '': CUP_CUP,
      BIG: CUP_BIG,
      MEDIUM: CUP_MEDIUM,
      SMALL: CUP_SMALL,
    },
    MEDAL: {
      GOLDEN: MEDAL_GOLDEN,
      MEDAL: MEDAL_MEDAL,
      STAR: MEDAL_STAR,
      RAUFOSS: MEDAL_GOLDEN,
      YOUTH_MEDAL: MEDAL_GOLDEN,
      SILVER: MEDAL_MEDAL,
    },
  }

  /**
   *
   * @param shooter
   * @return {string}
   */
  static medalImage = shooter => {
    const distinctions = tryService(() => shooter.shooters[0].distinctions)

    if (!distinctions || !distinctions.length) {
      return ''
    }

    const distinction = distinctions[0]

    if (!ShooterMedal.types.hasOwnProperty(distinction.type)) {
      return ''
    }

    if (!ShooterMedal.types[distinction.type].hasOwnProperty(distinction.subType)) {
      return ''
    }

    return ShooterMedal.types[distinction.type][distinction.subType]
  }
}

export default ShooterMedal
