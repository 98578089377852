import { FAVORITES_ADD_INFO, FAVORITES_ADD_SHOOTER, FAVORITES_REMOVE_SHOOTER } from '../ActionTypes'

/**
 *
 * @param {Object} state
 * @param {Object} action
 * @param {String} action.type
 * @param {*} action.payload
 * @returns {Object}
 */
export default (state = {}, action) => {
  switch (action.type) {
    case FAVORITES_ADD_INFO:
      return action.payload

    case FAVORITES_ADD_SHOOTER:
      return {
        ...state,
        [action.payload]: +action.payload,
      }

    case FAVORITES_REMOVE_SHOOTER:
      const stateCopy = { ...state }
      delete stateCopy[action.payload]

      return stateCopy

    default:
      return state
  }
}
