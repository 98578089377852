import PropTypes from 'prop-types'
import React from 'react'
import ClassNamesService from '../services/ClassNamesService'

const Button = ({ children, className = null, onClick = () => {} }) => (
  <button className={ClassNamesService.execute(['btn btn--default', className])} onClick={onClick}>
    {children}
  </button>
)

Button.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, () => null]),
  onClick: PropTypes.func,
}

export default Button
